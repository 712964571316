import React from 'react';
import './ChapterOverview.css';
import { Location } from '../Types/Location';

export const ChapterOverview: React.FC<{ 
    location: Location
}> = ({ location }) => {

    return (
        <>
            <h2>{location.title}</h2>
            <h3>{location.subtitle}</h3>
            <div className='location-metadata'>
                <p className='location-metadata-item'>{location.landMarkCategory}</p>
                <p className='location-metadata-item'>{location.neighborhood}</p>
                <p className='location-metadata-item chapter-item'>Chapter {location.chapter} / {location.total_chapters}</p>
            </div>
        </>
    );
};
