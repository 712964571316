import { Language } from "./Language";

export type Location = {
    rid: string;
    title: string;
    subtitle: string;
    src: string;
    language: Language;
    city: string;
    neighborhood: string;
    landMarkCategory: string;
    landmark: string;
    dateAdded: string;
    audioFile: string;
    chapter: number;
    total_chapters: number;
    address: string;
    latLong: {
        lat: number;
        long: number;
    };
    isPremium: boolean;
    premiumPackage: string | null;
    playNext: string;
};


export const locations: Location[] = [
    {
        rid: "highbridge_park_landmarks_and_features",
        title: "Highbridge Park",
        subtitle: "Landmarks And Features",
        src: require("../../../audioAssets/english/highbridge_park_landmarks_and_features.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Public Park",
        landmark: "Highbridge Park",
        dateAdded: "2024-07-08",
        audioFile: "highbridge_park_landmarks_and_features.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Amsterdam Ave & W 172nd St, New York, NY 10032",
        latLong: {
            lat: 40.8358,
            long: -73.9329
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "highbridge_park_overview_of_highbridge_park"
    },
    {
        rid: "highbridge_park_overview_of_highbridge_park",
        title: "Highbridge Park",
        subtitle: "Overview Of Highbridge Park",
        src: require("../../../audioAssets/english/highbridge_park_overview_of_highbridge_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Public Park",
        landmark: "Highbridge Park",
        dateAdded: "2024-07-08",
        audioFile: "highbridge_park_overview_of_highbridge_park.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Amsterdam Ave & W 172nd St, New York, NY 10032",
        latLong: {
            lat: 40.8358,
            long: -73.9329
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "highbridge_park_role_in_urban_recreation"
    },
    {
        rid: "highbridge_park_role_in_urban_recreation",
        title: "Highbridge Park",
        subtitle: "Role In Urban Recreation",
        src: require("../../../audioAssets/english/highbridge_park_role_in_urban_recreation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Public Park",
        landmark: "Highbridge Park",
        dateAdded: "2024-07-08",
        audioFile: "highbridge_park_role_in_urban_recreation.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Amsterdam Ave & W 172nd St, New York, NY 10032",
        latLong: {
            lat: 40.8358,
            long: -73.9329
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "highbridge_park_historical_background"
    },
    {
        rid: "highbridge_park_historical_background",
        title: "Highbridge Park",
        subtitle: "Historical Background",
        src: require("../../../audioAssets/english/highbridge_park_historical_background.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Public Park",
        landmark: "Highbridge Park",
        dateAdded: "2024-07-08",
        audioFile: "highbridge_park_historical_background.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Amsterdam Ave & W 172nd St, New York, NY 10032",
        latLong: {
            lat: 40.8358,
            long: -73.9329
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_notable_events"
    },
    {
        rid: "union_square_park_notable_events",
        title: "Union Square Park",
        subtitle: "Notable Events",
        src: require("../../../audioAssets/english/union_square_park_notable_events.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-08",
        audioFile: "union_square_park_notable_events.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Broadway and University Place, New York, NY 10003",
        latLong: {
            lat: 40.7359,
            long: -73.9906
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_historical_significance"
    },
    {
        rid: "union_square_park_historical_significance",
        title: "Union Square Park",
        subtitle: "Historical Significance",
        src: require("../../../audioAssets/english/union_square_park_historical_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-08",
        audioFile: "union_square_park_historical_significance.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Broadway and University Place, New York, NY 10003",
        latLong: {
            lat: 40.7359,
            long: -73.9906
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_introduction_to_union_square_park"
    },
    {
        rid: "union_square_park_introduction_to_union_square_park",
        title: "Union Square Park",
        subtitle: "Introduction to Union Square Park",
        src: require("../../../audioAssets/english/union_square_park_introduction_to_union_square_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-08",
        audioFile: "union_square_park_introduction_to_union_square_park.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Broadway and University Place, New York, NY 10003",
        latLong: {
            lat: 40.7359,
            long: -73.9906
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_cultural_and_social_role"
    },
    {
        rid: "union_square_park_cultural_and_social_role",
        title: "Union Square Park",
        subtitle: "Cultural And Social Role",
        src: require("../../../audioAssets/english/union_square_park_cultural_and_social_role.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-08",
        audioFile: "union_square_park_cultural_and_social_role.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Broadway and University Place, New York, NY 10003",
        latLong: {
            lat: 40.7359,
            long: -73.9906
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_notable_features"
    },
    {
        rid: "riverside_park_notable_features",
        title: "Riverside Park",
        subtitle: "Notable Features",
        src: require("../../../audioAssets/english/riverside_park_notable_features.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side, Morningside Heights, Manhattanville, Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-08",
        audioFile: "riverside_park_notable_features.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: {
            lat: 40.8007,
            long: -73.9707
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_introduction_to_riverside_park"
    },
    {
        rid: "riverside_park_introduction_to_riverside_park",
        title: "Riverside Park",
        subtitle: "Introduction to Riverside Park",
        src: require("../../../audioAssets/english/riverside_park_introduction_to_riverside_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side, Morningside Heights, Manhattanville, Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-08",
        audioFile: "riverside_park_introduction_to_riverside_park.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: {
            lat: 40.8007,
            long: -73.9707
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_historical_significance"
    },
    {
        rid: "riverside_park_historical_significance",
        title: "Riverside Park",
        subtitle: "Historical Significance",
        src: require("../../../audioAssets/english/riverside_park_historical_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side, Morningside Heights, Manhattanville, Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-08",
        audioFile: "riverside_park_historical_significance.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: {
            lat: 40.8007,
            long: -73.9707
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_role_in_community_recreation"
    },
    {
        rid: "riverside_park_role_in_community_recreation",
        title: "Riverside Park",
        subtitle: "Role In Community Recreation",
        src: require("../../../audioAssets/english/riverside_park_role_in_community_recreation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side, Morningside Heights, Manhattanville, Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-08",
        audioFile: "riverside_park_role_in_community_recreation.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: {
            lat: 40.8007,
            long: -73.9707
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_philharmonic_iconic_performances"
    },
    {
        rid: "ny_philharmonic_iconic_performances",
        title: "New York Philharmonic",
        subtitle: "Iconic Performances",
        src: require("../../../audioAssets/english/ny_philharmonic_iconic_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York Philharmonic",
        dateAdded: "2024-07-08",
        audioFile: "ny_philharmonic_iconic_performances.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "David Geffen Hall, 10 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_philharmonic_influence_on_global_music_culture"
    },
    {
        rid: "ny_philharmonic_influence_on_global_music_culture",
        title: "New York Philharmonic",
        subtitle: "Influence On Global Music Culture",
        src: require("../../../audioAssets/english/ny_philharmonic_influence_on_global_music_culture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York Philharmonic",
        dateAdded: "2024-07-08",
        audioFile: "ny_philharmonic_influence_on_global_music_culture.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "David Geffen Hall, 10 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_philharmonic_history_and_founding"
    },
    {
        rid: "ny_philharmonic_history_and_founding",
        title: "New York Philharmonic",
        subtitle: "History And Founding",
        src: require("../../../audioAssets/english/ny_philharmonic_history_and_founding.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York Philharmonic",
        dateAdded: "2024-07-08",
        audioFile: "ny_philharmonic_history_and_founding.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "David Geffen Hall, 10 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_philharmonic_introduction_to_the_ny_philharmonic"
    },
    {
        rid: "ny_philharmonic_introduction_to_the_ny_philharmonic",
        title: "New York Philharmonic",
        subtitle: "Introduction to The NY Philharmonic",
        src: require("../../../audioAssets/english/ny_philharmonic_introduction_to_the_ny_philharmonic.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York Philharmonic",
        dateAdded: "2024-07-08",
        audioFile: "ny_philharmonic_introduction_to_the_ny_philharmonic.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "David Geffen Hall, 10 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_opera_introduction_to_the_ny_opera"
    },
    {
        rid: "ny_opera_introduction_to_the_ny_opera",
        title: "New York City Opera",
        subtitle: "Introduction to The NY Opera",
        src: require("../../../audioAssets/english/ny_opera_introduction_to_the_ny_opera.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Opera",
        dateAdded: "2024-07-08",
        audioFile: "ny_opera_introduction_to_the_ny_opera.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, United States",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_opera_contribution_to_opera"
    },
    {
        rid: "ny_opera_contribution_to_opera",
        title: "New York City Opera",
        subtitle: "Contribution to Opera",
        src: require("../../../audioAssets/english/ny_opera_contribution_to_opera.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Opera",
        dateAdded: "2024-07-08",
        audioFile: "ny_opera_contribution_to_opera.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, United States",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_opera_history_and_milestones"
    },
    {
        rid: "ny_opera_history_and_milestones",
        title: "New York City Opera",
        subtitle: "History And Milestones",
        src: require("../../../audioAssets/english/ny_opera_history_and_milestones.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Opera",
        dateAdded: "2024-07-08",
        audioFile: "ny_opera_history_and_milestones.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, United States",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_opera_notable_productions"
    },
    {
        rid: "ny_opera_notable_productions",
        title: "New York City Opera",
        subtitle: "Notable Productions",
        src: require("../../../audioAssets/english/ny_opera_notable_productions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Opera",
        dateAdded: "2024-07-08",
        audioFile: "ny_opera_notable_productions.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, United States",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "hudson_river_park_development_and_transformation"
    },
    {
        rid: "hudson_river_park_development_and_transformation",
        title: "Hudson River Park",
        subtitle: "Development And Transformation",
        src: require("../../../audioAssets/english/hudson_river_park_development_and_transformation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Hudson River Park",
        dateAdded: "2024-07-08",
        audioFile: "hudson_river_park_development_and_transformation.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "353 West St, New York, NY 10011, USA",
        latLong: {
            lat: 40.738,
            long: -74.01
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "hudson_river_park_introduction_to_hudson_river_park"
    },
    {
        rid: "hudson_river_park_introduction_to_hudson_river_park",
        title: "Hudson River Park",
        subtitle: "Introduction to Hudson River Park",
        src: require("../../../audioAssets/english/hudson_river_park_introduction_to_hudson_river_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Hudson River Park",
        dateAdded: "2024-07-08",
        audioFile: "hudson_river_park_introduction_to_hudson_river_park.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "353 West St, New York, NY 10011, USA",
        latLong: {
            lat: 40.738,
            long: -74.01
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "hudson_river_park_environmental_and_recreational_impact"
    },
    {
        rid: "hudson_river_park_environmental_and_recreational_impact",
        title: "Hudson River Park",
        subtitle: "Environmental And Recreational Impact",
        src: require("../../../audioAssets/english/hudson_river_park_environmental_and_recreational_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Hudson River Park",
        dateAdded: "2024-07-08",
        audioFile: "hudson_river_park_environmental_and_recreational_impact.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "353 West St, New York, NY 10011, USA",
        latLong: {
            lat: 40.738,
            long: -74.01
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "hudson_river_park_key_attractions"
    },
    {
        rid: "hudson_river_park_key_attractions",
        title: "Hudson River Park",
        subtitle: "Key Attractions",
        src: require("../../../audioAssets/english/hudson_river_park_key_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Hudson River Park",
        dateAdded: "2024-07-08",
        audioFile: "hudson_river_park_key_attractions.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "353 West St, New York, NY 10011, USA",
        latLong: {
            lat: 40.738,
            long: -74.01
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "battery_park_introduction_to_battery_park"
    },
    {
        rid: "battery_park_introduction_to_battery_park",
        title: "Battery Park",
        subtitle: "Introduction to Battery Park",
        src: require("../../../audioAssets/english/battery_park_introduction_to_battery_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Public Park",
        landmark: "Battery Park",
        dateAdded: "2024-07-08",
        audioFile: "battery_park_introduction_to_battery_park.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "New York, NY 10004",
        latLong: {
            lat: 40.7033,
            long: -74.017
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "battery_park_history_and_development"
    },
    {
        rid: "battery_park_history_and_development",
        title: "Battery Park",
        subtitle: "History And Development",
        src: require("../../../audioAssets/english/battery_park_history_and_development.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Public Park",
        landmark: "Battery Park",
        dateAdded: "2024-07-08",
        audioFile: "battery_park_history_and_development.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "New York, NY 10004",
        latLong: {
            lat: 40.7033,
            long: -74.017
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "battery_park_key_sights_and_activities"
    },
    {
        rid: "battery_park_key_sights_and_activities",
        title: "Battery Park",
        subtitle: "Key Sights And Activities",
        src: require("../../../audioAssets/english/battery_park_key_sights_and_activities.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Public Park",
        landmark: "Battery Park",
        dateAdded: "2024-07-08",
        audioFile: "battery_park_key_sights_and_activities.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "New York, NY 10004",
        latLong: {
            lat: 40.7033,
            long: -74.017
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "battery_park_role_in_new_york's_history"
    },
    {
        rid: "battery_park_role_in_new_york's_history",
        title: "Battery Park",
        subtitle: "Role In New York's History",
        src: require("../../../audioAssets/english/battery_park_role_in_new_york's_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Public Park",
        landmark: "Battery Park",
        dateAdded: "2024-07-08",
        audioFile: "battery_park_role_in_new_york's_history.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "New York, NY 10004",
        latLong: {
            lat: 40.7033,
            long: -74.017
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyu_campus_and_architecture"
    },
    {
        rid: "nyu_campus_and_architecture",
        title: "New York University (NYU)",
        subtitle: "Campus And Architecture",
        src: require("../../../audioAssets/english/nyu_campus_and_architecture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "New York University (NYU)",
        dateAdded: "2024-07-08",
        audioFile: "nyu_campus_and_architecture.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "70 Washington Square S, New York, NY 10012, USA",
        latLong: {
            lat: 40.7291,
            long: -73.9965
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyu_introduction_to_nyu"
    },
    {
        rid: "nyu_introduction_to_nyu",
        title: "New York University (NYU)",
        subtitle: "Introduction to NYU",
        src: require("../../../audioAssets/english/nyu_introduction_to_nyu.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "New York University (NYU)",
        dateAdded: "2024-07-08",
        audioFile: "nyu_introduction_to_nyu.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "70 Washington Square S, New York, NY 10012, USA",
        latLong: {
            lat: 40.7291,
            long: -73.9965
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyu_historical_development"
    },
    {
        rid: "nyu_historical_development",
        title: "New York University (NYU)",
        subtitle: "Historical Development",
        src: require("../../../audioAssets/english/nyu_historical_development.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "New York University (NYU)",
        dateAdded: "2024-07-08",
        audioFile: "nyu_historical_development.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "70 Washington Square S, New York, NY 10012, USA",
        latLong: {
            lat: 40.7291,
            long: -73.9965
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyu_impact_on_education_and_research"
    },
    {
        rid: "nyu_impact_on_education_and_research",
        title: "New York University (NYU)",
        subtitle: "Impact On Education And Research",
        src: require("../../../audioAssets/english/nyu_impact_on_education_and_research.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "New York University (NYU)",
        dateAdded: "2024-07-08",
        audioFile: "nyu_impact_on_education_and_research.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "70 Washington Square S, New York, NY 10012, USA",
        latLong: {
            lat: 40.7291,
            long: -73.9965
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "times_square_historical_evolution"
    },
    {
        rid: "times_square_historical_evolution",
        title: "Times Square",
        subtitle: "Historical Evolution",
        src: require("../../../audioAssets/english/times_square_historical_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Times Square",
        dateAdded: "2024-07-08",
        audioFile: "times_square_historical_evolution.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Manhattan, NY 10036, USA",
        latLong: {
            lat: 40.758,
            long: -73.9855
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "times_square_iconic_landmarks"
    },
    {
        rid: "times_square_iconic_landmarks",
        title: "Times Square",
        subtitle: "Iconic Landmarks",
        src: require("../../../audioAssets/english/times_square_iconic_landmarks.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Times Square",
        dateAdded: "2024-07-08",
        audioFile: "times_square_iconic_landmarks.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Manhattan, NY 10036, USA",
        latLong: {
            lat: 40.758,
            long: -73.9855
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "times_square_introduction_to_times_square"
    },
    {
        rid: "times_square_introduction_to_times_square",
        title: "Times Square",
        subtitle: "Introduction to Times Square",
        src: require("../../../audioAssets/english/times_square_introduction_to_times_square.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Times Square",
        dateAdded: "2024-07-08",
        audioFile: "times_square_introduction_to_times_square.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Manhattan, NY 10036, USA",
        latLong: {
            lat: 40.758,
            long: -73.9855
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "times_square_impact_on_pop_culture"
    },
    {
        rid: "times_square_impact_on_pop_culture",
        title: "Times Square",
        subtitle: "Impact On Pop Culture",
        src: require("../../../audioAssets/english/times_square_impact_on_pop_culture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Times Square",
        dateAdded: "2024-07-08",
        audioFile: "times_square_impact_on_pop_culture.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Manhattan, NY 10036, USA",
        latLong: {
            lat: 40.758,
            long: -73.9855
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "morningside_park_historical_context"
    },
    {
        rid: "morningside_park_historical_context",
        title: "Morningside Park",
        subtitle: "Historical Context",
        src: require("../../../audioAssets/english/morningside_park_historical_context.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Public Park",
        landmark: "Morningside Park",
        dateAdded: "2024-07-08",
        audioFile: "morningside_park_historical_context.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "110th Street to 123rd Street, between Morningside Avenue and Morningside Drive, New York, NY",
        latLong: {
            lat: 40.808,
            long: -73.959
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "morningside_park_introduction_to_morningside_park"
    },
    {
        rid: "morningside_park_introduction_to_morningside_park",
        title: "Morningside Park",
        subtitle: "Introduction to Morningside Park",
        src: require("../../../audioAssets/english/morningside_park_introduction_to_morningside_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Public Park",
        landmark: "Morningside Park",
        dateAdded: "2024-07-08",
        audioFile: "morningside_park_introduction_to_morningside_park.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "110th Street to 123rd Street, between Morningside Avenue and Morningside Drive, New York, NY",
        latLong: {
            lat: 40.808,
            long: -73.959
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "morningside_park_importance_to_local_community"
    },
    {
        rid: "morningside_park_importance_to_local_community",
        title: "Morningside Park",
        subtitle: "Importance to Local Community",
        src: require("../../../audioAssets/english/morningside_park_importance_to_local_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Public Park",
        landmark: "Morningside Park",
        dateAdded: "2024-07-08",
        audioFile: "morningside_park_importance_to_local_community.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "110th Street to 123rd Street, between Morningside Avenue and Morningside Drive, New York, NY",
        latLong: {
            lat: 40.808,
            long: -73.959
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "morningside_park_main_attractions"
    },
    {
        rid: "morningside_park_main_attractions",
        title: "Morningside Park",
        subtitle: "Main Attractions",
        src: require("../../../audioAssets/english/morningside_park_main_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Public Park",
        landmark: "Morningside Park",
        dateAdded: "2024-07-08",
        audioFile: "morningside_park_main_attractions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "110th Street to 123rd Street, between Morningside Avenue and Morningside Drive, New York, NY",
        latLong: {
            lat: 40.808,
            long: -73.959
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_market_role_in_new_york's_food_scene"
    },
    {
        rid: "chelsea_market_role_in_new_york's_food_scene",
        title: "Chelsea Market",
        subtitle: "Role In New York's Food Scene",
        src: require("../../../audioAssets/english/chelsea_market_role_in_new_york's_food_scene.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Public Market",
        landmark: "Chelsea Market",
        dateAdded: "2024-07-08",
        audioFile: "chelsea_market_role_in_new_york's_food_scene.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "75 9th Ave, New York, NY 10011",
        latLong: {
            lat: 40.742417,
            long: -74.00627
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_market_culinary_attractions"
    },
    {
        rid: "chelsea_market_culinary_attractions",
        title: "Chelsea Market",
        subtitle: "Culinary Attractions",
        src: require("../../../audioAssets/english/chelsea_market_culinary_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Public Market",
        landmark: "Chelsea Market",
        dateAdded: "2024-07-08",
        audioFile: "chelsea_market_culinary_attractions.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "75 9th Ave, New York, NY 10011",
        latLong: {
            lat: 40.742417,
            long: -74.00627
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_market_introduction_to_chelsea_market"
    },
    {
        rid: "chelsea_market_introduction_to_chelsea_market",
        title: "Chelsea Market",
        subtitle: "Introduction to Chelsea Market",
        src: require("../../../audioAssets/english/chelsea_market_introduction_to_chelsea_market.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Public Market",
        landmark: "Chelsea Market",
        dateAdded: "2024-07-08",
        audioFile: "chelsea_market_introduction_to_chelsea_market.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "75 9th Ave, New York, NY 10011",
        latLong: {
            lat: 40.742417,
            long: -74.00627
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_market_historical_significance"
    },
    {
        rid: "chelsea_market_historical_significance",
        title: "Chelsea Market",
        subtitle: "Historical Significance",
        src: require("../../../audioAssets/english/chelsea_market_historical_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Public Market",
        landmark: "Chelsea Market",
        dateAdded: "2024-07-08",
        audioFile: "chelsea_market_historical_significance.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "75 9th Ave, New York, NY 10011",
        latLong: {
            lat: 40.742417,
            long: -74.00627
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "bryant_park_history_and_redevelopment"
    },
    {
        rid: "bryant_park_history_and_redevelopment",
        title: "Bryant Park",
        subtitle: "History And Redevelopment",
        src: require("../../../audioAssets/english/bryant_park_history_and_redevelopment.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Public Park",
        landmark: "Bryant Park",
        dateAdded: "2024-07-08",
        audioFile: "bryant_park_history_and_redevelopment.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "New York, NY 10018, United States",
        latLong: {
            lat: 40.753596,
            long: -73.983232
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "bryant_park_introduction_to_bryant_park"
    },
    {
        rid: "bryant_park_introduction_to_bryant_park",
        title: "Bryant Park",
        subtitle: "Introduction to Bryant Park",
        src: require("../../../audioAssets/english/bryant_park_introduction_to_bryant_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Public Park",
        landmark: "Bryant Park",
        dateAdded: "2024-07-08",
        audioFile: "bryant_park_introduction_to_bryant_park.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "New York, NY 10018, United States",
        latLong: {
            lat: 40.753596,
            long: -73.983232
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "bryant_park_cultural_and_social_significance"
    },
    {
        rid: "bryant_park_cultural_and_social_significance",
        title: "Bryant Park",
        subtitle: "Cultural And Social Significance",
        src: require("../../../audioAssets/english/bryant_park_cultural_and_social_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Public Park",
        landmark: "Bryant Park",
        dateAdded: "2024-07-08",
        audioFile: "bryant_park_cultural_and_social_significance.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "New York, NY 10018, United States",
        latLong: {
            lat: 40.753596,
            long: -73.983232
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "bryant_park_popular_activities"
    },
    {
        rid: "bryant_park_popular_activities",
        title: "Bryant Park",
        subtitle: "Popular Activities",
        src: require("../../../audioAssets/english/bryant_park_popular_activities.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Public Park",
        landmark: "Bryant Park",
        dateAdded: "2024-07-08",
        audioFile: "bryant_park_popular_activities.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "New York, NY 10018, United States",
        latLong: {
            lat: 40.753596,
            long: -73.983232
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_historical_background"
    },
    {
        rid: "madison_square_park_historical_background",
        title: "Madison Square Park",
        subtitle: "Historical Background",
        src: require("../../../audioAssets/english/madison_square_park_historical_background.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-08",
        audioFile: "madison_square_park_historical_background.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: {
            lat: 40.7425,
            long: -73.9879
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_importance_to_new_york_city"
    },
    {
        rid: "madison_square_park_importance_to_new_york_city",
        title: "Madison Square Park",
        subtitle: "Importance to New York City",
        src: require("../../../audioAssets/english/madison_square_park_importance_to_new_york_city.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-08",
        audioFile: "madison_square_park_importance_to_new_york_city.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: {
            lat: 40.7425,
            long: -73.9879
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_overview_of_madison_square_park"
    },
    {
        rid: "madison_square_park_overview_of_madison_square_park",
        title: "Madison Square Park",
        subtitle: "Overview Of Madison Square Park",
        src: require("../../../audioAssets/english/madison_square_park_overview_of_madison_square_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-08",
        audioFile: "madison_square_park_overview_of_madison_square_park.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: {
            lat: 40.7425,
            long: -73.9879
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_key_attractions"
    },
    {
        rid: "madison_square_park_key_attractions",
        title: "Madison Square Park",
        subtitle: "Key Attractions",
        src: require("../../../audioAssets/english/madison_square_park_key_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-08",
        audioFile: "madison_square_park_key_attractions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: {
            lat: 40.7425,
            long: -73.9879
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "lincoln_center_theater_introduction_to_lincoln_center_theater"
    },
    {
        rid: "lincoln_center_theater_introduction_to_lincoln_center_theater",
        title: "Lincoln Center Theater",
        subtitle: "Introduction to Lincoln Center Theater",
        src: require("../../../audioAssets/english/lincoln_center_theater_introduction_to_lincoln_center_theater.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Performing Arts",
        landmark: "Lincoln Center Theater",
        dateAdded: "2024-07-08",
        audioFile: "lincoln_center_theater_introduction_to_lincoln_center_theater.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "150 W 65th St, New York, NY 10023, USA",
        latLong: {
            lat: 40.772464,
            long: -73.983489
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "lincoln_center_theater_role_in_new_york's_theater_scene"
    },
    {
        rid: "lincoln_center_theater_role_in_new_york's_theater_scene",
        title: "Lincoln Center Theater",
        subtitle: "Role In New York'S Theater Scene",
        src: require("../../../audioAssets/english/lincoln_center_theater_role_in_new_york's_theater_scene.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Theater",
        landmark: "Lincoln Center Theater",
        dateAdded: "2024-07-08",
        audioFile: "lincoln_center_theater_role_in_new_york's_theater_scene.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "150 W 65th St, New York, NY 10023, USA",
        latLong: {
            lat: 40.772464,
            long: -73.983489
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "lincoln_center_theater_noteworthy_productions"
    },
    {
        rid: "lincoln_center_theater_noteworthy_productions",
        title: "Lincoln Center Theater",
        subtitle: "Noteworthy Productions",
        src: require("../../../audioAssets/english/lincoln_center_theater_noteworthy_productions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Performing Arts",
        landmark: "Lincoln Center Theater",
        dateAdded: "2024-07-08",
        audioFile: "lincoln_center_theater_noteworthy_productions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "150 W 65th St, New York, NY 10023, USA",
        latLong: {
            lat: 40.772464,
            long: -73.983489
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "lincoln_center_theater_history_and_development"
    },
    {
        rid: "lincoln_center_theater_history_and_development",
        title: "Lincoln Center Theater",
        subtitle: "History And Development",
        src: require("../../../audioAssets/english/lincoln_center_theater_history_and_development.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Performing Arts",
        landmark: "Lincoln Center Theater",
        dateAdded: "2024-07-08",
        audioFile: "lincoln_center_theater_history_and_development.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "150 W 65th St, New York, NY 10023, USA",
        latLong: {
            lat: 40.772464,
            long: -73.983489
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "julliard_role_in_performing_arts_education"
    },
    {
        rid: "julliard_role_in_performing_arts_education",
        title: "Juilliard School",
        subtitle: "Role In Universities",
        src: require("../../../audioAssets/english/julliard_role_in_performing_arts_education.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Center",
        landMarkCategory: "Universities",
        landmark: "Juilliard School",
        dateAdded: "2024-07-08",
        audioFile: "julliard_role_in_performing_arts_education.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "60 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7737,
            long: -73.9835
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "julliard_founding_and_early_years"
    },
    {
        rid: "julliard_founding_and_early_years",
        title: "Juilliard School",
        subtitle: "Founding And Early Years",
        src: require("../../../audioAssets/english/julliard_founding_and_early_years.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Center",
        landMarkCategory: "Universities",
        landmark: "Juilliard School",
        dateAdded: "2024-07-08",
        audioFile: "julliard_founding_and_early_years.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "60 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7737,
            long: -73.9835
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "julliard_famous_alumni"
    },
    {
        rid: "julliard_famous_alumni",
        title: "Juilliard School",
        subtitle: "Famous Alumni",
        src: require("../../../audioAssets/english/julliard_famous_alumni.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Center",
        landMarkCategory: "Universities",
        landmark: "Juilliard School",
        dateAdded: "2024-07-08",
        audioFile: "julliard_famous_alumni.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "60 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7737,
            long: -73.9835
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "julliard_introduction_to_julliard"
    },
    {
        rid: "julliard_introduction_to_julliard",
        title: "Juilliard School",
        subtitle: "Introduction to Julliard",
        src: require("../../../audioAssets/english/julliard_introduction_to_julliard.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Center",
        landMarkCategory: "Universities",
        landmark: "Juilliard School",
        dateAdded: "2024-07-08",
        audioFile: "julliard_introduction_to_julliard.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "60 Lincoln Center Plaza, New York, NY 10023",
        latLong: {
            lat: 40.7737,
            long: -73.9835
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "tompkins_square_park_role_in_the_community"
    },
    {
        rid: "tompkins_square_park_role_in_the_community",
        title: "Tompkins Square Park",
        subtitle: "Role In The Community",
        src: require("../../../audioAssets/english/tompkins_square_park_role_in_the_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Public Park",
        landmark: "Tompkins Square Park",
        dateAdded: "2024-07-08",
        audioFile: "tompkins_square_park_role_in_the_community.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Avenue A, New York, NY 10009",
        latLong: {
            lat: 40.7265,
            long: -73.9815
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "tompkins_square_park_events_and_activities"
    },
    {
        rid: "tompkins_square_park_events_and_activities",
        title: "Tompkins Square Park",
        subtitle: "Events And Activities",
        src: require("../../../audioAssets/english/tompkins_square_park_events_and_activities.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Public Park",
        landmark: "Tompkins Square Park",
        dateAdded: "2024-07-08",
        audioFile: "tompkins_square_park_events_and_activities.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Avenue A, New York, NY 10009",
        latLong: {
            lat: 40.7265,
            long: -73.9815
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "tompkins_square_park_history_and_evolution"
    },
    {
        rid: "tompkins_square_park_history_and_evolution",
        title: "Tompkins Square Park",
        subtitle: "History And Evolution",
        src: require("../../../audioAssets/english/tompkins_square_park_history_and_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Public Park",
        landmark: "Tompkins Square Park",
        dateAdded: "2024-07-08",
        audioFile: "tompkins_square_park_history_and_evolution.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Avenue A, New York, NY 10009",
        latLong: {
            lat: 40.7265,
            long: -73.9815
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "tompkins_square_park_introduction_to_tompkins_square_park"
    },
    {
        rid: "tompkins_square_park_introduction_to_tompkins_square_park",
        title: "Tompkins Square Park",
        subtitle: "Introduction to Tompkins Square Park",
        src: require("../../../audioAssets/english/tompkins_square_park_introduction_to_tompkins_square_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Public Park",
        landmark: "Tompkins Square Park",
        dateAdded: "2024-07-08",
        audioFile: "tompkins_square_park_introduction_to_tompkins_square_park.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Avenue A, New York, NY 10009",
        latLong: {
            lat: 40.7265,
            long: -73.9815
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "central_park_overview_of_central_park"
    },
    {
        rid: "central_park_overview_of_central_park",
        title: "Central Park",
        subtitle: "Overview Of Central Park",
        src: require("../../../audioAssets/english/central_park_overview_of_central_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Central Park",
        dateAdded: "2024-07-08",
        audioFile: "central_park_overview_of_central_park.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "New York, NY 10024, United States",
        latLong: {
            lat: 40.785091,
            long: -73.968285
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "central_park_major_attractions"
    },
    {
        rid: "central_park_major_attractions",
        title: "Central Park",
        subtitle: "Major Attractions",
        src: require("../../../audioAssets/english/central_park_major_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Central Park",
        dateAdded: "2024-07-08",
        audioFile: "central_park_major_attractions.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "New York, NY 10024, United States",
        latLong: {
            lat: 40.785091,
            long: -73.968285
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "central_park_cultural_and_social_impact"
    },
    {
        rid: "central_park_cultural_and_social_impact",
        title: "Central Park",
        subtitle: "Cultural And Social Impact",
        src: require("../../../audioAssets/english/central_park_cultural_and_social_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Central Park",
        dateAdded: "2024-07-08",
        audioFile: "central_park_cultural_and_social_impact.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "New York, NY 10024, United States",
        latLong: {
            lat: 40.785091,
            long: -73.968285
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "central_park_history_and_creation"
    },
    {
        rid: "central_park_history_and_creation",
        title: "Central Park",
        subtitle: "History And Creation",
        src: require("../../../audioAssets/english/central_park_history_and_creation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Manhattan",
        landMarkCategory: "Public Park",
        landmark: "Central Park",
        dateAdded: "2024-07-08",
        audioFile: "central_park_history_and_creation.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "New York, NY 10024, United States",
        latLong: {
            lat: 40.785091,
            long: -73.968285
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_historical_significance"
    },
    {
        rid: "washington_square_park_historical_significance",
        title: "Washington Square Park",
        subtitle: "Historical Significance",
        src: require("../../../audioAssets/english/washington_square_park_historical_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-08",
        audioFile: "washington_square_park_historical_significance.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: {
            lat: 40.730823,
            long: -73.997332
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_cultural_impact"
    },
    {
        rid: "washington_square_park_cultural_impact",
        title: "Washington Square Park",
        subtitle: "Cultural Impact",
        src: require("../../../audioAssets/english/washington_square_park_cultural_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-08",
        audioFile: "washington_square_park_cultural_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: {
            lat: 40.730823,
            long: -73.997332
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_famous_features"
    },
    {
        rid: "washington_square_park_famous_features",
        title: "Washington Square Park",
        subtitle: "Famous Features",
        src: require("../../../audioAssets/english/washington_square_park_famous_features.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-08",
        audioFile: "washington_square_park_famous_features.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: {
            lat: 40.730823,
            long: -73.997332
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_overview_of_washington_square_park"
    },
    {
        rid: "washington_square_park_overview_of_washington_square_park",
        title: "Washington Square Park",
        subtitle: "Overview Of Washington Square Park",
        src: require("../../../audioAssets/english/washington_square_park_overview_of_washington_square_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-08",
        audioFile: "washington_square_park_overview_of_washington_square_park.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: {
            lat: 40.730823,
            long: -73.997332
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_ballet_impact_on_ballet_worldwide"
    },
    {
        rid: "ny_ballet_impact_on_ballet_worldwide",
        title: "New York City Ballet",
        subtitle: "Impact On Ballet Worldwide",
        src: require("../../../audioAssets/english/ny_ballet_impact_on_ballet_worldwide.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Ballet",
        dateAdded: "2024-07-08",
        audioFile: "ny_ballet_impact_on_ballet_worldwide.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_ballet_famous_performances"
    },
    {
        rid: "ny_ballet_famous_performances",
        title: "New York City Ballet",
        subtitle: "Famous Performances",
        src: require("../../../audioAssets/english/ny_ballet_famous_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Ballet",
        dateAdded: "2024-07-08",
        audioFile: "ny_ballet_famous_performances.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_ballet_introduction_to_ny_ballet"
    },
    {
        rid: "ny_ballet_introduction_to_ny_ballet",
        title: "New York City Ballet",
        subtitle: "Introduction to Ny Ballet",
        src: require("../../../audioAssets/english/ny_ballet_introduction_to_ny_ballet.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Ballet",
        dateAdded: "2024-07-08",
        audioFile: "ny_ballet_introduction_to_ny_ballet.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "ny_ballet_founding_and_evolution"
    },
    {
        rid: "ny_ballet_founding_and_evolution",
        title: "New York City Ballet",
        subtitle: "Founding And Evolution",
        src: require("../../../audioAssets/english/ny_ballet_founding_and_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "New York City Ballet",
        dateAdded: "2024-07-08",
        audioFile: "ny_ballet_founding_and_evolution.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "20 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: {
            lat: 40.7725,
            long: -73.9833
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_river_park_community_and_environmental_benefits"
    },
    {
        rid: "east_river_park_community_and_environmental_benefits",
        title: "East River Park",
        subtitle: "Community And Environmental Benefits",
        src: require("../../../audioAssets/english/east_river_park_community_and_environmental_benefits.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Park",
        landmark: "East River Park",
        dateAdded: "2024-07-08",
        audioFile: "east_river_park_community_and_environmental_benefits.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Houston St to Montgomery St, FDR Drive, New York, NY 10002",
        latLong: {
            lat: 40.7174,
            long: -73.9719
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_river_park_recreational_activities"
    },
    {
        rid: "east_river_park_recreational_activities",
        title: "East River Park",
        subtitle: "Recreational Activities",
        src: require("../../../audioAssets/english/east_river_park_recreational_activities.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Park",
        landmark: "East River Park",
        dateAdded: "2024-07-08",
        audioFile: "east_river_park_recreational_activities.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Houston St to Montgomery St, FDR Drive, New York, NY 10002",
        latLong: {
            lat: 40.7174,
            long: -73.9719
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_river_park_history_and_redevelopment"
    },
    {
        rid: "east_river_park_history_and_redevelopment",
        title: "East River Park",
        subtitle: "History And Redevelopment",
        src: require("../../../audioAssets/english/east_river_park_history_and_redevelopment.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Park",
        landmark: "East River Park",
        dateAdded: "2024-07-08",
        audioFile: "east_river_park_history_and_redevelopment.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Houston St to Montgomery St, FDR Drive, New York, NY 10002",
        latLong: {
            lat: 40.7174,
            long: -73.9719
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_river_park_introduction_to_east_river_park"
    },
    {
        rid: "east_river_park_introduction_to_east_river_park",
        title: "East River Park",
        subtitle: "Introduction to East River Park",
        src: require("../../../audioAssets/english/east_river_park_introduction_to_east_river_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Park",
        landmark: "East River Park",
        dateAdded: "2024-07-08",
        audioFile: "east_river_park_introduction_to_east_river_park.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Houston St to Montgomery St, FDR Drive, New York, NY 10002",
        latLong: {
            lat: 40.7174,
            long: -73.9719
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "columbia_university_founding_and_early_years"
    },
    {
        rid: "columbia_university_founding_and_early_years",
        title: "Columbia University",
        subtitle: "Founding and Early Years",
        src: require("../../../audioAssets/english/columbia_university_founding_and_early_years.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Columbia University",
        dateAdded: "2024-07-08",
        audioFile: "columbia_university_founding_and_early_years.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "116th St & Broadway, New York, NY 10027",
        latLong: {
            lat: 40.807536,
            long: -73.962573
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "columbia_university_architectural_highlights"
    },
    {
        rid: "columbia_university_architectural_highlights",
        title: "Columbia University",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/columbia_university_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Columbia University",
        dateAdded: "2024-07-08",
        audioFile: "columbia_university_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "116th St & Broadway, New York, NY 10027",
        latLong: {
            lat: 40.807536,
            long: -73.962573
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "columbia_university_notable_alumni_and_contributions"
    },
    {
        rid: "columbia_university_notable_alumni_and_contributions",
        title: "Columbia University",
        subtitle: "Notable Alumni and Contributions",
        src: require("../../../audioAssets/english/columbia_university_notable_alumni_and_contributions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Columbia University",
        dateAdded: "2024-07-08",
        audioFile: "columbia_university_notable_alumni_and_contributions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "116th St & Broadway, New York, NY 10027",
        latLong: {
            lat: 40.807536,
            long: -73.962573
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "columbia_university_columbia's_role_in_the_modern_era"
    },
    {
        rid: "columbia_university_columbia's_role_in_the_modern_era",
        title: "Columbia University",
        subtitle: "Columbia's Role in the Modern Era",
        src: require("../../../audioAssets/english/columbia_university_columbia's_role_in_the_modern_era.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Columbia University",
        dateAdded: "2024-07-08",
        audioFile: "columbia_university_columbia's_role_in_the_modern_era.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "116th St & Broadway, New York, NY 10027",
        latLong: {
            lat: 40.807536,
            long: -73.962573
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_new_school_introduction_and_historical_background"
    },
    {
        rid: "the_new_school_introduction_and_historical_background",
        title: "The New School",
        subtitle: "Introduction and Historical Background",
        src: require("../../../audioAssets/english/the_new_school_introduction_and_historical_background.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "The New School",
        dateAdded: "2024-07-08",
        audioFile: "the_new_school_introduction_and_historical_background.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "66 West 12th Street, New York, NY 10011",
        latLong: {
            lat: 40.7355,
            long: -73.9966
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_new_school_architectural_highlights_and_campus_layout"
    },
    {
        rid: "the_new_school_architectural_highlights_and_campus_layout",
        title: "The New School",
        subtitle: "Architectural Highlights and Campus Layout",
        src: require("../../../audioAssets/english/the_new_school_architectural_highlights_and_campus_layout.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "The New School",
        dateAdded: "2024-07-08",
        audioFile: "the_new_school_architectural_highlights_and_campus_layout.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "66 West 12th Street, New York, NY 10011",
        latLong: {
            lat: 40.7355,
            long: -73.9966
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_new_school_influential_figures_and_alumni"
    },
    {
        rid: "the_new_school_influential_figures_and_alumni",
        title: "The New School",
        subtitle: "Influential Figures and Alumni",
        src: require("../../../audioAssets/english/the_new_school_influential_figures_and_alumni.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "The New School",
        dateAdded: "2024-07-08",
        audioFile: "the_new_school_influential_figures_and_alumni.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "66 West 12th Street, New York, NY 10011",
        latLong: {
            lat: 40.7355,
            long: -73.9966
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_new_school_impact_on_new_york_city's_educational_and_cultural_landscape"
    },
    {
        rid: "the_new_school_impact_on_new_york_city's_educational_and_cultural_landscape",
        title: "The New School",
        subtitle: "Impact on NYC's Landscape",
        src: require("../../../audioAssets/english/the_new_school_impact_on_new_york_city's_educational_and_cultural_landscape.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Universities",
        landmark: "The New School",
        dateAdded: "2024-07-08",
        audioFile: "the_new_school_impact_on_new_york_city's_educational_and_cultural_landscape.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "66 West 12th Street, New York, NY 10011",
        latLong: {
            lat: 40.7355,
            long: -73.9966
        },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_plaza_hotel_history_and_origins"
    },
    {
        rid: "the_plaza_hotel_history_and_origins",
        title: "The Plaza Hotel",
        subtitle: "History and Origins",
        src: require("../../../audioAssets/english/the_plaza_hotel_history_and_origins.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "The Plaza Hotel",
        dateAdded: "2024-07-09",
        audioFile: "the_plaza_hotel_history_and_origins.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "768 5th Ave, New York, NY 10019",
        latLong: { lat: 40.764527, long: -73.974152 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_plaza_hotel_architectural_brilliance"
    },
    {
        rid: "the_plaza_hotel_architectural_brilliance",
        title: "The Plaza Hotel",
        subtitle: "Architectural Brilliance",
        src: require("../../../audioAssets/english/the_plaza_hotel_architectural_brilliance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "The Plaza Hotel",
        dateAdded: "2024-07-09",
        audioFile: "the_plaza_hotel_architectural_brilliance.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "768 5th Ave, New York, NY 10019",
        latLong: { lat: 40.764527, long: -73.974152 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_plaza_hotel_famous_guests_and_moments"
    },
    {
        rid: "the_plaza_hotel_famous_guests_and_moments",
        title: "The Plaza Hotel",
        subtitle: "Famous Guests and Moments",
        src: require("../../../audioAssets/english/the_plaza_hotel_famous_guests_and_moments.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "The Plaza Hotel",
        dateAdded: "2024-07-09",
        audioFile: "the_plaza_hotel_famous_guests_and_moments.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "768 5th Ave, New York, NY 10019",
        latLong: { lat: 40.764527, long: -73.974152 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_plaza_hotel_role_in_pop_culture"
    },
    {
        rid: "the_plaza_hotel_role_in_pop_culture",
        title: "The Plaza Hotel",
        subtitle: "Role in Pop Culture",
        src: require("../../../audioAssets/english/the_plaza_hotel_role_in_pop_culture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "The Plaza Hotel",
        dateAdded: "2024-07-09",
        audioFile: "the_plaza_hotel_role_in_pop_culture.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "768 5th Ave, New York, NY 10019",
        latLong: { lat: 40.764527, long: -73.974152 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyit_introduction_to_nyit"
    },
    {
        rid: "nyit_introduction_to_nyit",
        title: "New York Institute of Technology (NYIT)",
        subtitle: "Introduction to NYIT",
        src: require("../../../audioAssets/english/nyit_introduction_to_nyit.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Universities",
        landmark: "New York Institute of Technology (NYIT)",
        dateAdded: "2024-07-09",
        audioFile: "nyit_introduction_to_nyit.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1855 Broadway, New York, NY 10023",
        latLong: { lat: 40.7693, long: -73.9835 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyit_architectural_design_and_campus"
    },
    {
        rid: "nyit_architectural_design_and_campus",
        title: "New York Institute of Technology (NYIT)",
        subtitle: "Architectural Design and Campus",
        src: require("../../../audioAssets/english/nyit_architectural_design_and_campus.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Universities",
        landmark: "New York Institute of Technology (NYIT)",
        dateAdded: "2024-07-09",
        audioFile: "nyit_architectural_design_and_campus.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1855 Broadway, New York, NY 10023",
        latLong: { lat: 40.7693, long: -73.9835 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyit_evolution_over_time"
    },
    {
        rid: "nyit_evolution_over_time",
        title: "New York Institute of Technology (NYIT)",
        subtitle: "Evolution Over Time",
        src: require("../../../audioAssets/english/nyit_evolution_over_time.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Universities",
        landmark: "New York Institute of Technology (NYIT)",
        dateAdded: "2024-07-09",
        audioFile: "nyit_evolution_over_time.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1855 Broadway, New York, NY 10023",
        latLong: { lat: 40.7693, long: -73.9835 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nyit_nyit's_impact_and_legacy"
    },
    {
        rid: "nyit_nyit's_impact_and_legacy",
        title: "New York Institute of Technology (NYIT)",
        subtitle: "NYIT's Impact and Legacy",
        src: require("../../../audioAssets/english/nyit_nyit's_impact_and_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Universities",
        landmark: "New York Institute of Technology (NYIT)",
        dateAdded: "2024-07-09",
        audioFile: "nyit_nyit's_impact_and_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1855 Broadway, New York, NY 10023",
        latLong: { lat: 40.7693, long: -73.9835 },
        isPremium: false,
        premiumPackage: null,
        playNext: "john_jay_college_introduction_to_john_jay_college"
    },
    {
        rid: "john_jay_college_introduction_to_john_jay_college",
        title: "John Jay College",
        subtitle: "Introduction to John Jay College",
        src: require("../../../audioAssets/english/john_jay_college_introduction_to_john_jay_college.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Universities",
        landmark: "John Jay College",
        dateAdded: "2024-07-09",
        audioFile: "john_jay_college_introduction_to_john_jay_college.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "524 W 59th St, New York, NY 10019",
        latLong: { lat: 40.770278, long: -73.989444 },
        isPremium: false,
        premiumPackage: null,
        playNext: "john_jay_college_founding_and_early_years"
    },
    {
        rid: "john_jay_college_founding_and_early_years",
        title: "John Jay College",
        subtitle: "Founding and Early Years",
        src: require("../../../audioAssets/english/john_jay_college_founding_and_early_years.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Universities",
        landmark: "John Jay College",
        dateAdded: "2024-07-09",
        audioFile: "john_jay_college_founding_and_early_years.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "524 W 59th St, New York, NY 10019",
        latLong: { lat: 40.770278, long: -73.989444 },
        isPremium: false,
        premiumPackage: null,
        playNext: "john_jay_college_architectural_design_and_campus"
    },
    {
        rid: "john_jay_college_architectural_design_and_campus",
        title: "John Jay College",
        subtitle: "Architectural Design and Campus",
        src: require("../../../audioAssets/english/john_jay_college_architectural_design_and_campus.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Universities",
        landmark: "John Jay College",
        dateAdded: "2024-07-09",
        audioFile: "john_jay_college_architectural_design_and_campus.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "524 W 59th St, New York, NY 10019",
        latLong: { lat: 40.770278, long: -73.989444 },
        isPremium: false,
        premiumPackage: null,
        playNext: "john_jay_college_academic_excellence_and_contributions"
    },
    {
        rid: "john_jay_college_academic_excellence_and_contributions",
        title: "John Jay College",
        subtitle: "Academic Excellence and Contributions",
        src: require("../../../audioAssets/english/john_jay_college_academic_excellence_and_contributions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Universities",
        landmark: "John Jay College",
        dateAdded: "2024-07-09",
        audioFile: "john_jay_college_academic_excellence_and_contributions.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "524 W 59th St, New York, NY 10019",
        latLong: { lat: 40.770278, long: -73.989444 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_boat_house_historical_evolution"
    },
    {
        rid: "the_boat_house_historical_evolution",
        title: "The Loeb Boathouse",
        subtitle: "Historical Evolution",
        src: require("../../../audioAssets/english/the_boat_house_historical_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Iconic Destination",
        landmark: "The Loeb Boathouse",
        dateAdded: "2024-07-09",
        audioFile: "the_boat_house_historical_evolution.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "East 72nd Street and Park Drive North, New York, NY 10021",
        latLong: { lat: 40.774291, long: -73.969553 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_boat_house_architectural_elements"
    },
    {
        rid: "the_boat_house_architectural_elements",
        title: "The Loeb Boathouse",
        subtitle: "Architectural Elements",
        src: require("../../../audioAssets/english/the_boat_house_architectural_elements.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Iconic Destination",
        landmark: "The Loeb Boathouse",
        dateAdded: "2024-07-09",
        audioFile: "the_boat_house_architectural_elements.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "East 72nd Street and Park Drive North, New York, NY 10021",
        latLong: { lat: 40.774291, long: -73.969553 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_boat_house_life_on_the_lake"
    },
    {
        rid: "the_boat_house_life_on_the_lake",
        title: "The Loeb Boathouse",
        subtitle: "Life on the Lake",
        src: require("../../../audioAssets/english/the_boat_house_life_on_the_lake.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Iconic Destination",
        landmark: "The Loeb Boathouse",
        dateAdded: "2024-07-09",
        audioFile: "the_boat_house_life_on_the_lake.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "East 72nd Street and Park Drive North, New York, NY 10021",
        latLong: { lat: 40.774291, long: -73.969553 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_boat_house_a_day_in_central_park"
    },
    {
        rid: "the_boat_house_a_day_in_central_park",
        title: "The Loeb Boathouse",
        subtitle: "A Day in Central Park",
        src: require("../../../audioAssets/english/the_boat_house_a_day_in_central_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Iconic Destination",
        landmark: "The Loeb Boathouse",
        dateAdded: "2024-07-09",
        audioFile: "the_boat_house_a_day_in_central_park.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "East 72nd Street and Park Drive North, New York, NY 10021",
        latLong: { lat: 40.774291, long: -73.969553 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_great_lawn_an_oasis_in_central_park"
    },
    {
        rid: "the_great_lawn_an_oasis_in_central_park",
        title: "The Great Lawn",
        subtitle: "An Oasis in Central Park",
        src: require("../../../audioAssets/english/the_great_lawn_an_oasis_in_central_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Public Park",
        landmark: "The Great Lawn",
        dateAdded: "2024-07-09",
        audioFile: "the_great_lawn_an_oasis_in_central_park.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Central Park, New York, NY 10024",
        latLong: { lat: 40.7825, long: -73.9663 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_great_lawn_design_and_development"
    },
    {
        rid: "the_great_lawn_design_and_development",
        title: "The Great Lawn",
        subtitle: "Design and Development",
        src: require("../../../audioAssets/english/the_great_lawn_design_and_development.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Public Park",
        landmark: "The Great Lawn",
        dateAdded: "2024-07-09",
        audioFile: "the_great_lawn_design_and_development.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Central Park, New York, NY 10024",
        latLong: { lat: 40.7825, long: -73.9663 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_great_lawn_historic_events_and_performances"
    },
    {
        rid: "the_great_lawn_historic_events_and_performances",
        title: "The Great Lawn",
        subtitle: "Historic Events and Performances",
        src: require("../../../audioAssets/english/the_great_lawn_historic_events_and_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Public Park",
        landmark: "The Great Lawn",
        dateAdded: "2024-07-09",
        audioFile: "the_great_lawn_historic_events_and_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Central Park, New York, NY 10024",
        latLong: { lat: 40.7825, long: -73.9663 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_great_lawn_the_lawn_today_and_its_impact"
    },
    {
        rid: "the_great_lawn_the_lawn_today_and_its_impact",
        title: "The Great Lawn",
        subtitle: "The Lawn Today and Its Impact",
        src: require("../../../audioAssets/english/the_great_lawn_the_lawn_today_and_its_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Central Park",
        landMarkCategory: "Public Park",
        landmark: "The Great Lawn",
        dateAdded: "2024-07-09",
        audioFile: "the_great_lawn_the_lawn_today_and_its_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Central Park, New York, NY 10024",
        latLong: { lat: 40.7825, long: -73.9663 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_met_introduction_and_overview"
    },
    {
        rid: "the_met_introduction_and_overview",
        title: "The Met",
        subtitle: "Introduction and Overview",
        src: require("../../../audioAssets/english/the_met_introduction_and_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "The Met",
        dateAdded: "2024-07-09",
        audioFile: "the_met_introduction_and_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1000 5th Ave, New York, NY 10028, United States",
        latLong: { lat: 40.779437, long: -73.963244 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_met_the_architectural_marvel"
    },
    {
        rid: "the_met_the_architectural_marvel",
        title: "The Met",
        subtitle: "The Architectural Marvel",
        src: require("../../../audioAssets/english/the_met_the_architectural_marvel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "The Met",
        dateAdded: "2024-07-09",
        audioFile: "the_met_the_architectural_marvel.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1000 5th Ave, New York, NY 10028, United States",
        latLong: { lat: 40.779437, long: -73.963244 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_met_unveiling_the_collections"
    },
    {
        rid: "the_met_unveiling_the_collections",
        title: "The Met",
        subtitle: "Unveiling the Collections",
        src: require("../../../audioAssets/english/the_met_unveiling_the_collections.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "The Met",
        dateAdded: "2024-07-09",
        audioFile: "the_met_unveiling_the_collections.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1000 5th Ave, New York, NY 10028, United States",
        latLong: { lat: 40.779437, long: -73.963244 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_met_cultural_impact_and_noteworthy_exhibits"
    },
    {
        rid: "the_met_cultural_impact_and_noteworthy_exhibits",
        title: "The Met",
        subtitle: "Cultural Impact and Noteworthy Exhibits",
        src: require("../../../audioAssets/english/the_met_cultural_impact_and_noteworthy_exhibits.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "The Met",
        dateAdded: "2024-07-09",
        audioFile: "the_met_cultural_impact_and_noteworthy_exhibits.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1000 5th Ave, New York, NY 10028, United States",
        latLong: { lat: 40.779437, long: -73.963244 },
        isPremium: false,
        premiumPackage: null,
        playNext: "museum_of_modern_art_(moma)_introduction_to_moma"
    },
    {
        rid: "museum_of_modern_art_(moma)_introduction_to_moma",
        title: "Museum of Modern Art (MoMA)",
        subtitle: "Introduction to MoMA",
        src: require("../../../audioAssets/english/museum_of_modern_art_(moma)_introduction_to_moma.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Museums",
        landmark: "Museum of Modern Art (MoMA)",
        dateAdded: "2024-07-09",
        audioFile: "museum_of_modern_art_(moma)_introduction_to_moma.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "11 W 53rd St, New York, NY 10019, USA",
        latLong: { lat: 40.7614, long: -73.9776 },
        isPremium: false,
        premiumPackage: null,
        playNext: "museum_of_modern_art_(moma)_founding_and_early_years"
    },
    {
        rid: "museum_of_modern_art_(moma)_founding_and_early_years",
        title: "Museum of Modern Art (MoMA)",
        subtitle: "Founding and Early Years",
        src: require("../../../audioAssets/english/museum_of_modern_art_(moma)_founding_and_early_years.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Museums",
        landmark: "Museum of Modern Art (MoMA)",
        dateAdded: "2024-07-09",
        audioFile: "museum_of_modern_art_(moma)_founding_and_early_years.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "11 W 53rd St, New York, NY 10019, USA",
        latLong: { lat: 40.7614, long: -73.9776 },
        isPremium: false,
        premiumPackage: null,
        playNext: "museum_of_modern_art_(moma)_iconic_collections_and_exhibits"
    },
    {
        rid: "museum_of_modern_art_(moma)_iconic_collections_and_exhibits",
        title: "Museum of Modern Art (MoMA)",
        subtitle: "Iconic Collections and Exhibits",
        src: require("../../../audioAssets/english/museum_of_modern_art_(moma)_iconic_collections_and_exhibits.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Museums",
        landmark: "Museum of Modern Art (MoMA)",
        dateAdded: "2024-07-09",
        audioFile: "museum_of_modern_art_(moma)_iconic_collections_and_exhibits.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "11 W 53rd St, New York, NY 10019, USA",
        latLong: { lat: 40.7614, long: -73.9776 },
        isPremium: false,
        premiumPackage: null,
        playNext: "museum_of_modern_art_(moma)_architectural_evolution"
    },
    {
        rid: "museum_of_modern_art_(moma)_architectural_evolution",
        title: "Museum of Modern Art (MoMA)",
        subtitle: "Architectural Evolution",
        src: require("../../../audioAssets/english/museum_of_modern_art_(moma)_architectural_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Museums",
        landmark: "Museum of Modern Art (MoMA)",
        dateAdded: "2024-07-09",
        audioFile: "museum_of_modern_art_(moma)_architectural_evolution.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "11 W 53rd St, New York, NY 10019, USA",
        latLong: { lat: 40.7614, long: -73.9776 },
        isPremium: false,
        premiumPackage: null,
        playNext: "american_museum_of_natural_history_a_brief_overview"
    },
    {
        rid: "american_museum_of_natural_history_a_brief_overview",
        title: "American Museum of Natural History",
        subtitle: "A Brief Overview",
        src: require("../../../audioAssets/english/american_museum_of_natural_history_a_brief_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Museums",
        landmark: "American Museum of Natural History",
        dateAdded: "2024-07-09",
        audioFile: "american_museum_of_natural_history_a_brief_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "200 Central Park West, New York, NY 10024",
        latLong: { lat: 40.7813241, long: -73.9739882 },
        isPremium: false,
        premiumPackage: null,
        playNext: "american_museum_of_natural_history_architectural_wonders"
    },
    {
        rid: "american_museum_of_natural_history_architectural_wonders",
        title: "American Museum of Natural History",
        subtitle: "Architectural Wonders",
        src: require("../../../audioAssets/english/american_museum_of_natural_history_architectural_wonders.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Museums",
        landmark: "American Museum of Natural History",
        dateAdded: "2024-07-09",
        audioFile: "american_museum_of_natural_history_architectural_wonders.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "200 Central Park West, New York, NY 10024",
        latLong: { lat: 40.7813241, long: -73.9739882 },
        isPremium: false,
        premiumPackage: null,
        playNext: "american_museum_of_natural_history_evolution_of_exhibits"
    },
    {
        rid: "american_museum_of_natural_history_evolution_of_exhibits",
        title: "American Museum of Natural History",
        subtitle: "Evolution of Exhibits",
        src: require("../../../audioAssets/english/american_museum_of_natural_history_evolution_of_exhibits.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Museums",
        landmark: "American Museum of Natural History",
        dateAdded: "2024-07-09",
        audioFile: "american_museum_of_natural_history_evolution_of_exhibits.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "200 Central Park West, New York, NY 10024",
        latLong: { lat: 40.7813241, long: -73.9739882 },
        isPremium: false,
        premiumPackage: null,
        playNext: "american_museum_of_natural_history_impact_on_science_and_education"
    },
    {
        rid: "american_museum_of_natural_history_impact_on_science_and_education",
        title: "American Museum of Natural History",
        subtitle: "Impact on Science and Education",
        src: require("../../../audioAssets/english/american_museum_of_natural_history_impact_on_science_and_education.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Museums",
        landmark: "American Museum of Natural History",
        dateAdded: "2024-07-09",
        audioFile: "american_museum_of_natural_history_impact_on_science_and_education.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "200 Central Park West, New York, NY 10024",
        latLong: { lat: 40.7813241, long: -73.9739882 },
        isPremium: false,
        premiumPackage: null,
        playNext: "guggenheim_museum_visionary_beginnings"
    },
    {
        rid: "guggenheim_museum_visionary_beginnings",
        title: "Guggenheim Museum",
        subtitle: "Visionary Beginnings",
        src: require("../../../audioAssets/english/guggenheim_museum_visionary_beginnings.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "Guggenheim Museum",
        dateAdded: "2024-07-09",
        audioFile: "guggenheim_museum_visionary_beginnings.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1071 Fifth Avenue, New York, NY 10128",
        latLong: { lat: 40.782979, long: -73.95897 },
        isPremium: false,
        premiumPackage: null,
        playNext: "guggenheim_museum_revolutionary_architecture"
    },
    {
        rid: "guggenheim_museum_revolutionary_architecture",
        title: "Guggenheim Museum",
        subtitle: "Revolutionary Architecture",
        src: require("../../../audioAssets/english/guggenheim_museum_revolutionary_architecture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "Guggenheim Museum",
        dateAdded: "2024-07-09",
        audioFile: "guggenheim_museum_revolutionary_architecture.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1071 Fifth Avenue, New York, NY 10128",
        latLong: { lat: 40.782979, long: -73.95897 },
        isPremium: false,
        premiumPackage: null,
        playNext: "guggenheim_museum_the_art_collections"
    },
    {
        rid: "guggenheim_museum_the_art_collections",
        title: "Guggenheim Museum",
        subtitle: "The Art Collections",
        src: require("../../../audioAssets/english/guggenheim_museum_the_art_collections.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "Guggenheim Museum",
        dateAdded: "2024-07-09",
        audioFile: "guggenheim_museum_the_art_collections.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1071 Fifth Avenue, New York, NY 10128",
        latLong: { lat: 40.782979, long: -73.95897 },
        isPremium: false,
        premiumPackage: null,
        playNext: "guggenheim_museum_impact_and_legacy"
    },
    {
        rid: "guggenheim_museum_impact_and_legacy",
        title: "Guggenheim Museum",
        subtitle: "Impact and Legacy",
        src: require("../../../audioAssets/english/guggenheim_museum_impact_and_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Museums",
        landmark: "Guggenheim Museum",
        dateAdded: "2024-07-09",
        audioFile: "guggenheim_museum_impact_and_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1071 Fifth Avenue, New York, NY 10128",
        latLong: { lat: 40.782979, long: -73.95897 },
        isPremium: false,
        premiumPackage: null,
        playNext: "whitney_museum_of_american_art_a_visionary_beginning:_gertrude_vanderbilt_whitney"
    },
    {
        rid: "whitney_museum_of_american_art_a_visionary_beginning:_gertrude_vanderbilt_whitney",
        title: "Whitney Museum of American Art",
        subtitle: "A Visionary Beginning: Gertrude Vanderbilt Whitney",
        src: require("../../../audioAssets/english/whitney_museum_of_american_art_a_visionary_beginning:_gertrude_vanderbilt_whitney.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Museums",
        landmark: "Whitney Museum of American Art",
        dateAdded: "2024-07-09",
        audioFile: "whitney_museum_of_american_art_a_visionary_beginning:_gertrude_vanderbilt_whitney.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "99 Gansevoort St, New York, NY 10014, USA",
        latLong: { lat: 40.739587, long: -74.008863 },
        isPremium: false,
        premiumPackage: null,
        playNext: "whitney_museum_of_american_art_architectural_marvels:_the_renzo_piano_design"
    },
    {
        rid: "whitney_museum_of_american_art_architectural_marvels:_the_renzo_piano_design",
        title: "Whitney Museum of American Art",
        subtitle: "Architectural Marvels: The Renzo Piano Design",
        src: require("../../../audioAssets/english/whitney_museum_of_american_art_architectural_marvels:_the_renzo_piano_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Museums",
        landmark: "Whitney Museum of American Art",
        dateAdded: "2024-07-09",
        audioFile: "whitney_museum_of_american_art_architectural_marvels:_the_renzo_piano_design.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "99 Gansevoort St, New York, NY 10014, USA",
        latLong: { lat: 40.739587, long: -74.008863 },
        isPremium: false,
        premiumPackage: null,
        playNext: "whitney_museum_of_american_art_from_downtown_to_meatpacking:_a_moving_history"
    },
    {
        rid: "whitney_museum_of_american_art_from_downtown_to_meatpacking:_a_moving_history",
        title: "Whitney Museum of American Art",
        subtitle: "From Downtown to Meatpacking: A Moving History",
        src: require("../../../audioAssets/english/whitney_museum_of_american_art_from_downtown_to_meatpacking:_a_moving_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Museums",
        landmark: "Whitney Museum of American Art",
        dateAdded: "2024-07-09",
        audioFile: "whitney_museum_of_american_art_from_downtown_to_meatpacking:_a_moving_history.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "99 Gansevoort St, New York, NY 10014, USA",
        latLong: { lat: 40.739587, long: -74.008863 },
        isPremium: false,
        premiumPackage: null,
        playNext: "whitney_museum_of_american_art_championing_american_art:_iconic_exhibits_and_artists"
    },
    {
        rid: "whitney_museum_of_american_art_championing_american_art:_iconic_exhibits_and_artists",
        title: "Whitney Museum of American Art",
        subtitle: "Championing American Art: Iconic Exhibits and Artists",
        src: require("../../../audioAssets/english/whitney_museum_of_american_art_championing_american_art:_iconic_exhibits_and_artists.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Museums",
        landmark: "Whitney Museum of American Art",
        dateAdded: "2024-07-09",
        audioFile: "whitney_museum_of_american_art_championing_american_art:_iconic_exhibits_and_artists.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "99 Gansevoort St, New York, NY 10014, USA",
        latLong: { lat: 40.739587, long: -74.008863 },
        isPremium: false,
        premiumPackage: null,
        playNext: "intrepid_sea,_air_&_space_museum_introduction_to_the_intrepid"
    },
    {
        rid: "intrepid_sea,_air_&_space_museum_introduction_to_the_intrepid",
        title: "Intrepid Sea, Air & Space Museum",
        subtitle: "Introduction to the Intrepid",
        src: require("../../../audioAssets/english/intrepid_sea,_air_&_space_museum_introduction_to_the_intrepid.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Museums",
        landmark: "Intrepid Sea, Air & Space Museum",
        dateAdded: "2024-07-09",
        audioFile: "intrepid_sea,_air_&_space_museum_introduction_to_the_intrepid.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Pier 86, W 46th St, New York, NY 10036",
        latLong: { lat: 40.7645, long: -73.9996 },
        isPremium: false,
        premiumPackage: null,
        playNext: "intrepid_sea,_air_&_space_museum_the_history_of_the_uss_intrepid"
    },
    {
        rid: "intrepid_sea,_air_&_space_museum_the_history_of_the_uss_intrepid",
        title: "Intrepid Sea, Air & Space Museum",
        subtitle: "The History of the USS Intrepid",
        src: require("../../../audioAssets/english/intrepid_sea,_air_&_space_museum_the_history_of_the_uss_intrepid.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Museums",
        landmark: "Intrepid Sea, Air & Space Museum",
        dateAdded: "2024-07-09",
        audioFile: "intrepid_sea,_air_&_space_museum_the_history_of_the_uss_intrepid.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Pier 86, W 46th St, New York, NY 10036",
        latLong: { lat: 40.7645, long: -73.9996 },
        isPremium: false,
        premiumPackage: null,
        playNext: "intrepid_sea,_air_&_space_museum_exhibits_and_collections"
    },
    {
        rid: "intrepid_sea,_air_&_space_museum_exhibits_and_collections",
        title: "Intrepid Sea, Air & Space Museum",
        subtitle: "Exhibits and Collections",
        src: require("../../../audioAssets/english/intrepid_sea,_air_&_space_museum_exhibits_and_collections.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Museums",
        landmark: "Intrepid Sea, Air & Space Museum",
        dateAdded: "2024-07-09",
        audioFile: "intrepid_sea,_air_&_space_museum_exhibits_and_collections.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Pier 86, W 46th St, New York, NY 10036",
        latLong: { lat: 40.7645, long: -73.9996 },
        isPremium: false,
        premiumPackage: null,
        playNext: "intrepid_sea,_air_&_space_museum_the_intrepid's_role_in_education_and_community"
    },
    {
        rid: "intrepid_sea,_air_&_space_museum_the_intrepid's_role_in_education_and_community",
        title: "Intrepid Sea, Air & Space Museum",
        subtitle: "The Intrepid's Role in Education and Community",
        src: require("../../../audioAssets/english/intrepid_sea,_air_&_space_museum_the_intrepid's_role_in_education_and_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Museums",
        landmark: "Intrepid Sea, Air & Space Museum",
        dateAdded: "2024-07-09",
        audioFile: "intrepid_sea,_air_&_space_museum_the_intrepid's_role_in_education_and_community.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Pier 86, W 46th St, New York, NY 10036",
        latLong: { lat: 40.7645, long: -73.9996 },
        isPremium: false,
        premiumPackage: null,
        playNext: "prospect_park_introduction_to_prospect_park"
    },
    {
        rid: "prospect_park_introduction_to_prospect_park",
        title: "Prospect Park",
        subtitle: "Introduction to Prospect Park",
        src: require("../../../audioAssets/english/prospect_park_introduction_to_prospect_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Park Slope",
        landMarkCategory: "Public Park",
        landmark: "Prospect Park",
        dateAdded: "2024-07-09",
        audioFile: "prospect_park_introduction_to_prospect_park.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "95 Prospect Park West, Brooklyn, NY 11215",
        latLong: { lat: 40.6619, long: -73.968 },
        isPremium: false,
        premiumPackage: null,
        playNext: "prospect_park_design_and_construction"
    },
    {
        rid: "prospect_park_design_and_construction",
        title: "Prospect Park",
        subtitle: "Design and Construction",
        src: require("../../../audioAssets/english/prospect_park_design_and_construction.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Park Slope",
        landMarkCategory: "Public Park",
        landmark: "Prospect Park",
        dateAdded: "2024-07-09",
        audioFile: "prospect_park_design_and_construction.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "95 Prospect Park West, Brooklyn, NY 11215",
        latLong: { lat: 40.6619, long: -73.968 },
        isPremium: false,
        premiumPackage: null,
        playNext: "prospect_park_historical_highlights"
    },
    {
        rid: "prospect_park_historical_highlights",
        title: "Prospect Park",
        subtitle: "Historical Highlights",
        src: require("../../../audioAssets/english/prospect_park_historical_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Park Slope",
        landMarkCategory: "Public Park",
        landmark: "Prospect Park",
        dateAdded: "2024-07-09",
        audioFile: "prospect_park_historical_highlights.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "95 Prospect Park West, Brooklyn, NY 11215",
        latLong: { lat: 40.6619, long: -73.968 },
        isPremium: false,
        premiumPackage: null,
        playNext: "prospect_park_natural_beauty_and_attractions"
    },
    {
        rid: "prospect_park_natural_beauty_and_attractions",
        title: "Prospect Park",
        subtitle: "Natural Beauty and Attractions",
        src: require("../../../audioAssets/english/prospect_park_natural_beauty_and_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Park Slope",
        landMarkCategory: "Public Park",
        landmark: "Prospect Park",
        dateAdded: "2024-07-09",
        audioFile: "prospect_park_natural_beauty_and_attractions.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "95 Prospect Park West, Brooklyn, NY 11215",
        latLong: { lat: 40.6619, long: -73.968 },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_historical_significance"
    },
    {
        rid: "washington_square_park_historical_significance",
        title: "Washington Square Park",
        subtitle: "Historical Significance",
        src: require("../../../audioAssets/english/washington_square_park_historical_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-09",
        audioFile: "washington_square_park_historical_significance.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: { lat: 40.730823, long: -73.997332 },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_architectural_highlights"
    },
    {
        rid: "washington_square_park_architectural_highlights",
        title: "Washington Square Park",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/washington_square_park_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-09",
        audioFile: "washington_square_park_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: { lat: 40.730823, long: -73.997332 },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_cultural_&_social_hub"
    },
    {
        rid: "washington_square_park_cultural_&_social_hub",
        title: "Washington Square Park",
        subtitle: "Cultural & Social Hub",
        src: require("../../../audioAssets/english/washington_square_park_cultural_&_social_hub.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-09",
        audioFile: "washington_square_park_cultural_&_social_hub.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: { lat: 40.730823, long: -73.997332 },
        isPremium: false,
        premiumPackage: null,
        playNext: "washington_square_park_events_&_activities"
    },
    {
        rid: "washington_square_park_events_&_activities",
        title: "Washington Square Park",
        subtitle: "Events & Activities",
        src: require("../../../audioAssets/english/washington_square_park_events_&_activities.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Public Park",
        landmark: "Washington Square Park",
        dateAdded: "2024-07-09",
        audioFile: "washington_square_park_events_&_activities.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Washington Square, New York, NY 10012",
        latLong: { lat: 40.730823, long: -73.997332 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flushing_meadows-corona_park_historical_overview"
    },
    {
        rid: "flushing_meadows-corona_park_historical_overview",
        title: "Flushing Meadows-Corona Park",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/flushing_meadows-corona_park_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flushing",
        landMarkCategory: "Public Park",
        landmark: "Flushing Meadows-Corona Park",
        dateAdded: "2024-07-09",
        audioFile: "flushing_meadows-corona_park_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Flushing Meadows-Corona Park, Queens, NY 11368",
        latLong: { lat: 40.7498, long: -73.8448 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flushing_meadows-corona_park_creation_of_the_park"
    },
    {
        rid: "flushing_meadows-corona_park_creation_of_the_park",
        title: "Flushing Meadows-Corona Park",
        subtitle: "Creation of the Park",
        src: require("../../../audioAssets/english/flushing_meadows-corona_park_creation_of_the_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flushing",
        landMarkCategory: "Public Park",
        landmark: "Flushing Meadows-Corona Park",
        dateAdded: "2024-07-09",
        audioFile: "flushing_meadows-corona_park_creation_of_the_park.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Flushing Meadows-Corona Park, Queens, NY 11368",
        latLong: { lat: 40.7498, long: -73.8448 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flushing_meadows-corona_park_1964_world's_fair"
    },
    {
        rid: "flushing_meadows-corona_park_1964_world's_fair",
        title: "Flushing Meadows-Corona Park",
        subtitle: "1964 World's Fair",
        src: require("../../../audioAssets/english/flushing_meadows-corona_park_1964_world's_fair.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flushing",
        landMarkCategory: "Public Park",
        landmark: "Flushing Meadows-Corona Park",
        dateAdded: "2024-07-09",
        audioFile: "flushing_meadows-corona_park_1964_world's_fair.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Flushing Meadows-Corona Park, Queens, NY 11368",
        latLong: { lat: 40.7498, long: -73.8448 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flushing_meadows-corona_park_present-day_attractions"
    },
    {
        rid: "flushing_meadows-corona_park_present-day_attractions",
        title: "Flushing Meadows-Corona Park",
        subtitle: "Present-Day Attractions",
        src: require("../../../audioAssets/english/flushing_meadows-corona_park_present-day_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flushing",
        landMarkCategory: "Public Park",
        landmark: "Flushing Meadows-Corona Park",
        dateAdded: "2024-07-09",
        audioFile: "flushing_meadows-corona_park_present-day_attractions.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Flushing Meadows-Corona Park, Queens, NY 11368",
        latLong: { lat: 40.7498, long: -73.8448 },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_introduction_to_riverside_park"
    },
    {
        rid: "riverside_park_introduction_to_riverside_park",
        title: "Riverside Park",
        subtitle: "Introduction to Riverside Park",
        src: require("../../../audioAssets/english/riverside_park_introduction_to_riverside_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-09",
        audioFile: "riverside_park_introduction_to_riverside_park.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: { lat: 40.8007, long: -73.9707 },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_history_and_development"
    },
    {
        rid: "riverside_park_history_and_development",
        title: "Riverside Park",
        subtitle: "History and Development",
        src: require("../../../audioAssets/english/riverside_park_history_and_development.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-09",
        audioFile: "riverside_park_history_and_development.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: { lat: 40.8007, long: -73.9707 },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_key_features_and_attractions"
    },
    {
        rid: "riverside_park_key_features_and_attractions",
        title: "Riverside Park",
        subtitle: "Key Features and Attractions",
        src: require("../../../audioAssets/english/riverside_park_key_features_and_attractions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-09",
        audioFile: "riverside_park_key_features_and_attractions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: { lat: 40.8007, long: -73.9707 },
        isPremium: false,
        premiumPackage: null,
        playNext: "riverside_park_ecological_importance_and_community_impact"
    },
    {
        rid: "riverside_park_ecological_importance_and_community_impact",
        title: "Riverside Park",
        subtitle: "Ecological Importance and Community Impact",
        src: require("../../../audioAssets/english/riverside_park_ecological_importance_and_community_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Public Park",
        landmark: "Riverside Park",
        dateAdded: "2024-07-09",
        audioFile: "riverside_park_ecological_importance_and_community_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Riverside Dr, New York, NY 10024, USA",
        latLong: { lat: 40.8007, long: -73.9707 },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_a_historical_overview"
    },
    {
        rid: "madison_square_park_a_historical_overview",
        title: "Madison Square Park",
        subtitle: "A Historical Overview",
        src: require("../../../audioAssets/english/madison_square_park_a_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-09",
        audioFile: "madison_square_park_a_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: { lat: 40.7425, long: -73.988 },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_transformation_and_redevelopment"
    },
    {
        rid: "madison_square_park_transformation_and_redevelopment",
        title: "Madison Square Park",
        subtitle: "Transformation and Redevelopment",
        src: require("../../../audioAssets/english/madison_square_park_transformation_and_redevelopment.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-09",
        audioFile: "madison_square_park_transformation_and_redevelopment.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: { lat: 40.7425, long: -73.988 },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_art_and_exhibitions"
    },
    {
        rid: "madison_square_park_art_and_exhibitions",
        title: "Madison Square Park",
        subtitle: "Art and Exhibitions",
        src: require("../../../audioAssets/english/madison_square_park_art_and_exhibitions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-09",
        audioFile: "madison_square_park_art_and_exhibitions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: { lat: 40.7425, long: -73.988 },
        isPremium: false,
        premiumPackage: null,
        playNext: "madison_square_park_flora,_fauna,_and_wildlife"
    },
    {
        rid: "madison_square_park_flora,_fauna,_and_wildlife",
        title: "Madison Square Park",
        subtitle: "Flora, Fauna, and Wildlife",
        src: require("../../../audioAssets/english/madison_square_park_flora,_fauna,_and_wildlife.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Public Park",
        landmark: "Madison Square Park",
        dateAdded: "2024-07-09",
        audioFile: "madison_square_park_flora,_fauna,_and_wildlife.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Madison Ave & E 23rd St, New York, NY 10010",
        latLong: { lat: 40.7425, long: -73.988 },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_historical_overview"
    },
    {
        rid: "union_square_park_historical_overview",
        title: "Union Square Park",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/union_square_park_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-09",
        audioFile: "union_square_park_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Union Square West and Union Square East, New York, NY 10003",
        latLong: { lat: 40.735863, long: -73.991083 },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_transformation_and_development"
    },
    {
        rid: "union_square_park_transformation_and_development",
        title: "Union Square Park",
        subtitle: "Transformation and Development",
        src: require("../../../audioAssets/english/union_square_park_transformation_and_development.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-09",
        audioFile: "union_square_park_transformation_and_development.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Union Square West and Union Square East, New York, NY 10003",
        latLong: { lat: 40.735863, long: -73.991083 },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_union_square_in_popular_culture"
    },
    {
        rid: "union_square_park_union_square_in_popular_culture",
        title: "Union Square Park",
        subtitle: "Union Square in Popular Culture",
        src: require("../../../audioAssets/english/union_square_park_union_square_in_popular_culture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-09",
        audioFile: "union_square_park_union_square_in_popular_culture.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Union Square West and Union Square East, New York, NY 10003",
        latLong: { lat: 40.735863, long: -73.991083 },
        isPremium: false,
        premiumPackage: null,
        playNext: "union_square_park_current-day_attractions_and_activities"
    },
    {
        rid: "union_square_park_current-day_attractions_and_activities",
        title: "Union Square Park",
        subtitle: "Current-Day Attractions and Activities",
        src: require("../../../audioAssets/english/union_square_park_current-day_attractions_and_activities.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Union Square",
        landMarkCategory: "Public Park",
        landmark: "Union Square Park",
        dateAdded: "2024-07-09",
        audioFile: "union_square_park_current-day_attractions_and_activities.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "14th Street to 17th Street, between Union Square West and Union Square East, New York, NY 10003",
        latLong: { lat: 40.735863, long: -73.991083 },
        isPremium: false,
        premiumPackage: null,
        playNext: "astoria_park_introduction_and_history"
    },
    {
        rid: "astoria_park_introduction_and_history",
        title: "Astoria Park",
        subtitle: "Introduction and History",
        src: require("../../../audioAssets/english/astoria_park_introduction_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Astoria",
        landMarkCategory: "Public Park",
        landmark: "Astoria Park",
        dateAdded: "2024-07-09",
        audioFile: "astoria_park_introduction_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "19 19th St, Queens, NY 11105, USA",
        latLong: { lat: 40.7794, long: -73.9286 },
        isPremium: false,
        premiumPackage: null,
        playNext: "astoria_park_the_architecture_and_landmarks"
    },
    {
        rid: "astoria_park_the_architecture_and_landmarks",
        title: "Astoria Park",
        subtitle: "The Architecture and Landmarks",
        src: require("../../../audioAssets/english/astoria_park_the_architecture_and_landmarks.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Astoria",
        landMarkCategory: "Public Park",
        landmark: "Astoria Park",
        dateAdded: "2024-07-09",
        audioFile: "astoria_park_the_architecture_and_landmarks.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "19 19th St, Queens, NY 11105, USA",
        latLong: { lat: 40.7794, long: -73.9286 },
        isPremium: false,
        premiumPackage: null,
        playNext: "astoria_park_recreational_activities_and_events"
    },
    {
        rid: "astoria_park_recreational_activities_and_events",
        title: "Astoria Park",
        subtitle: "Recreational Activities and Events",
        src: require("../../../audioAssets/english/astoria_park_recreational_activities_and_events.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Astoria",
        landMarkCategory: "Public Park",
        landmark: "Astoria Park",
        dateAdded: "2024-07-09",
        audioFile: "astoria_park_recreational_activities_and_events.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "19 19th St, Queens, NY 11105, USA",
        latLong: { lat: 40.7794, long: -73.9286 },
        isPremium: false,
        premiumPackage: null,
        playNext: "astoria_park_environmental_and_community_impact"
    },
    {
        rid: "astoria_park_environmental_and_community_impact",
        title: "Astoria Park",
        subtitle: "Environmental and Community Impact",
        src: require("../../../audioAssets/english/astoria_park_environmental_and_community_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Astoria",
        landMarkCategory: "Public Park",
        landmark: "Astoria Park",
        dateAdded: "2024-07-09",
        audioFile: "astoria_park_environmental_and_community_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "19 19th St, Queens, NY 11105, USA",
        latLong: { lat: 40.7794, long: -73.9286 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fort_greene_park_history_of_the_park"
    },
    {
        rid: "fort_greene_park_history_of_the_park",
        title: "Fort Greene Park",
        subtitle: "History of the Park",
        src: require("../../../audioAssets/english/fort_greene_park_history_of_the_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Public Park",
        landmark: "Fort Greene Park",
        dateAdded: "2024-07-09",
        audioFile: "fort_greene_park_history_of_the_park.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Washington Park St, Brooklyn, NY 11205",
        latLong: { lat: 40.6918, long: -73.9746 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fort_greene_park_the_prison_ship_martyrs'_monument"
    },
    {
        rid: "fort_greene_park_the_prison_ship_martyrs'_monument",
        title: "Fort Greene Park",
        subtitle: "The Prison Ship Martyrs' Monument",
        src: require("../../../audioAssets/english/fort_greene_park_the_prison_ship_martyrs'_monument.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Public Park",
        landmark: "Fort Greene Park",
        dateAdded: "2024-07-09",
        audioFile: "fort_greene_park_the_prison_ship_martyrs'_monument.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Washington Park St, Brooklyn, NY 11205",
        latLong: { lat: 40.6918, long: -73.9746 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fort_greene_park_frederick_law_olmsted_and_calvert_vaux's_vision"
    },
    {
        rid: "fort_greene_park_frederick_law_olmsted_and_calvert_vaux's_vision",
        title: "Fort Greene Park",
        subtitle: "Frederick Law Olmsted and Calvert Vaux's Vision",
        src: require("../../../audioAssets/english/fort_greene_park_frederick_law_olmsted_and_calvert_vaux's_vision.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Public Park",
        landmark: "Fort Greene Park",
        dateAdded: "2024-07-09",
        audioFile: "fort_greene_park_frederick_law_olmsted_and_calvert_vaux's_vision.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Washington Park St, Brooklyn, NY 11205",
        latLong: { lat: 40.6918, long: -73.9746 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fort_greene_park_modern-day_fort_greene_park"
    },
    {
        rid: "fort_greene_park_modern-day_fort_greene_park",
        title: "Fort Greene Park",
        subtitle: "Modern-Day Fort Greene Park",
        src: require("../../../audioAssets/english/fort_greene_park_modern-day_fort_greene_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Public Park",
        landmark: "Fort Greene Park",
        dateAdded: "2024-07-09",
        audioFile: "fort_greene_park_modern-day_fort_greene_park.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Washington Park St, Brooklyn, NY 11205",
        latLong: { lat: 40.6918, long: -73.9746 },
        isPremium: false,
        premiumPackage: null,
        playNext: "carnegie_hall_introduction_to_carnegie_hall"
    },
    {
        rid: "carnegie_hall_introduction_to_carnegie_hall",
        title: "Carnegie Hall",
        subtitle: "Introduction to Carnegie Hall",
        src: require("../../../audioAssets/english/carnegie_hall_introduction_to_carnegie_hall.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Performing Arts",
        landmark: "Carnegie Hall",
        dateAdded: "2024-07-09",
        audioFile: "carnegie_hall_introduction_to_carnegie_hall.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "881 Seventh Avenue, New York, NY 10019",
        latLong: { lat: 40.765139, long: -73.979895 },
        isPremium: false,
        premiumPackage: null,
        playNext: "carnegie_hall_construction_and_architecture"
    },
    {
        rid: "carnegie_hall_construction_and_architecture",
        title: "Carnegie Hall",
        subtitle: "Construction and Architecture",
        src: require("../../../audioAssets/english/carnegie_hall_construction_and_architecture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Performing Arts",
        landmark: "Carnegie Hall",
        dateAdded: "2024-07-09",
        audioFile: "carnegie_hall_construction_and_architecture.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "881 Seventh Avenue, New York, NY 10019",
        latLong: { lat: 40.765139, long: -73.979895 },
        isPremium: false,
        premiumPackage: null,
        playNext: "carnegie_hall_iconic_performances_and_artists"
    },
    {
        rid: "carnegie_hall_iconic_performances_and_artists",
        title: "Carnegie Hall",
        subtitle: "Iconic Performances and Artists",
        src: require("../../../audioAssets/english/carnegie_hall_iconic_performances_and_artists.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Performing Arts",
        landmark: "Carnegie Hall",
        dateAdded: "2024-07-09",
        audioFile: "carnegie_hall_iconic_performances_and_artists.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "881 Seventh Avenue, New York, NY 10019",
        latLong: { lat: 40.765139, long: -73.979895 },
        isPremium: false,
        premiumPackage: null,
        playNext: "carnegie_hall_carnegie_hall's_cultural_impact"
    },
    {
        rid: "carnegie_hall_carnegie_hall's_cultural_impact",
        title: "Carnegie Hall",
        subtitle: "Carnegie Hall's Cultural Impact",
        src: require("../../../audioAssets/english/carnegie_hall_carnegie_hall's_cultural_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Performing Arts",
        landmark: "Carnegie Hall",
        dateAdded: "2024-07-09",
        audioFile: "carnegie_hall_carnegie_hall's_cultural_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "881 Seventh Avenue, New York, NY 10019",
        latLong: { lat: 40.765139, long: -73.979895 },
        isPremium: false,
        premiumPackage: null,
        playNext: "radio_city_music_hall_overview_and_origins"
    },
    {
        rid: "radio_city_music_hall_overview_and_origins",
        title: "Radio City Music Hall",
        subtitle: "Overview and Origins",
        src: require("../../../audioAssets/english/radio_city_music_hall_overview_and_origins.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Radio City Music Hall",
        dateAdded: "2024-07-09",
        audioFile: "radio_city_music_hall_overview_and_origins.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1260 6th Avenue, New York, NY 10020",
        latLong: { lat: 40.759976, long: -73.980065 },
        isPremium: false,
        premiumPackage: null,
        playNext: "radio_city_music_hall_construction_and_design"
    },
    {
        rid: "radio_city_music_hall_construction_and_design",
        title: "Radio City Music Hall",
        subtitle: "Construction and Design",
        src: require("../../../audioAssets/english/radio_city_music_hall_construction_and_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Radio City Music Hall",
        dateAdded: "2024-07-09",
        audioFile: "radio_city_music_hall_construction_and_design.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1260 6th Avenue, New York, NY 10020",
        latLong: { lat: 40.759976, long: -73.980065 },
        isPremium: false,
        premiumPackage: null,
        playNext: "radio_city_music_hall_the_rockettes_and_performances"
    },
    {
        rid: "radio_city_music_hall_the_rockettes_and_performances",
        title: "Radio City Music Hall",
        subtitle: "The Rockettes and Performances",
        src: require("../../../audioAssets/english/radio_city_music_hall_the_rockettes_and_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Radio City Music Hall",
        dateAdded: "2024-07-09",
        audioFile: "radio_city_music_hall_the_rockettes_and_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1260 6th Avenue, New York, NY 10020",
        latLong: { lat: 40.759976, long: -73.980065 },
        isPremium: false,
        premiumPackage: null,
        playNext: "radio_city_music_hall_cultural_impact_and_legacy"
    },
    {
        rid: "radio_city_music_hall_cultural_impact_and_legacy",
        title: "Radio City Music Hall",
        subtitle: "Cultural Impact and Legacy",
        src: require("../../../audioAssets/english/radio_city_music_hall_cultural_impact_and_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Radio City Music Hall",
        dateAdded: "2024-07-09",
        audioFile: "radio_city_music_hall_cultural_impact_and_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1260 6th Avenue, New York, NY 10020",
        latLong: { lat: 40.759976, long: -73.980065 },
        isPremium: false,
        premiumPackage: null,
        playNext: "beacon_theatre_introduction_and_historical_overview"
    },
    {
        rid: "beacon_theatre_introduction_and_historical_overview",
        title: "Beacon Theatre",
        subtitle: "Introduction and Historical Overview",
        src: require("../../../audioAssets/english/beacon_theatre_introduction_and_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Theater",
        landmark: "Beacon Theatre",
        dateAdded: "2024-07-09",
        audioFile: "beacon_theatre_introduction_and_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "2124 Broadway, New York, NY 10023",
        latLong: { lat: 40.780509, long: -73.98137 },
        isPremium: false,
        premiumPackage: null,
        playNext: "beacon_theatre_architectural_splendor"
    },
    {
        rid: "beacon_theatre_architectural_splendor",
        title: "Beacon Theatre",
        subtitle: "Architectural Splendor",
        src: require("../../../audioAssets/english/beacon_theatre_architectural_splendor.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Theater",
        landmark: "Beacon Theatre",
        dateAdded: "2024-07-09",
        audioFile: "beacon_theatre_architectural_splendor.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "2124 Broadway, New York, NY 10023",
        latLong: { lat: 40.780509, long: -73.98137 },
        isPremium: false,
        premiumPackage: null,
        playNext: "beacon_theatre_iconic_performances"
    },
    {
        rid: "beacon_theatre_iconic_performances",
        title: "Beacon Theatre",
        subtitle: "Iconic Performances",
        src: require("../../../audioAssets/english/beacon_theatre_iconic_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Theater",
        landmark: "Beacon Theatre",
        dateAdded: "2024-07-09",
        audioFile: "beacon_theatre_iconic_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "2124 Broadway, New York, NY 10023",
        latLong: { lat: 40.780509, long: -73.98137 },
        isPremium: false,
        premiumPackage: null,
        playNext: "beacon_theatre_beacon_theatre_in_modern_times"
    },
    {
        rid: "beacon_theatre_beacon_theatre_in_modern_times",
        title: "Beacon Theatre",
        subtitle: "Beacon Theatre in Modern Times",
        src: require("../../../audioAssets/english/beacon_theatre_beacon_theatre_in_modern_times.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Theater",
        landmark: "Beacon Theatre",
        dateAdded: "2024-07-09",
        audioFile: "beacon_theatre_beacon_theatre_in_modern_times.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "2124 Broadway, New York, NY 10023",
        latLong: { lat: 40.780509, long: -73.98137 },
        isPremium: false,
        premiumPackage: null,
        playNext: "apollo_theater_overview"
    },
    {
        rid: "apollo_theater_overview",
        title: "Apollo Theater",
        subtitle: "Overview",
        src: require("../../../audioAssets/english/apollo_theater_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Theater",
        landmark: "Apollo Theater",
        dateAdded: "2024-07-09",
        audioFile: "apollo_theater_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "253 W 125th St, New York, NY 10027",
        latLong: { lat: 40.810523, long: -73.949169 },
        isPremium: false,
        premiumPackage: null,
        playNext: "apollo_theater_origins_and_early_history"
    },
    {
        rid: "apollo_theater_origins_and_early_history",
        title: "Apollo Theater",
        subtitle: "Origins and Early History",
        src: require("../../../audioAssets/english/apollo_theater_origins_and_early_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Theater",
        landmark: "Apollo Theater",
        dateAdded: "2024-07-09",
        audioFile: "apollo_theater_origins_and_early_history.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "253 W 125th St, New York, NY 10027",
        latLong: { lat: 40.810523, long: -73.949169 },
        isPremium: false,
        premiumPackage: null,
        playNext: "apollo_theater_the_harlem_renaissance_and_beyond"
    },
    {
        rid: "apollo_theater_the_harlem_renaissance_and_beyond",
        title: "Apollo Theater",
        subtitle: "The Harlem Renaissance and Beyond",
        src: require("../../../audioAssets/english/apollo_theater_the_harlem_renaissance_and_beyond.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Theater",
        landmark: "Apollo Theater",
        dateAdded: "2024-07-09",
        audioFile: "apollo_theater_the_harlem_renaissance_and_beyond.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "253 W 125th St, New York, NY 10027",
        latLong: { lat: 40.810523, long: -73.949169 },
        isPremium: false,
        premiumPackage: null,
        playNext: "apollo_theater_modern_impact_and_legacy"
    },
    {
        rid: "apollo_theater_modern_impact_and_legacy",
        title: "Apollo Theater",
        subtitle: "Modern Impact and Legacy",
        src: require("../../../audioAssets/english/apollo_theater_modern_impact_and_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Theater",
        landmark: "Apollo Theater",
        dateAdded: "2024-07-09",
        audioFile: "apollo_theater_modern_impact_and_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "253 W 125th St, New York, NY 10027",
        latLong: { lat: 40.810523, long: -73.949169 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_academy_of_music_a_historic_overview"
    },
    {
        rid: "brooklyn_academy_of_music_a_historic_overview",
        title: "Brooklyn Academy of Music",
        subtitle: "A Historic Overview",
        src: require("../../../audioAssets/english/brooklyn_academy_of_music_a_historic_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Academy of Music",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_academy_of_music_a_historic_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "30 Lafayette Ave, Brooklyn, NY 11217",
        latLong: { lat: 40.6862, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_academy_of_music_architectural_marvels"
    },
    {
        rid: "brooklyn_academy_of_music_architectural_marvels",
        title: "Brooklyn Academy of Music",
        subtitle: "Architectural Marvels",
        src: require("../../../audioAssets/english/brooklyn_academy_of_music_architectural_marvels.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Academy of Music",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_academy_of_music_architectural_marvels.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "30 Lafayette Ave, Brooklyn, NY 11217",
        latLong: { lat: 40.6862, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_academy_of_music_star-studded_performances"
    },
    {
        rid: "brooklyn_academy_of_music_star-studded_performances",
        title: "Brooklyn Academy of Music",
        subtitle: "Star-Studded Performances",
        src: require("../../../audioAssets/english/brooklyn_academy_of_music_star-studded_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Academy of Music",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_academy_of_music_star-studded_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "30 Lafayette Ave, Brooklyn, NY 11217",
        latLong: { lat: 40.6862, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_academy_of_music_community_and_cultural_impact"
    },
    {
        rid: "brooklyn_academy_of_music_community_and_cultural_impact",
        title: "Brooklyn Academy of Music",
        subtitle: "Community and Cultural Impact",
        src: require("../../../audioAssets/english/brooklyn_academy_of_music_community_and_cultural_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Fort Greene",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Academy of Music",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_academy_of_music_community_and_cultural_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "30 Lafayette Ave, Brooklyn, NY 11217",
        latLong: { lat: 40.6862, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "metropolitan_opera_house_a_grand_introduction"
    },
    {
        rid: "metropolitan_opera_house_a_grand_introduction",
        title: "Metropolitan Opera House",
        subtitle: "A Grand Introduction",
        src: require("../../../audioAssets/english/metropolitan_opera_house_a_grand_introduction.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Metropolitan Opera House",
        dateAdded: "2024-07-09",
        audioFile: "metropolitan_opera_house_a_grand_introduction.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "30 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: { lat: 40.7727, long: -73.9845 },
        isPremium: false,
        premiumPackage: null,
        playNext: "metropolitan_opera_house_architectural_marvel"
    },
    {
        rid: "metropolitan_opera_house_architectural_marvel",
        title: "Metropolitan Opera House",
        subtitle: "Architectural Marvel",
        src: require("../../../audioAssets/english/metropolitan_opera_house_architectural_marvel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Metropolitan Opera House",
        dateAdded: "2024-07-09",
        audioFile: "metropolitan_opera_house_architectural_marvel.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "30 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: { lat: 40.7727, long: -73.9845 },
        isPremium: false,
        premiumPackage: null,
        playNext: "metropolitan_opera_house_red-carpet_moments"
    },
    {
        rid: "metropolitan_opera_house_red-carpet_moments",
        title: "Metropolitan Opera House",
        subtitle: "Red-Carpet Moments",
        src: require("../../../audioAssets/english/metropolitan_opera_house_red-carpet_moments.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Metropolitan Opera House",
        dateAdded: "2024-07-09",
        audioFile: "metropolitan_opera_house_red-carpet_moments.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "30 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: { lat: 40.7727, long: -73.9845 },
        isPremium: false,
        premiumPackage: null,
        playNext: "metropolitan_opera_house_the_heart_of_performing_arts"
    },
    {
        rid: "metropolitan_opera_house_the_heart_of_performing_arts",
        title: "Metropolitan Opera House",
        subtitle: "The Heart of Performing Arts",
        src: require("../../../audioAssets/english/metropolitan_opera_house_the_heart_of_performing_arts.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Metropolitan Opera House",
        dateAdded: "2024-07-09",
        audioFile: "metropolitan_opera_house_the_heart_of_performing_arts.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "30 Lincoln Center Plaza, New York, NY 10023, USA",
        latLong: { lat: 40.7727, long: -73.9845 },
        isPremium: false,
        premiumPackage: null,
        playNext: "david_geffen_hall_introduction_and_history"
    },
    {
        rid: "david_geffen_hall_introduction_and_history",
        title: "David Geffen Hall",
        subtitle: "Introduction and History",
        src: require("../../../audioAssets/english/david_geffen_hall_introduction_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "David Geffen Hall",
        dateAdded: "2024-07-09",
        audioFile: "david_geffen_hall_introduction_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "10 Lincoln Center Plaza, New York, NY 10023",
        latLong: { lat: 40.7725, long: -73.9851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "david_geffen_hall_architectural_design_and_renovations"
    },
    {
        rid: "david_geffen_hall_architectural_design_and_renovations",
        title: "David Geffen Hall",
        subtitle: "Architectural Design and Renovations",
        src: require("../../../audioAssets/english/david_geffen_hall_architectural_design_and_renovations.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "David Geffen Hall",
        dateAdded: "2024-07-09",
        audioFile: "david_geffen_hall_architectural_design_and_renovations.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "10 Lincoln Center Plaza, New York, NY 10023",
        latLong: { lat: 40.7725, long: -73.9851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "david_geffen_hall_memorable_performances_and_events"
    },
    {
        rid: "david_geffen_hall_memorable_performances_and_events",
        title: "David Geffen Hall",
        subtitle: "Memorable Performances and Events",
        src: require("../../../audioAssets/english/david_geffen_hall_memorable_performances_and_events.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "David Geffen Hall",
        dateAdded: "2024-07-09",
        audioFile: "david_geffen_hall_memorable_performances_and_events.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "10 Lincoln Center Plaza, New York, NY 10023",
        latLong: { lat: 40.7725, long: -73.9851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "david_geffen_hall_role_in_the_community_and_future_plans"
    },
    {
        rid: "david_geffen_hall_role_in_the_community_and_future_plans",
        title: "David Geffen Hall",
        subtitle: "Role in the Community and Future Plans",
        src: require("../../../audioAssets/english/david_geffen_hall_role_in_the_community_and_future_plans.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "David Geffen Hall",
        dateAdded: "2024-07-09",
        audioFile: "david_geffen_hall_role_in_the_community_and_future_plans.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "10 Lincoln Center Plaza, New York, NY 10023",
        latLong: { lat: 40.7725, long: -73.9851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "alice_tully_hall_a_modern_architectural_marvel"
    },
    {
        rid: "alice_tully_hall_a_modern_architectural_marvel",
        title: "Alice Tully Hall",
        subtitle: "A Modern Architectural Marvel",
        src: require("../../../audioAssets/english/alice_tully_hall_a_modern_architectural_marvel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Alice Tully Hall",
        dateAdded: "2024-07-09",
        audioFile: "alice_tully_hall_a_modern_architectural_marvel.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1941 Broadway, New York, NY 10023",
        latLong: { lat: 40.774423, long: -73.982357 },
        isPremium: false,
        premiumPackage: null,
        playNext: "alice_tully_hall_the_visionary_behind_the_hall:_alice_tully"
    },
    {
        rid: "alice_tully_hall_the_visionary_behind_the_hall:_alice_tully",
        title: "Alice Tully Hall",
        subtitle: "The Visionary Behind the Hall: Alice Tully",
        src: require("../../../audioAssets/english/alice_tully_hall_the_visionary_behind_the_hall:_alice_tully.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Alice Tully Hall",
        dateAdded: "2024-07-09",
        audioFile: "alice_tully_hall_the_visionary_behind_the_hall:_alice_tully.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1941 Broadway, New York, NY 10023",
        latLong: { lat: 40.774423, long: -73.982357 },
        isPremium: false,
        premiumPackage: null,
        playNext: "alice_tully_hall_design_and_construction"
    },
    {
        rid: "alice_tully_hall_design_and_construction",
        title: "Alice Tully Hall",
        subtitle: "Design and Construction",
        src: require("../../../audioAssets/english/alice_tully_hall_design_and_construction.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Alice Tully Hall",
        dateAdded: "2024-07-09",
        audioFile: "alice_tully_hall_design_and_construction.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1941 Broadway, New York, NY 10023",
        latLong: { lat: 40.774423, long: -73.982357 },
        isPremium: false,
        premiumPackage: null,
        playNext: "alice_tully_hall_a_hub_for_performing_arts"
    },
    {
        rid: "alice_tully_hall_a_hub_for_performing_arts",
        title: "Alice Tully Hall",
        subtitle: "A Hub for Performing Arts",
        src: require("../../../audioAssets/english/alice_tully_hall_a_hub_for_performing_arts.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Performing Arts",
        landmark: "Alice Tully Hall",
        dateAdded: "2024-07-09",
        audioFile: "alice_tully_hall_a_hub_for_performing_arts.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1941 Broadway, New York, NY 10023",
        latLong: { lat: 40.774423, long: -73.982357 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_joyce_theater_introduction_and_history"
    },
    {
        rid: "the_joyce_theater_introduction_and_history",
        title: "The Joyce Theater",
        subtitle: "Introduction and History",
        src: require("../../../audioAssets/english/the_joyce_theater_introduction_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Theater",
        landmark: "The Joyce Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_joyce_theater_introduction_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "175 8th Avenue, New York, NY 10011",
        latLong: { lat: 40.7423, long: -74.0001 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_joyce_theater_architectural_design_and_renovation"
    },
    {
        rid: "the_joyce_theater_architectural_design_and_renovation",
        title: "The Joyce Theater",
        subtitle: "Architectural Design and Renovation",
        src: require("../../../audioAssets/english/the_joyce_theater_architectural_design_and_renovation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Theater",
        landmark: "The Joyce Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_joyce_theater_architectural_design_and_renovation.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "175 8th Avenue, New York, NY 10011",
        latLong: { lat: 40.7423, long: -74.0001 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_joyce_theater_impact_on_the_dance_community"
    },
    {
        rid: "the_joyce_theater_impact_on_the_dance_community",
        title: "The Joyce Theater",
        subtitle: "Impact on the Dance Community",
        src: require("../../../audioAssets/english/the_joyce_theater_impact_on_the_dance_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Theater",
        landmark: "The Joyce Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_joyce_theater_impact_on_the_dance_community.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "175 8th Avenue, New York, NY 10011",
        latLong: { lat: 40.7423, long: -74.0001 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_joyce_theater_notable_performances_and_events"
    },
    {
        rid: "the_joyce_theater_notable_performances_and_events",
        title: "The Joyce Theater",
        subtitle: "Notable Performances and Events",
        src: require("../../../audioAssets/english/the_joyce_theater_notable_performances_and_events.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Theater",
        landmark: "The Joyce Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_joyce_theater_notable_performances_and_events.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "175 8th Avenue, New York, NY 10011",
        latLong: { lat: 40.7423, long: -74.0001 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_steel_introduction_and_history"
    },
    {
        rid: "brooklyn_steel_introduction_and_history",
        title: "Brooklyn Steel",
        subtitle: "Introduction and History",
        src: require("../../../audioAssets/english/brooklyn_steel_introduction_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Williamsburg",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Steel",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_steel_introduction_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "319 Frost St, Brooklyn, NY 11222",
        latLong: { lat: 40.717456, long: -73.937795 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_steel_transformative_renovations"
    },
    {
        rid: "brooklyn_steel_transformative_renovations",
        title: "Brooklyn Steel",
        subtitle: "Transformative Renovations",
        src: require("../../../audioAssets/english/brooklyn_steel_transformative_renovations.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Williamsburg",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Steel",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_steel_transformative_renovations.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "319 Frost St, Brooklyn, NY 11222",
        latLong: { lat: 40.717456, long: -73.937795 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_steel_iconic_concerts_and_events"
    },
    {
        rid: "brooklyn_steel_iconic_concerts_and_events",
        title: "Brooklyn Steel",
        subtitle: "Iconic Concerts and Events",
        src: require("../../../audioAssets/english/brooklyn_steel_iconic_concerts_and_events.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Williamsburg",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Steel",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_steel_iconic_concerts_and_events.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "319 Frost St, Brooklyn, NY 11222",
        latLong: { lat: 40.717456, long: -73.937795 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_steel_impact_on_the_north_brooklyn_music_scene"
    },
    {
        rid: "brooklyn_steel_impact_on_the_north_brooklyn_music_scene",
        title: "Brooklyn Steel",
        subtitle: "Impact on the North Brooklyn Music Scene",
        src: require("../../../audioAssets/english/brooklyn_steel_impact_on_the_north_brooklyn_music_scene.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Williamsburg",
        landMarkCategory: "Performing Arts",
        landmark: "Brooklyn Steel",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_steel_impact_on_the_north_brooklyn_music_scene.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "319 Frost St, Brooklyn, NY 11222",
        latLong: { lat: 40.717456, long: -73.937795 },
        isPremium: false,
        premiumPackage: null,
        playNext: "le_poisson_rouge_history_and_founding"
    },
    {
        rid: "le_poisson_rouge_history_and_founding",
        title: "Le Poisson Rouge",
        subtitle: "History and Founding",
        src: require("../../../audioAssets/english/le_poisson_rouge_history_and_founding.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Performing Arts",
        landmark: "Le Poisson Rouge",
        dateAdded: "2024-07-09",
        audioFile: "le_poisson_rouge_history_and_founding.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "158 Bleecker St, New York, NY 10012",
        latLong: { lat: 40.728, long: -74.0007 },
        isPremium: false,
        premiumPackage: null,
        playNext: "le_poisson_rouge_architectural_evolution"
    },
    {
        rid: "le_poisson_rouge_architectural_evolution",
        title: "Le Poisson Rouge",
        subtitle: "Architectural Evolution",
        src: require("../../../audioAssets/english/le_poisson_rouge_architectural_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Performing Arts",
        landmark: "Le Poisson Rouge",
        dateAdded: "2024-07-09",
        audioFile: "le_poisson_rouge_architectural_evolution.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "158 Bleecker St, New York, NY 10012",
        latLong: { lat: 40.728, long: -74.0007 },
        isPremium: false,
        premiumPackage: null,
        playNext: "le_poisson_rouge_iconic_performances"
    },
    {
        rid: "le_poisson_rouge_iconic_performances",
        title: "Le Poisson Rouge",
        subtitle: "Iconic Performances",
        src: require("../../../audioAssets/english/le_poisson_rouge_iconic_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Performing Arts",
        landmark: "Le Poisson Rouge",
        dateAdded: "2024-07-09",
        audioFile: "le_poisson_rouge_iconic_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "158 Bleecker St, New York, NY 10012",
        latLong: { lat: 40.728, long: -74.0007 },
        isPremium: false,
        premiumPackage: null,
        playNext: "le_poisson_rouge_cultural_impact_in_today's_nyc"
    },
    {
        rid: "le_poisson_rouge_cultural_impact_in_today's_nyc",
        title: "Le Poisson Rouge",
        subtitle: "Cultural Impact in Today's NYC",
        src: require("../../../audioAssets/english/le_poisson_rouge_cultural_impact_in_today's_nyc.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Performing Arts",
        landmark: "Le Poisson Rouge",
        dateAdded: "2024-07-09",
        audioFile: "le_poisson_rouge_cultural_impact_in_today's_nyc.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "158 Bleecker St, New York, NY 10012",
        latLong: { lat: 40.728, long: -74.0007 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fordham_university_historical_overview"
    },
    {
        rid: "fordham_university_historical_overview",
        title: "Fordham University",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/fordham_university_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Bronx",
        landMarkCategory: "Universities",
        landmark: "Fordham University",
        dateAdded: "2024-07-09",
        audioFile: "fordham_university_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "441 East Fordham Road, Bronx, NY 10458",
        latLong: { lat: 40.862, long: -73.8851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fordham_university_architectural_evolution"
    },
    {
        rid: "fordham_university_architectural_evolution",
        title: "Fordham University",
        subtitle: "Architectural Evolution",
        src: require("../../../audioAssets/english/fordham_university_architectural_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Bronx",
        landMarkCategory: "Universities",
        landmark: "Fordham University",
        dateAdded: "2024-07-09",
        audioFile: "fordham_university_architectural_evolution.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "441 East Fordham Road, Bronx, NY 10458",
        latLong: { lat: 40.862, long: -73.8851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fordham_university_campus_life_through_the_decades"
    },
    {
        rid: "fordham_university_campus_life_through_the_decades",
        title: "Fordham University",
        subtitle: "Campus Life Through the Decades",
        src: require("../../../audioAssets/english/fordham_university_campus_life_through_the_decades.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Bronx",
        landMarkCategory: "Universities",
        landmark: "Fordham University",
        dateAdded: "2024-07-09",
        audioFile: "fordham_university_campus_life_through_the_decades.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "441 East Fordham Road, Bronx, NY 10458",
        latLong: { lat: 40.862, long: -73.8851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "fordham_university_notable_alumni_and_achievements"
    },
    {
        rid: "fordham_university_notable_alumni_and_achievements",
        title: "Fordham University",
        subtitle: "Notable Alumni and Achievements",
        src: require("../../../audioAssets/english/fordham_university_notable_alumni_and_achievements.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Bronx",
        landMarkCategory: "Universities",
        landmark: "Fordham University",
        dateAdded: "2024-07-09",
        audioFile: "fordham_university_notable_alumni_and_achievements.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "441 East Fordham Road, Bronx, NY 10458",
        latLong: { lat: 40.862, long: -73.8851 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pace_university_historical_background"
    },
    {
        rid: "pace_university_historical_background",
        title: "Pace University",
        subtitle: "Historical Background",
        src: require("../../../audioAssets/english/pace_university_historical_background.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Universities",
        landmark: "Pace University",
        dateAdded: "2024-07-09",
        audioFile: "pace_university_historical_background.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1 Pace Plaza, New York, NY 10038",
        latLong: { lat: 40.7111, long: -74.0056 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pace_university_architectural_highlights"
    },
    {
        rid: "pace_university_architectural_highlights",
        title: "Pace University",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/pace_university_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Universities",
        landmark: "Pace University",
        dateAdded: "2024-07-09",
        audioFile: "pace_university_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1 Pace Plaza, New York, NY 10038",
        latLong: { lat: 40.7111, long: -74.0056 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pace_university_student_life_and_campus_culture"
    },
    {
        rid: "pace_university_student_life_and_campus_culture",
        title: "Pace University",
        subtitle: "Student Life and Campus Culture",
        src: require("../../../audioAssets/english/pace_university_student_life_and_campus_culture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Universities",
        landmark: "Pace University",
        dateAdded: "2024-07-09",
        audioFile: "pace_university_student_life_and_campus_culture.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1 Pace Plaza, New York, NY 10038",
        latLong: { lat: 40.7111, long: -74.0056 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pace_university_impact_on_the_financial_district"
    },
    {
        rid: "pace_university_impact_on_the_financial_district",
        title: "Pace University",
        subtitle: "Impact on the Financial District",
        src: require("../../../audioAssets/english/pace_university_impact_on_the_financial_district.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Universities",
        landmark: "Pace University",
        dateAdded: "2024-07-09",
        audioFile: "pace_university_impact_on_the_financial_district.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1 Pace Plaza, New York, NY 10038",
        latLong: { lat: 40.7111, long: -74.0056 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pratt_institute_history_and_founding"
    },
    {
        rid: "pratt_institute_history_and_founding",
        title: "Pratt Institute",
        subtitle: "History and Founding",
        src: require("../../../audioAssets/english/pratt_institute_history_and_founding.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Clinton Hill",
        landMarkCategory: "Universities",
        landmark: "Pratt Institute",
        dateAdded: "2024-07-09",
        audioFile: "pratt_institute_history_and_founding.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "200 Willoughby Ave, Brooklyn, NY 11205",
        latLong: { lat: 40.691233, long: -73.963672 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pratt_institute_architectural_highlights"
    },
    {
        rid: "pratt_institute_architectural_highlights",
        title: "Pratt Institute",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/pratt_institute_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Clinton Hill",
        landMarkCategory: "Universities",
        landmark: "Pratt Institute",
        dateAdded: "2024-07-09",
        audioFile: "pratt_institute_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "200 Willoughby Ave, Brooklyn, NY 11205",
        latLong: { lat: 40.691233, long: -73.963672 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pratt_institute_student_life_and_community"
    },
    {
        rid: "pratt_institute_student_life_and_community",
        title: "Pratt Institute",
        subtitle: "Student Life and Community",
        src: require("../../../audioAssets/english/pratt_institute_student_life_and_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Clinton Hill",
        landMarkCategory: "Universities",
        landmark: "Pratt Institute",
        dateAdded: "2024-07-09",
        audioFile: "pratt_institute_student_life_and_community.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "200 Willoughby Ave, Brooklyn, NY 11205",
        latLong: { lat: 40.691233, long: -73.963672 },
        isPremium: false,
        premiumPackage: null,
        playNext: "pratt_institute_impact_on_art_and_design"
    },
    {
        rid: "pratt_institute_impact_on_art_and_design",
        title: "Pratt Institute",
        subtitle: "Impact on Art and Design",
        src: require("../../../audioAssets/english/pratt_institute_impact_on_art_and_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Clinton Hill",
        landMarkCategory: "Universities",
        landmark: "Pratt Institute",
        dateAdded: "2024-07-09",
        audioFile: "pratt_institute_impact_on_art_and_design.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "200 Willoughby Ave, Brooklyn, NY 11205",
        latLong: { lat: 40.691233, long: -73.963672 },
        isPremium: false,
        premiumPackage: null,
        playNext: "baruch_college_overview"
    },
    {
        rid: "baruch_college_overview",
        title: "Baruch College",
        subtitle: "Overview",
        src: require("../../../audioAssets/english/baruch_college_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Universities",
        landmark: "Baruch College",
        dateAdded: "2024-07-09",
        audioFile: "baruch_college_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "55 Lexington Ave, New York, NY 10010",
        latLong: { lat: 40.7404, long: -73.9848 },
        isPremium: false,
        premiumPackage: null,
        playNext: "baruch_college_historical_beginnings"
    },
    {
        rid: "baruch_college_historical_beginnings",
        title: "Baruch College",
        subtitle: "Historical Beginnings",
        src: require("../../../audioAssets/english/baruch_college_historical_beginnings.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Universities",
        landmark: "Baruch College",
        dateAdded: "2024-07-09",
        audioFile: "baruch_college_historical_beginnings.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "55 Lexington Ave, New York, NY 10010",
        latLong: { lat: 40.7404, long: -73.9848 },
        isPremium: false,
        premiumPackage: null,
        playNext: "baruch_college_architectural_features"
    },
    {
        rid: "baruch_college_architectural_features",
        title: "Baruch College",
        subtitle: "Architectural Features",
        src: require("../../../audioAssets/english/baruch_college_architectural_features.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Universities",
        landmark: "Baruch College",
        dateAdded: "2024-07-09",
        audioFile: "baruch_college_architectural_features.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "55 Lexington Ave, New York, NY 10010",
        latLong: { lat: 40.7404, long: -73.9848 },
        isPremium: false,
        premiumPackage: null,
        playNext: "baruch_college_impact_and_achievements"
    },
    {
        rid: "baruch_college_impact_and_achievements",
        title: "Baruch College",
        subtitle: "Impact and Achievements",
        src: require("../../../audioAssets/english/baruch_college_impact_and_achievements.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Universities",
        landmark: "Baruch College",
        dateAdded: "2024-07-09",
        audioFile: "baruch_college_impact_and_achievements.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "55 Lexington Ave, New York, NY 10010",
        latLong: { lat: 40.7404, long: -73.9848 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hunter_college_overview_and_history"
    },
    {
        rid: "hunter_college_overview_and_history",
        title: "Hunter College",
        subtitle: "Overview and History",
        src: require("../../../audioAssets/english/hunter_college_overview_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Hunter College",
        dateAdded: "2024-07-09",
        audioFile: "hunter_college_overview_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "695 Park Ave, New York, NY 10065",
        latLong: { lat: 40.7685, long: -73.9648 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hunter_college_architectural_highlights"
    },
    {
        rid: "hunter_college_architectural_highlights",
        title: "Hunter College",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/hunter_college_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Hunter College",
        dateAdded: "2024-07-09",
        audioFile: "hunter_college_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "695 Park Ave, New York, NY 10065",
        latLong: { lat: 40.7685, long: -73.9648 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hunter_college_famous_alumni_and_faculty"
    },
    {
        rid: "hunter_college_famous_alumni_and_faculty",
        title: "Hunter College",
        subtitle: "Famous Alumni and Faculty",
        src: require("../../../audioAssets/english/hunter_college_famous_alumni_and_faculty.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Hunter College",
        dateAdded: "2024-07-09",
        audioFile: "hunter_college_famous_alumni_and_faculty.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "695 Park Ave, New York, NY 10065",
        latLong: { lat: 40.7685, long: -73.9648 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hunter_college_hunter's_role_in_the_community"
    },
    {
        rid: "hunter_college_hunter's_role_in_the_community",
        title: "Hunter College",
        subtitle: "Hunter's Role in the Community",
        src: require("../../../audioAssets/english/hunter_college_hunter's_role_in_the_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Hunter College",
        dateAdded: "2024-07-09",
        audioFile: "hunter_college_hunter's_role_in_the_community.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "695 Park Ave, New York, NY 10065",
        latLong: { lat: 40.7685, long: -73.9648 },
        isPremium: false,
        premiumPackage: null,
        playNext: "barnard_college_introduction_and_founding"
    },
    {
        rid: "barnard_college_introduction_and_founding",
        title: "Barnard College",
        subtitle: "Introduction and Founding",
        src: require("../../../audioAssets/english/barnard_college_introduction_and_founding.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Barnard College",
        dateAdded: "2024-07-09",
        audioFile: "barnard_college_introduction_and_founding.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "3009 Broadway, New York, NY 10027, USA",
        latLong: { lat: 40.810698, long: -73.961481 },
        isPremium: false,
        premiumPackage: null,
        playNext: "barnard_college_architectural_highlights"
    },
    {
        rid: "barnard_college_architectural_highlights",
        title: "Barnard College",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/barnard_college_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Barnard College",
        dateAdded: "2024-07-09",
        audioFile: "barnard_college_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "3009 Broadway, New York, NY 10027, USA",
        latLong: { lat: 40.810698, long: -73.961481 },
        isPremium: false,
        premiumPackage: null,
        playNext: "barnard_college_notable_alumni"
    },
    {
        rid: "barnard_college_notable_alumni",
        title: "Barnard College",
        subtitle: "Notable Alumni",
        src: require("../../../audioAssets/english/barnard_college_notable_alumni.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Barnard College",
        dateAdded: "2024-07-09",
        audioFile: "barnard_college_notable_alumni.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "3009 Broadway, New York, NY 10027, USA",
        latLong: { lat: 40.810698, long: -73.961481 },
        isPremium: false,
        premiumPackage: null,
        playNext: "barnard_college_role_in_women's_education"
    },
    {
        rid: "barnard_college_role_in_women's_education",
        title: "Barnard College",
        subtitle: "Role in Women's Education",
        src: require("../../../audioAssets/english/barnard_college_role_in_women's_education.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Morningside Heights",
        landMarkCategory: "Universities",
        landmark: "Barnard College",
        dateAdded: "2024-07-09",
        audioFile: "barnard_college_role_in_women's_education.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "3009 Broadway, New York, NY 10027, USA",
        latLong: { lat: 40.810698, long: -73.961481 },
        isPremium: false,
        premiumPackage: null,
        playNext: "school_of_visual_arts_history_and_founding"
    },
    {
        rid: "school_of_visual_arts_history_and_founding",
        title: "School of Visual Arts",
        subtitle: "History and Founding",
        src: require("../../../audioAssets/english/school_of_visual_arts_history_and_founding.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Gramercy",
        landMarkCategory: "Universities",
        landmark: "School of Visual Arts",
        dateAdded: "2024-07-09",
        audioFile: "school_of_visual_arts_history_and_founding.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "209 East 23rd Street, New York, NY 10010",
        latLong: { lat: 40.7381, long: -73.9847 },
        isPremium: false,
        premiumPackage: null,
        playNext: "school_of_visual_arts_campus_and_facilities"
    },
    {
        rid: "school_of_visual_arts_campus_and_facilities",
        title: "School of Visual Arts",
        subtitle: "Campus and Facilities",
        src: require("../../../audioAssets/english/school_of_visual_arts_campus_and_facilities.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Gramercy",
        landMarkCategory: "Universities",
        landmark: "School of Visual Arts",
        dateAdded: "2024-07-09",
        audioFile: "school_of_visual_arts_campus_and_facilities.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "209 East 23rd Street, New York, NY 10010",
        latLong: { lat: 40.7381, long: -73.9847 },
        isPremium: false,
        premiumPackage: null,
        playNext: "school_of_visual_arts_notable_alumni_and_faculty"
    },
    {
        rid: "school_of_visual_arts_notable_alumni_and_faculty",
        title: "School of Visual Arts",
        subtitle: "Notable Alumni and Faculty",
        src: require("../../../audioAssets/english/school_of_visual_arts_notable_alumni_and_faculty.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Gramercy",
        landMarkCategory: "Universities",
        landmark: "School of Visual Arts",
        dateAdded: "2024-07-09",
        audioFile: "school_of_visual_arts_notable_alumni_and_faculty.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "209 East 23rd Street, New York, NY 10010",
        latLong: { lat: 40.7381, long: -73.9847 },
        isPremium: false,
        premiumPackage: null,
        playNext: "school_of_visual_arts_impact_on_new_york's_arts_scene"
    },
    {
        rid: "school_of_visual_arts_impact_on_new_york's_arts_scene",
        title: "School of Visual Arts",
        subtitle: "Impact on New York's Arts Scene",
        src: require("../../../audioAssets/english/school_of_visual_arts_impact_on_new_york's_arts_scene.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Gramercy",
        landMarkCategory: "Universities",
        landmark: "School of Visual Arts",
        dateAdded: "2024-07-09",
        audioFile: "school_of_visual_arts_impact_on_new_york's_arts_scene.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "209 East 23rd Street, New York, NY 10010",
        latLong: { lat: 40.7381, long: -73.9847 },
        isPremium: false,
        premiumPackage: null,
        playNext: "cooper_union_historical_overview"
    },
    {
        rid: "cooper_union_historical_overview",
        title: "Cooper Union",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/cooper_union_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Universities",
        landmark: "Cooper Union",
        dateAdded: "2024-07-09",
        audioFile: "cooper_union_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "7 East 7th Street, New York, NY 10003",
        latLong: { lat: 40.7291, long: -73.9902 },
        isPremium: false,
        premiumPackage: null,
        playNext: "cooper_union_foundation_and_architecture"
    },
    {
        rid: "cooper_union_foundation_and_architecture",
        title: "Cooper Union",
        subtitle: "Foundation and Architecture",
        src: require("../../../audioAssets/english/cooper_union_foundation_and_architecture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Universities",
        landmark: "Cooper Union",
        dateAdded: "2024-07-09",
        audioFile: "cooper_union_foundation_and_architecture.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "7 East 7th Street, New York, NY 10003",
        latLong: { lat: 40.7291, long: -73.9902 },
        isPremium: false,
        premiumPackage: null,
        playNext: "cooper_union_notable_alumni"
    },
    {
        rid: "cooper_union_notable_alumni",
        title: "Cooper Union",
        subtitle: "Notable Alumni",
        src: require("../../../audioAssets/english/cooper_union_notable_alumni.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Universities",
        landmark: "Cooper Union",
        dateAdded: "2024-07-09",
        audioFile: "cooper_union_notable_alumni.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "7 East 7th Street, New York, NY 10003",
        latLong: { lat: 40.7291, long: -73.9902 },
        isPremium: false,
        premiumPackage: null,
        playNext: "cooper_union_impact_on_education_and_society"
    },
    {
        rid: "cooper_union_impact_on_education_and_society",
        title: "Cooper Union",
        subtitle: "Impact on Education and Society",
        src: require("../../../audioAssets/english/cooper_union_impact_on_education_and_society.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Universities",
        landmark: "Cooper Union",
        dateAdded: "2024-07-09",
        audioFile: "cooper_union_impact_on_education_and_society.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "7 East 7th Street, New York, NY 10003",
        latLong: { lat: 40.7291, long: -73.9902 },
        isPremium: false,
        premiumPackage: null,
        playNext: "weill_cornell_medical_college_overview_of_weill_cornell_medical_college"
    },
    {
        rid: "weill_cornell_medical_college_overview_of_weill_cornell_medical_college",
        title: "Weill Cornell Medical College",
        subtitle: "Overview of Weill Cornell Medical College",
        src: require("../../../audioAssets/english/weill_cornell_medical_college_overview_of_weill_cornell_medical_college.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Weill Cornell Medical College",
        dateAdded: "2024-07-09",
        audioFile: "weill_cornell_medical_college_overview_of_weill_cornell_medical_college.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1300 York Avenue, New York, NY 10065",
        latLong: { lat: 40.7641, long: -73.9549 },
        isPremium: false,
        premiumPackage: null,
        playNext: "weill_cornell_medical_college_history_and_founding_of_the_institution"
    },
    {
        rid: "weill_cornell_medical_college_history_and_founding_of_the_institution",
        title: "Weill Cornell Medical College",
        subtitle: "History and Founding of the Institution",
        src: require("../../../audioAssets/english/weill_cornell_medical_college_history_and_founding_of_the_institution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Weill Cornell Medical College",
        dateAdded: "2024-07-09",
        audioFile: "weill_cornell_medical_college_history_and_founding_of_the_institution.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1300 York Avenue, New York, NY 10065",
        latLong: { lat: 40.7641, long: -73.9549 },
        isPremium: false,
        premiumPackage: null,
        playNext: "weill_cornell_medical_college_architecture_and_campus_features"
    },
    {
        rid: "weill_cornell_medical_college_architecture_and_campus_features",
        title: "Weill Cornell Medical College",
        subtitle: "Architecture and Campus Features",
        src: require("../../../audioAssets/english/weill_cornell_medical_college_architecture_and_campus_features.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Weill Cornell Medical College",
        dateAdded: "2024-07-09",
        audioFile: "weill_cornell_medical_college_architecture_and_campus_features.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1300 York Avenue, New York, NY 10065",
        latLong: { lat: 40.7641, long: -73.9549 },
        isPremium: false,
        premiumPackage: null,
        playNext: "weill_cornell_medical_college_impact_on_medical_research_and_education"
    },
    {
        rid: "weill_cornell_medical_college_impact_on_medical_research_and_education",
        title: "Weill Cornell Medical College",
        subtitle: "Impact on Medical Research and Education",
        src: require("../../../audioAssets/english/weill_cornell_medical_college_impact_on_medical_research_and_education.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Universities",
        landmark: "Weill Cornell Medical College",
        dateAdded: "2024-07-09",
        audioFile: "weill_cornell_medical_college_impact_on_medical_research_and_education.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1300 York Avenue, New York, NY 10065",
        latLong: { lat: 40.7641, long: -73.9549 },
        isPremium: false,
        premiumPackage: null,
        playNext: "yeshiva_university_historical_origins"
    },
    {
        rid: "yeshiva_university_historical_origins",
        title: "Yeshiva University",
        subtitle: "Historical Origins",
        src: require("../../../audioAssets/english/yeshiva_university_historical_origins.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Universities",
        landmark: "Yeshiva University",
        dateAdded: "2024-07-09",
        audioFile: "yeshiva_university_historical_origins.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "500 W 185th St, New York, NY 10033",
        latLong: { lat: 40.85017, long: -73.92989 },
        isPremium: false,
        premiumPackage: null,
        playNext: "yeshiva_university_architectural_highlights"
    },
    {
        rid: "yeshiva_university_architectural_highlights",
        title: "Yeshiva University",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/yeshiva_university_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Universities",
        landmark: "Yeshiva University",
        dateAdded: "2024-07-09",
        audioFile: "yeshiva_university_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "500 W 185th St, New York, NY 10033",
        latLong: { lat: 40.85017, long: -73.92989 },
        isPremium: false,
        premiumPackage: null,
        playNext: "yeshiva_university_academic_excellence"
    },
    {
        rid: "yeshiva_university_academic_excellence",
        title: "Yeshiva University",
        subtitle: "Academic Excellence",
        src: require("../../../audioAssets/english/yeshiva_university_academic_excellence.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Universities",
        landmark: "Yeshiva University",
        dateAdded: "2024-07-09",
        audioFile: "yeshiva_university_academic_excellence.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "500 W 185th St, New York, NY 10033",
        latLong: { lat: 40.85017, long: -73.92989 },
        isPremium: false,
        premiumPackage: null,
        playNext: "yeshiva_university_community_impact_and_vision"
    },
    {
        rid: "yeshiva_university_community_impact_and_vision",
        title: "Yeshiva University",
        subtitle: "Community Impact and Vision",
        src: require("../../../audioAssets/english/yeshiva_university_community_impact_and_vision.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Washington Heights",
        landMarkCategory: "Universities",
        landmark: "Yeshiva University",
        dateAdded: "2024-07-09",
        audioFile: "yeshiva_university_community_impact_and_vision.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "500 W 185th St, New York, NY 10033",
        latLong: { lat: 40.85017, long: -73.92989 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_state_building_a_towering_icon:_overview"
    },
    {
        rid: "empire_state_building_a_towering_icon:_overview",
        title: "Empire State Building",
        subtitle: "A Towering Icon: Overview",
        src: require("../../../audioAssets/english/empire_state_building_a_towering_icon:_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire State Building",
        dateAdded: "2024-07-09",
        audioFile: "empire_state_building_a_towering_icon:_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "350 5th Ave, New York, NY 10118",
        latLong: { lat: 40.748817, long: -73.985428 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_state_building_rising_to_the_skies:_construction_and_design"
    },
    {
        rid: "empire_state_building_rising_to_the_skies:_construction_and_design",
        title: "Empire State Building",
        subtitle: "Rising to the Skies: Construction and Design",
        src: require("../../../audioAssets/english/empire_state_building_rising_to_the_skies:_construction_and_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire State Building",
        dateAdded: "2024-07-09",
        audioFile: "empire_state_building_rising_to_the_skies:_construction_and_design.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "350 5th Ave, New York, NY 10118",
        latLong: { lat: 40.748817, long: -73.985428 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_state_building_the_roaring_twenties:_new_york’s_skyline_evolution"
    },
    {
        rid: "empire_state_building_the_roaring_twenties:_new_york’s_skyline_evolution",
        title: "Empire State Building",
        subtitle: "The Roaring Twenties: New York’s Skyline Evolution",
        src: require("../../../audioAssets/english/empire_state_building_the_roaring_twenties:_new_york’s_skyline_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire State Building",
        dateAdded: "2024-07-09",
        audioFile: "empire_state_building_the_roaring_twenties:_new_york’s_skyline_evolution.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "350 5th Ave, New York, NY 10118",
        latLong: { lat: 40.748817, long: -73.985428 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_state_building_moments_in_time:_cultural_and_historical_significance"
    },
    {
        rid: "empire_state_building_moments_in_time:_cultural_and_historical_significance",
        title: "Empire State Building",
        subtitle: "Moments in Time: Cultural and Historical Significance",
        src: require("../../../audioAssets/english/empire_state_building_moments_in_time:_cultural_and_historical_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire State Building",
        dateAdded: "2024-07-09",
        audioFile: "empire_state_building_moments_in_time:_cultural_and_historical_significance.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "350 5th Ave, New York, NY 10118",
        latLong: { lat: 40.748817, long: -73.985428 },
        isPremium: false,
        premiumPackage: null,
        playNext: "statue_of_liberty_historical_overview"
    },
    {
        rid: "statue_of_liberty_historical_overview",
        title: "Statue of Liberty",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/statue_of_liberty_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Liberty Island",
        landMarkCategory: "Iconic Destination",
        landmark: "Statue of Liberty",
        dateAdded: "2024-07-09",
        audioFile: "statue_of_liberty_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Liberty Island, New York, NY 10004",
        latLong: { lat: 40.689247, long: -74.044502 },
        isPremium: false,
        premiumPackage: null,
        playNext: "statue_of_liberty_design_and_construction"
    },
    {
        rid: "statue_of_liberty_design_and_construction",
        title: "Statue of Liberty",
        subtitle: "Design and Construction",
        src: require("../../../audioAssets/english/statue_of_liberty_design_and_construction.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Liberty Island",
        landMarkCategory: "Iconic Destination",
        landmark: "Statue of Liberty",
        dateAdded: "2024-07-09",
        audioFile: "statue_of_liberty_design_and_construction.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Liberty Island, New York, NY 10004",
        latLong: { lat: 40.689247, long: -74.044502 },
        isPremium: false,
        premiumPackage: null,
        playNext: "statue_of_liberty_the_immigrant_experience"
    },
    {
        rid: "statue_of_liberty_the_immigrant_experience",
        title: "Statue of Liberty",
        subtitle: "The Immigrant Experience",
        src: require("../../../audioAssets/english/statue_of_liberty_the_immigrant_experience.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Liberty Island",
        landMarkCategory: "Iconic Destination",
        landmark: "Statue of Liberty",
        dateAdded: "2024-07-09",
        audioFile: "statue_of_liberty_the_immigrant_experience.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Liberty Island, New York, NY 10004",
        latLong: { lat: 40.689247, long: -74.044502 },
        isPremium: false,
        premiumPackage: null,
        playNext: "statue_of_liberty_symbolism_and_cultural_impact"
    },
    {
        rid: "statue_of_liberty_symbolism_and_cultural_impact",
        title: "Statue of Liberty",
        subtitle: "Symbolism and Cultural Impact",
        src: require("../../../audioAssets/english/statue_of_liberty_symbolism_and_cultural_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Liberty Island",
        landMarkCategory: "Iconic Destination",
        landmark: "Statue of Liberty",
        dateAdded: "2024-07-09",
        audioFile: "statue_of_liberty_symbolism_and_cultural_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Liberty Island, New York, NY 10004",
        latLong: { lat: 40.689247, long: -74.044502 },
        isPremium: false,
        premiumPackage: null,
        playNext: "rockefeller_center_introduction_to_rockefeller_center"
    },
    {
        rid: "rockefeller_center_introduction_to_rockefeller_center",
        title: "Rockefeller Center",
        subtitle: "Introduction to Rockefeller Center",
        src: require("../../../audioAssets/english/rockefeller_center_introduction_to_rockefeller_center.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Rockefeller Center",
        dateAdded: "2024-07-09",
        audioFile: "rockefeller_center_introduction_to_rockefeller_center.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "45 Rockefeller Plaza, New York, NY 10111, USA",
        latLong: { lat: 40.75874, long: -73.978673 },
        isPremium: false,
        premiumPackage: null,
        playNext: "rockefeller_center_art_and_architecture"
    },
    {
        rid: "rockefeller_center_art_and_architecture",
        title: "Rockefeller Center",
        subtitle: "Art and Architecture",
        src: require("../../../audioAssets/english/rockefeller_center_art_and_architecture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Rockefeller Center",
        dateAdded: "2024-07-09",
        audioFile: "rockefeller_center_art_and_architecture.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "45 Rockefeller Plaza, New York, NY 10111, USA",
        latLong: { lat: 40.75874, long: -73.978673 },
        isPremium: false,
        premiumPackage: null,
        playNext: "rockefeller_center_the_role_in_nyc's_economy"
    },
    {
        rid: "rockefeller_center_the_role_in_nyc's_economy",
        title: "Rockefeller Center",
        subtitle: "The Role in NYC's Economy",
        src: require("../../../audioAssets/english/rockefeller_center_the_role_in_nyc's_economy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Rockefeller Center",
        dateAdded: "2024-07-09",
        audioFile: "rockefeller_center_the_role_in_nyc's_economy.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "45 Rockefeller Plaza, New York, NY 10111, USA",
        latLong: { lat: 40.75874, long: -73.978673 },
        isPremium: false,
        premiumPackage: null,
        playNext: "rockefeller_center_cultural_impact_and_traditions"
    },
    {
        rid: "rockefeller_center_cultural_impact_and_traditions",
        title: "Rockefeller Center",
        subtitle: "Cultural Impact and Traditions",
        src: require("../../../audioAssets/english/rockefeller_center_cultural_impact_and_traditions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Rockefeller Center",
        dateAdded: "2024-07-09",
        audioFile: "rockefeller_center_cultural_impact_and_traditions.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "45 Rockefeller Plaza, New York, NY 10111, USA",
        latLong: { lat: 40.75874, long: -73.978673 },
        isPremium: false,
        premiumPackage: null,
        playNext: "one_world_trade_center_a_beacon_of_resilience"
    },
    {
        rid: "one_world_trade_center_a_beacon_of_resilience",
        title: "One World Trade Center",
        subtitle: "A Beacon of Resilience",
        src: require("../../../audioAssets/english/one_world_trade_center_a_beacon_of_resilience.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "One World Trade Center",
        dateAdded: "2024-07-09",
        audioFile: "one_world_trade_center_a_beacon_of_resilience.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "285 Fulton St, New York, NY 10007, United States",
        latLong: { lat: 40.712743, long: -74.013379 },
        isPremium: false,
        premiumPackage: null,
        playNext: "one_world_trade_center_architecture_and_design"
    },
    {
        rid: "one_world_trade_center_architecture_and_design",
        title: "One World Trade Center",
        subtitle: "Architecture and Design",
        src: require("../../../audioAssets/english/one_world_trade_center_architecture_and_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "One World Trade Center",
        dateAdded: "2024-07-09",
        audioFile: "one_world_trade_center_architecture_and_design.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "285 Fulton St, New York, NY 10007, United States",
        latLong: { lat: 40.712743, long: -74.013379 },
        isPremium: false,
        premiumPackage: null,
        playNext: "one_world_trade_center_building_a_symbol"
    },
    {
        rid: "one_world_trade_center_building_a_symbol",
        title: "One World Trade Center",
        subtitle: "Building a Symbol",
        src: require("../../../audioAssets/english/one_world_trade_center_building_a_symbol.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "One World Trade Center",
        dateAdded: "2024-07-09",
        audioFile: "one_world_trade_center_building_a_symbol.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "285 Fulton St, New York, NY 10007, United States",
        latLong: { lat: 40.712743, long: -74.013379 },
        isPremium: false,
        premiumPackage: null,
        playNext: "one_world_trade_center_a_hub_of_modern_life"
    },
    {
        rid: "one_world_trade_center_a_hub_of_modern_life",
        title: "One World Trade Center",
        subtitle: "A Hub of Modern Life",
        src: require("../../../audioAssets/english/one_world_trade_center_a_hub_of_modern_life.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "One World Trade Center",
        dateAdded: "2024-07-09",
        audioFile: "one_world_trade_center_a_hub_of_modern_life.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "285 Fulton St, New York, NY 10007, United States",
        latLong: { lat: 40.712743, long: -74.013379 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chrysler_building_gleaming_art_deco_icon"
    },
    {
        rid: "chrysler_building_gleaming_art_deco_icon",
        title: "Chrysler Building",
        subtitle: "Gleaming Art Deco Icon",
        src: require("../../../audioAssets/english/chrysler_building_gleaming_art_deco_icon.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown East",
        landMarkCategory: "Iconic Destination",
        landmark: "Chrysler Building",
        dateAdded: "2024-07-09",
        audioFile: "chrysler_building_gleaming_art_deco_icon.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "405 Lexington Ave, New York, NY 10174",
        latLong: { lat: 40.7516, long: -73.9755 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chrysler_building_rivalry_for_the_skies"
    },
    {
        rid: "chrysler_building_rivalry_for_the_skies",
        title: "Chrysler Building",
        subtitle: "Rivalry for the Skies",
        src: require("../../../audioAssets/english/chrysler_building_rivalry_for_the_skies.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown East",
        landMarkCategory: "Iconic Destination",
        landmark: "Chrysler Building",
        dateAdded: "2024-07-09",
        audioFile: "chrysler_building_rivalry_for_the_skies.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "405 Lexington Ave, New York, NY 10174",
        latLong: { lat: 40.7516, long: -73.9755 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chrysler_building_architectural_marvels"
    },
    {
        rid: "chrysler_building_architectural_marvels",
        title: "Chrysler Building",
        subtitle: "Architectural Marvels",
        src: require("../../../audioAssets/english/chrysler_building_architectural_marvels.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown East",
        landMarkCategory: "Iconic Destination",
        landmark: "Chrysler Building",
        dateAdded: "2024-07-09",
        audioFile: "chrysler_building_architectural_marvels.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "405 Lexington Ave, New York, NY 10174",
        latLong: { lat: 40.7516, long: -73.9755 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chrysler_building_chrysler_legacy"
    },
    {
        rid: "chrysler_building_chrysler_legacy",
        title: "Chrysler Building",
        subtitle: "Chrysler Legacy",
        src: require("../../../audioAssets/english/chrysler_building_chrysler_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown East",
        landMarkCategory: "Iconic Destination",
        landmark: "Chrysler Building",
        dateAdded: "2024-07-09",
        audioFile: "chrysler_building_chrysler_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "405 Lexington Ave, New York, NY 10174",
        latLong: { lat: 40.7516, long: -73.9755 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_bridge_a_grand_conception:_the_vision_and_design"
    },
    {
        rid: "brooklyn_bridge_a_grand_conception:_the_vision_and_design",
        title: "Brooklyn Bridge",
        subtitle: "A Grand Conception: The Vision and Design",
        src: require("../../../audioAssets/english/brooklyn_bridge_a_grand_conception:_the_vision_and_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Iconic Destination",
        landmark: "Brooklyn Bridge",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_bridge_a_grand_conception:_the_vision_and_design.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Brooklyn, NY 11201, United States",
        latLong: { lat: 40.70569, long: -73.99636 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_bridge_engineering_marvel:_construction_challenges_and_triumphs"
    },
    {
        rid: "brooklyn_bridge_engineering_marvel:_construction_challenges_and_triumphs",
        title: "Brooklyn Bridge",
        subtitle: "Engineering Marvel: Construction Challenges and Triumphs",
        src: require("../../../audioAssets/english/brooklyn_bridge_engineering_marvel:_construction_challenges_and_triumphs.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Iconic Destination",
        landmark: "Brooklyn Bridge",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_bridge_engineering_marvel:_construction_challenges_and_triumphs.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Brooklyn, NY 11201, United States",
        latLong: { lat: 40.70569, long: -73.99636 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_bridge_historical_context:_brooklyn_and_manhattan_in_the_1870s"
    },
    {
        rid: "brooklyn_bridge_historical_context:_brooklyn_and_manhattan_in_the_1870s",
        title: "Brooklyn Bridge",
        subtitle: "Historical Context: Brooklyn and Manhattan in the 1870s",
        src: require("../../../audioAssets/english/brooklyn_bridge_historical_context:_brooklyn_and_manhattan_in_the_1870s.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Iconic Destination",
        landmark: "Brooklyn Bridge",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_bridge_historical_context:_brooklyn_and_manhattan_in_the_1870s.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Brooklyn, NY 11201, United States",
        latLong: { lat: 40.70569, long: -73.99636 },
        isPremium: false,
        premiumPackage: null,
        playNext: "brooklyn_bridge_icon_of_new_york:_cultural_impact_and_legacy"
    },
    {
        rid: "brooklyn_bridge_icon_of_new_york:_cultural_impact_and_legacy",
        title: "Brooklyn Bridge",
        subtitle: "Icon of New York: Cultural Impact and Legacy",
        src: require("../../../audioAssets/english/brooklyn_bridge_icon_of_new_york:_cultural_impact_and_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Iconic Destination",
        landmark: "Brooklyn Bridge",
        dateAdded: "2024-07-09",
        audioFile: "brooklyn_bridge_icon_of_new_york:_cultural_impact_and_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Brooklyn, NY 11201, United States",
        latLong: { lat: 40.70569, long: -73.99636 },
        isPremium: false,
        premiumPackage: null,
        playNext: "wall_street_the_birth_of_wall_street"
    },
    {
        rid: "wall_street_the_birth_of_wall_street",
        title: "Wall Street",
        subtitle: "The Birth of Wall Street",
        src: require("../../../audioAssets/english/wall_street_the_birth_of_wall_street.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "Wall Street",
        dateAdded: "2024-07-09",
        audioFile: "wall_street_the_birth_of_wall_street.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Wall Street, New York, NY, USA",
        latLong: { lat: 40.7069, long: -74.0113 },
        isPremium: false,
        premiumPackage: null,
        playNext: "wall_street_the_architecture_of_wall_street"
    },
    {
        rid: "wall_street_the_architecture_of_wall_street",
        title: "Wall Street",
        subtitle: "The Architecture of Wall Street",
        src: require("../../../audioAssets/english/wall_street_the_architecture_of_wall_street.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "Wall Street",
        dateAdded: "2024-07-09",
        audioFile: "wall_street_the_architecture_of_wall_street.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Wall Street, New York, NY, USA",
        latLong: { lat: 40.7069, long: -74.0113 },
        isPremium: false,
        premiumPackage: null,
        playNext: "wall_street_the_financial_district's_evolution"
    },
    {
        rid: "wall_street_the_financial_district's_evolution",
        title: "Wall Street",
        subtitle: "The Financial District's Evolution",
        src: require("../../../audioAssets/english/wall_street_the_financial_district's_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "Wall Street",
        dateAdded: "2024-07-09",
        audioFile: "wall_street_the_financial_district's_evolution.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Wall Street, New York, NY, USA",
        latLong: { lat: 40.7069, long: -74.0113 },
        isPremium: false,
        premiumPackage: null,
        playNext: "wall_street_wall_street's_cultural_impact"
    },
    {
        rid: "wall_street_wall_street's_cultural_impact",
        title: "Wall Street",
        subtitle: "Wall Street's Cultural Impact",
        src: require("../../../audioAssets/english/wall_street_wall_street's_cultural_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Financial District",
        landMarkCategory: "Iconic Destination",
        landmark: "Wall Street",
        dateAdded: "2024-07-09",
        audioFile: "wall_street_wall_street's_cultural_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Wall Street, New York, NY, USA",
        latLong: { lat: 40.7069, long: -74.0113 },
        isPremium: false,
        premiumPackage: null,
        playNext: "grand_central_terminal_a_timeless_icon:_an_overview"
    },
    {
        rid: "grand_central_terminal_a_timeless_icon:_an_overview",
        title: "Grand Central Terminal",
        subtitle: "A Timeless Icon: An Overview",
        src: require("../../../audioAssets/english/grand_central_terminal_a_timeless_icon:_an_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Grand Central Terminal",
        dateAdded: "2024-07-09",
        audioFile: "grand_central_terminal_a_timeless_icon:_an_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "89 E 42nd St, New York, NY 10017",
        latLong: { lat: 40.752726, long: -73.977229 },
        isPremium: false,
        premiumPackage: null,
        playNext: "grand_central_terminal_engineering_feat:_construction_and_design"
    },
    {
        rid: "grand_central_terminal_engineering_feat:_construction_and_design",
        title: "Grand Central Terminal",
        subtitle: "Engineering Feat: Construction and Design",
        src: require("../../../audioAssets/english/grand_central_terminal_engineering_feat:_construction_and_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Grand Central Terminal",
        dateAdded: "2024-07-09",
        audioFile: "grand_central_terminal_engineering_feat:_construction_and_design.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "89 E 42nd St, New York, NY 10017",
        latLong: { lat: 40.752726, long: -73.977229 },
        isPremium: false,
        premiumPackage: null,
        playNext: "grand_central_terminal_the_terminal's_golden_age:_new_york_in_the_early_20th_century"
    },
    {
        rid: "grand_central_terminal_the_terminal's_golden_age:_new_york_in_the_early_20th_century",
        title: "Grand Central Terminal",
        subtitle: "The Terminal's Golden Age: New York in the Early 20th Century",
        src: require("../../../audioAssets/english/grand_central_terminal_the_terminal's_golden_age:_new_york_in_the_early_20th_century.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Grand Central Terminal",
        dateAdded: "2024-07-09",
        audioFile: "grand_central_terminal_the_terminal's_golden_age:_new_york_in_the_early_20th_century.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "89 E 42nd St, New York, NY 10017",
        latLong: { lat: 40.752726, long: -73.977229 },
        isPremium: false,
        premiumPackage: null,
        playNext: "grand_central_terminal_heart_of_the_city:_cultural_and_historical_significance"
    },
    {
        rid: "grand_central_terminal_heart_of_the_city:_cultural_and_historical_significance",
        title: "Grand Central Terminal",
        subtitle: "Heart of the City: Cultural and Historical Significance",
        src: require("../../../audioAssets/english/grand_central_terminal_heart_of_the_city:_cultural_and_historical_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Grand Central Terminal",
        dateAdded: "2024-07-09",
        audioFile: "grand_central_terminal_heart_of_the_city:_cultural_and_historical_significance.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "89 E 42nd St, New York, NY 10017",
        latLong: { lat: 40.752726, long: -73.977229 },
        isPremium: false,
        premiumPackage: null,
        playNext: "ellis_island_gateway_to_america"
    },
    {
        rid: "ellis_island_gateway_to_america",
        title: "Ellis Island",
        subtitle: "Gateway to America",
        src: require("../../../audioAssets/english/ellis_island_gateway_to_america.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Ellis Island (accessible from Battery Park)",
        landMarkCategory: "Museums",
        landmark: "Ellis Island",
        dateAdded: "2024-07-09",
        audioFile: "ellis_island_gateway_to_america.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Ellis Island, New York, NY 10004",
        latLong: { lat: 40.699475, long: -74.03956 },
        isPremium: false,
        premiumPackage: null,
        playNext: "ellis_island_architectural_marvels_and_expansions"
    },
    {
        rid: "ellis_island_architectural_marvels_and_expansions",
        title: "Ellis Island",
        subtitle: "Architectural Marvels and Expansions",
        src: require("../../../audioAssets/english/ellis_island_architectural_marvels_and_expansions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Ellis Island (accessible from Battery Park)",
        landMarkCategory: "Museums",
        landmark: "Ellis Island",
        dateAdded: "2024-07-09",
        audioFile: "ellis_island_architectural_marvels_and_expansions.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Ellis Island, New York, NY 10004",
        latLong: { lat: 40.699475, long: -74.03956 },
        isPremium: false,
        premiumPackage: null,
        playNext: "ellis_island_the_immigrant_experience"
    },
    {
        rid: "ellis_island_the_immigrant_experience",
        title: "Ellis Island",
        subtitle: "The Immigrant Experience",
        src: require("../../../audioAssets/english/ellis_island_the_immigrant_experience.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Ellis Island (accessible from Battery Park)",
        landMarkCategory: "Museums",
        landmark: "Ellis Island",
        dateAdded: "2024-07-09",
        audioFile: "ellis_island_the_immigrant_experience.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Ellis Island, New York, NY 10004",
        latLong: { lat: 40.699475, long: -74.03956 },
        isPremium: false,
        premiumPackage: null,
        playNext: "ellis_island_legacy_and_memory"
    },
    {
        rid: "ellis_island_legacy_and_memory",
        title: "Ellis Island",
        subtitle: "Legacy and Memory",
        src: require("../../../audioAssets/english/ellis_island_legacy_and_memory.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Ellis Island (accessible from Battery Park)",
        landMarkCategory: "Museums",
        landmark: "Ellis Island",
        dateAdded: "2024-07-09",
        audioFile: "ellis_island_legacy_and_memory.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Ellis Island, New York, NY 10004",
        latLong: { lat: 40.699475, long: -74.03956 },
        isPremium: false,
        premiumPackage: null,
        playNext: "top_of_the_rock_introduction_and_history"
    },
    {
        rid: "top_of_the_rock_introduction_and_history",
        title: "Top of the Rock",
        subtitle: "Introduction and History",
        src: require("../../../audioAssets/english/top_of_the_rock_introduction_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Top of the Rock",
        dateAdded: "2024-07-09",
        audioFile: "top_of_the_rock_introduction_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "30 Rockefeller Plaza, New York, NY 10112",
        latLong: { lat: 40.759, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "top_of_the_rock_engineering_marvel"
    },
    {
        rid: "top_of_the_rock_engineering_marvel",
        title: "Top of the Rock",
        subtitle: "Engineering Marvel",
        src: require("../../../audioAssets/english/top_of_the_rock_engineering_marvel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Top of the Rock",
        dateAdded: "2024-07-09",
        audioFile: "top_of_the_rock_engineering_marvel.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "30 Rockefeller Plaza, New York, NY 10112",
        latLong: { lat: 40.759, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "top_of_the_rock_views_and_sightlines"
    },
    {
        rid: "top_of_the_rock_views_and_sightlines",
        title: "Top of the Rock",
        subtitle: "Views and Sightlines",
        src: require("../../../audioAssets/english/top_of_the_rock_views_and_sightlines.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Top of the Rock",
        dateAdded: "2024-07-09",
        audioFile: "top_of_the_rock_views_and_sightlines.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "30 Rockefeller Plaza, New York, NY 10112",
        latLong: { lat: 40.759, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "top_of_the_rock_visitor_experience"
    },
    {
        rid: "top_of_the_rock_visitor_experience",
        title: "Top of the Rock",
        subtitle: "Visitor Experience",
        src: require("../../../audioAssets/english/top_of_the_rock_visitor_experience.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Iconic Destination",
        landmark: "Top of the Rock",
        dateAdded: "2024-07-09",
        audioFile: "top_of_the_rock_visitor_experience.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "30 Rockefeller Plaza, New York, NY 10112",
        latLong: { lat: 40.759, long: -73.9787 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flatiron_building_historical_overview"
    },
    {
        rid: "flatiron_building_historical_overview",
        title: "Flatiron Building",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/flatiron_building_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Iconic Destination",
        landmark: "Flatiron Building",
        dateAdded: "2024-07-09",
        audioFile: "flatiron_building_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "175 5th Ave, New York, NY 10010",
        latLong: { lat: 40.741061, long: -73.989699 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flatiron_building_architectural_marvel"
    },
    {
        rid: "flatiron_building_architectural_marvel",
        title: "Flatiron Building",
        subtitle: "Architectural Marvel",
        src: require("../../../audioAssets/english/flatiron_building_architectural_marvel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Iconic Destination",
        landmark: "Flatiron Building",
        dateAdded: "2024-07-09",
        audioFile: "flatiron_building_architectural_marvel.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "175 5th Ave, New York, NY 10010",
        latLong: { lat: 40.741061, long: -73.989699 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flatiron_building_new_york_at_the_turn_of_the_century"
    },
    {
        rid: "flatiron_building_new_york_at_the_turn_of_the_century",
        title: "Flatiron Building",
        subtitle: "New York at the Turn of the Century",
        src: require("../../../audioAssets/english/flatiron_building_new_york_at_the_turn_of_the_century.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Iconic Destination",
        landmark: "Flatiron Building",
        dateAdded: "2024-07-09",
        audioFile: "flatiron_building_new_york_at_the_turn_of_the_century.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "175 5th Ave, New York, NY 10010",
        latLong: { lat: 40.741061, long: -73.989699 },
        isPremium: false,
        premiumPackage: null,
        playNext: "flatiron_building_modern-day_icon_and_cultural_influence"
    },
    {
        rid: "flatiron_building_modern-day_icon_and_cultural_influence",
        title: "Flatiron Building",
        subtitle: "Modern-Day Icon and Cultural Influence",
        src: require("../../../audioAssets/english/flatiron_building_modern-day_icon_and_cultural_influence.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Flatiron District",
        landMarkCategory: "Iconic Destination",
        landmark: "Flatiron Building",
        dateAdded: "2024-07-09",
        audioFile: "flatiron_building_modern-day_icon_and_cultural_influence.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "175 5th Ave, New York, NY 10010",
        latLong: { lat: 40.741061, long: -73.989699 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_vessel_introduction_to_the_vessel"
    },
    {
        rid: "the_vessel_introduction_to_the_vessel",
        title: "The Vessel",
        subtitle: "Introduction to The Vessel",
        src: require("../../../audioAssets/english/the_vessel_introduction_to_the_vessel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hudson Yards",
        landMarkCategory: "Iconic Destination",
        landmark: "The Vessel",
        dateAdded: "2024-07-09",
        audioFile: "the_vessel_introduction_to_the_vessel.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "20 Hudson Yards, New York, NY 10001",
        latLong: { lat: 40.7542, long: -74.0017 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_vessel_design_and_architecture"
    },
    {
        rid: "the_vessel_design_and_architecture",
        title: "The Vessel",
        subtitle: "Design and Architecture",
        src: require("../../../audioAssets/english/the_vessel_design_and_architecture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hudson Yards",
        landMarkCategory: "Iconic Destination",
        landmark: "The Vessel",
        dateAdded: "2024-07-09",
        audioFile: "the_vessel_design_and_architecture.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "20 Hudson Yards, New York, NY 10001",
        latLong: { lat: 40.7542, long: -74.0017 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_vessel_construction_and_engineering"
    },
    {
        rid: "the_vessel_construction_and_engineering",
        title: "The Vessel",
        subtitle: "Construction and Engineering",
        src: require("../../../audioAssets/english/the_vessel_construction_and_engineering.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hudson Yards",
        landMarkCategory: "Iconic Destination",
        landmark: "The Vessel",
        dateAdded: "2024-07-09",
        audioFile: "the_vessel_construction_and_engineering.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "20 Hudson Yards, New York, NY 10001",
        latLong: { lat: 40.7542, long: -74.0017 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_vessel_visitor_experience_and_cultural_impact"
    },
    {
        rid: "the_vessel_visitor_experience_and_cultural_impact",
        title: "The Vessel",
        subtitle: "Visitor Experience and Cultural Impact",
        src: require("../../../audioAssets/english/the_vessel_visitor_experience_and_cultural_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hudson Yards",
        landMarkCategory: "Iconic Destination",
        landmark: "The Vessel",
        dateAdded: "2024-07-09",
        audioFile: "the_vessel_visitor_experience_and_cultural_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "20 Hudson Yards, New York, NY 10001",
        latLong: { lat: 40.7542, long: -74.0017 },
        isPremium: false,
        premiumPackage: null,
        playNext: "essex_market_historical_overview"
    },
    {
        rid: "essex_market_historical_overview",
        title: "Essex Market",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/essex_market_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Market",
        landmark: "Essex Market",
        dateAdded: "2024-07-09",
        audioFile: "essex_market_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "88 Essex Street, New York, NY 10002",
        latLong: { lat: 40.7184, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "essex_market_architectural_evolution"
    },
    {
        rid: "essex_market_architectural_evolution",
        title: "Essex Market",
        subtitle: "Architectural Evolution",
        src: require("../../../audioAssets/english/essex_market_architectural_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Market",
        landmark: "Essex Market",
        dateAdded: "2024-07-09",
        audioFile: "essex_market_architectural_evolution.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "88 Essex Street, New York, NY 10002",
        latLong: { lat: 40.7184, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "essex_market_life_at_the_market:_vendors_and_visitors"
    },
    {
        rid: "essex_market_life_at_the_market:_vendors_and_visitors",
        title: "Essex Market",
        subtitle: "Life at the Market: Vendors and Visitors",
        src: require("../../../audioAssets/english/essex_market_life_at_the_market:_vendors_and_visitors.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Market",
        landmark: "Essex Market",
        dateAdded: "2024-07-09",
        audioFile: "essex_market_life_at_the_market:_vendors_and_visitors.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "88 Essex Street, New York, NY 10002",
        latLong: { lat: 40.7184, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "essex_market_essex_market_in_the_community"
    },
    {
        rid: "essex_market_essex_market_in_the_community",
        title: "Essex Market",
        subtitle: "Essex Market in the Community",
        src: require("../../../audioAssets/english/essex_market_essex_market_in_the_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Public Market",
        landmark: "Essex Market",
        dateAdded: "2024-07-09",
        audioFile: "essex_market_essex_market_in_the_community.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "88 Essex Street, New York, NY 10002",
        latLong: { lat: 40.7184, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dekalb_market_hall_introduction_to_dekalb_market_hall"
    },
    {
        rid: "dekalb_market_hall_introduction_to_dekalb_market_hall",
        title: "Dekalb Market Hall",
        subtitle: "Introduction to Dekalb Market Hall",
        src: require("../../../audioAssets/english/dekalb_market_hall_introduction_to_dekalb_market_hall.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Downtown Brooklyn",
        landMarkCategory: "Public Market",
        landmark: "Dekalb Market Hall",
        dateAdded: "2024-07-09",
        audioFile: "dekalb_market_hall_introduction_to_dekalb_market_hall.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "445 Albee Square W, Brooklyn, NY 11201",
        latLong: { lat: 40.6914, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dekalb_market_hall_from_concept_to_creation"
    },
    {
        rid: "dekalb_market_hall_from_concept_to_creation",
        title: "Dekalb Market Hall",
        subtitle: "From Concept to Creation",
        src: require("../../../audioAssets/english/dekalb_market_hall_from_concept_to_creation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Downtown Brooklyn",
        landMarkCategory: "Public Market",
        landmark: "Dekalb Market Hall",
        dateAdded: "2024-07-09",
        audioFile: "dekalb_market_hall_from_concept_to_creation.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "445 Albee Square W, Brooklyn, NY 11201",
        latLong: { lat: 40.6914, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dekalb_market_hall_a_culinary_journey"
    },
    {
        rid: "dekalb_market_hall_a_culinary_journey",
        title: "Dekalb Market Hall",
        subtitle: "A Culinary Journey",
        src: require("../../../audioAssets/english/dekalb_market_hall_a_culinary_journey.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Downtown Brooklyn",
        landMarkCategory: "Public Market",
        landmark: "Dekalb Market Hall",
        dateAdded: "2024-07-09",
        audioFile: "dekalb_market_hall_a_culinary_journey.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "445 Albee Square W, Brooklyn, NY 11201",
        latLong: { lat: 40.6914, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dekalb_market_hall_cultural_impact_and_community"
    },
    {
        rid: "dekalb_market_hall_cultural_impact_and_community",
        title: "Dekalb Market Hall",
        subtitle: "Cultural Impact and Community",
        src: require("../../../audioAssets/english/dekalb_market_hall_cultural_impact_and_community.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Downtown Brooklyn",
        landMarkCategory: "Public Market",
        landmark: "Dekalb Market Hall",
        dateAdded: "2024-07-09",
        audioFile: "dekalb_market_hall_cultural_impact_and_community.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "445 Albee Square W, Brooklyn, NY 11201",
        latLong: { lat: 40.6914, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gansevoort_market_historical_background"
    },
    {
        rid: "gansevoort_market_historical_background",
        title: "Gansevoort Market",
        subtitle: "Historical Background",
        src: require("../../../audioAssets/english/gansevoort_market_historical_background.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Public Market",
        landmark: "Gansevoort Market",
        dateAdded: "2024-07-09",
        audioFile: "gansevoort_market_historical_background.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "353 W 14th St, New York, NY 10014",
        latLong: { lat: 40.7412, long: -74.0054 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gansevoort_market_transformation_and_modernization"
    },
    {
        rid: "gansevoort_market_transformation_and_modernization",
        title: "Gansevoort Market",
        subtitle: "Transformation and Modernization",
        src: require("../../../audioAssets/english/gansevoort_market_transformation_and_modernization.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Public Market",
        landmark: "Gansevoort Market",
        dateAdded: "2024-07-09",
        audioFile: "gansevoort_market_transformation_and_modernization.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "353 W 14th St, New York, NY 10014",
        latLong: { lat: 40.7412, long: -74.0054 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gansevoort_market_local_vendors_and_cuisine"
    },
    {
        rid: "gansevoort_market_local_vendors_and_cuisine",
        title: "Gansevoort Market",
        subtitle: "Local Vendors and Cuisine",
        src: require("../../../audioAssets/english/gansevoort_market_local_vendors_and_cuisine.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Public Market",
        landmark: "Gansevoort Market",
        dateAdded: "2024-07-09",
        audioFile: "gansevoort_market_local_vendors_and_cuisine.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "353 W 14th St, New York, NY 10014",
        latLong: { lat: 40.7412, long: -74.0054 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gansevoort_market_community_impact_and_cultural_influence"
    },
    {
        rid: "gansevoort_market_community_impact_and_cultural_influence",
        title: "Gansevoort Market",
        subtitle: "Community Impact and Cultural Influence",
        src: require("../../../audioAssets/english/gansevoort_market_community_impact_and_cultural_influence.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Meatpacking District",
        landMarkCategory: "Public Market",
        landmark: "Gansevoort Market",
        dateAdded: "2024-07-09",
        audioFile: "gansevoort_market_community_impact_and_cultural_influence.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "353 W 14th St, New York, NY 10014",
        latLong: { lat: 40.7412, long: -74.0054 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_ed_sullivan_theater_historical_overview"
    },
    {
        rid: "the_ed_sullivan_theater_historical_overview",
        title: "The Ed Sullivan Theater",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/the_ed_sullivan_theater_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "The Ed Sullivan Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_ed_sullivan_theater_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1697 Broadway, New York, NY 10019",
        latLong: { lat: 40.7638, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_ed_sullivan_theater_architectural_features"
    },
    {
        rid: "the_ed_sullivan_theater_architectural_features",
        title: "The Ed Sullivan Theater",
        subtitle: "Architectural Features",
        src: require("../../../audioAssets/english/the_ed_sullivan_theater_architectural_features.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "The Ed Sullivan Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_ed_sullivan_theater_architectural_features.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1697 Broadway, New York, NY 10019",
        latLong: { lat: 40.7638, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_ed_sullivan_theater_famous_performances"
    },
    {
        rid: "the_ed_sullivan_theater_famous_performances",
        title: "The Ed Sullivan Theater",
        subtitle: "Famous Performances",
        src: require("../../../audioAssets/english/the_ed_sullivan_theater_famous_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "The Ed Sullivan Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_ed_sullivan_theater_famous_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1697 Broadway, New York, NY 10019",
        latLong: { lat: 40.7638, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "the_ed_sullivan_theater_modern-day_use_and_legacy"
    },
    {
        rid: "the_ed_sullivan_theater_modern-day_use_and_legacy",
        title: "The Ed Sullivan Theater",
        subtitle: "Modern-day Use and Legacy",
        src: require("../../../audioAssets/english/the_ed_sullivan_theater_modern-day_use_and_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "The Ed Sullivan Theater",
        dateAdded: "2024-07-09",
        audioFile: "the_ed_sullivan_theater_modern-day_use_and_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1697 Broadway, New York, NY 10019",
        latLong: { lat: 40.7638, long: -73.9822 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gershwin_theatre_history_and_legacy"
    },
    {
        rid: "gershwin_theatre_history_and_legacy",
        title: "Gershwin Theatre",
        subtitle: "History and Legacy",
        src: require("../../../audioAssets/english/gershwin_theatre_history_and_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Gershwin Theatre",
        dateAdded: "2024-07-09",
        audioFile: "gershwin_theatre_history_and_legacy.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "222 West 51st Street, New York, NY 10019",
        latLong: { lat: 40.76231, long: -73.985768 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gershwin_theatre_architectural_marvel"
    },
    {
        rid: "gershwin_theatre_architectural_marvel",
        title: "Gershwin Theatre",
        subtitle: "Architectural Marvel",
        src: require("../../../audioAssets/english/gershwin_theatre_architectural_marvel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Gershwin Theatre",
        dateAdded: "2024-07-09",
        audioFile: "gershwin_theatre_architectural_marvel.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "222 West 51st Street, New York, NY 10019",
        latLong: { lat: 40.76231, long: -73.985768 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gershwin_theatre_famous_productions"
    },
    {
        rid: "gershwin_theatre_famous_productions",
        title: "Gershwin Theatre",
        subtitle: "Famous Productions",
        src: require("../../../audioAssets/english/gershwin_theatre_famous_productions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Gershwin Theatre",
        dateAdded: "2024-07-09",
        audioFile: "gershwin_theatre_famous_productions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "222 West 51st Street, New York, NY 10019",
        latLong: { lat: 40.76231, long: -73.985768 },
        isPremium: false,
        premiumPackage: null,
        playNext: "gershwin_theatre_cultural_impact_on_broadway"
    },
    {
        rid: "gershwin_theatre_cultural_impact_on_broadway",
        title: "Gershwin Theatre",
        subtitle: "Cultural Impact on Broadway",
        src: require("../../../audioAssets/english/gershwin_theatre_cultural_impact_on_broadway.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Gershwin Theatre",
        dateAdded: "2024-07-09",
        audioFile: "gershwin_theatre_cultural_impact_on_broadway.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "222 West 51st Street, New York, NY 10019",
        latLong: { lat: 40.76231, long: -73.985768 },
        isPremium: false,
        premiumPackage: null,
        playNext: "winter_garden_theatre_history_and_architecture"
    },
    {
        rid: "winter_garden_theatre_history_and_architecture",
        title: "Winter Garden Theatre",
        subtitle: "History and Architecture",
        src: require("../../../audioAssets/english/winter_garden_theatre_history_and_architecture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Winter Garden Theatre",
        dateAdded: "2024-07-09",
        audioFile: "winter_garden_theatre_history_and_architecture.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "1634 Broadway, New York, NY 10019",
        latLong: { lat: 40.761575, long: -73.983373 },
        isPremium: false,
        premiumPackage: null,
        playNext: "winter_garden_theatre_notable_productions"
    },
    {
        rid: "winter_garden_theatre_notable_productions",
        title: "Winter Garden Theatre",
        subtitle: "Notable Productions",
        src: require("../../../audioAssets/english/winter_garden_theatre_notable_productions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Winter Garden Theatre",
        dateAdded: "2024-07-09",
        audioFile: "winter_garden_theatre_notable_productions.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "1634 Broadway, New York, NY 10019",
        latLong: { lat: 40.761575, long: -73.983373 },
        isPremium: false,
        premiumPackage: null,
        playNext: "winter_garden_theatre_stars_and_legends"
    },
    {
        rid: "winter_garden_theatre_stars_and_legends",
        title: "Winter Garden Theatre",
        subtitle: "Stars and Legends",
        src: require("../../../audioAssets/english/winter_garden_theatre_stars_and_legends.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Winter Garden Theatre",
        dateAdded: "2024-07-09",
        audioFile: "winter_garden_theatre_stars_and_legends.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "1634 Broadway, New York, NY 10019",
        latLong: { lat: 40.761575, long: -73.983373 },
        isPremium: false,
        premiumPackage: null,
        playNext: "winter_garden_theatre_theatre's_role_in_broadway"
    },
    {
        rid: "winter_garden_theatre_theatre's_role_in_broadway",
        title: "Winter Garden Theatre",
        subtitle: "Theatre's Role in Broadway",
        src: require("../../../audioAssets/english/winter_garden_theatre_theatre's_role_in_broadway.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Winter Garden Theatre",
        dateAdded: "2024-07-09",
        audioFile: "winter_garden_theatre_theatre's_role_in_broadway.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "1634 Broadway, New York, NY 10019",
        latLong: { lat: 40.761575, long: -73.983373 },
        isPremium: false,
        premiumPackage: null,
        playNext: "shubert_theatre_a_grand_beginning:_early_years_of_shubert_theatre"
    },
    {
        rid: "shubert_theatre_a_grand_beginning:_early_years_of_shubert_theatre",
        title: "Shubert Theatre",
        subtitle: "A Grand Beginning: Early Years of Shubert Theatre",
        src: require("../../../audioAssets/english/shubert_theatre_a_grand_beginning:_early_years_of_shubert_theatre.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Shubert Theatre",
        dateAdded: "2024-07-09",
        audioFile: "shubert_theatre_a_grand_beginning:_early_years_of_shubert_theatre.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "225 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.7582, long: -73.9884 },
        isPremium: false,
        premiumPackage: null,
        playNext: "shubert_theatre_architectural_marvel:_design_and_construction"
    },
    {
        rid: "shubert_theatre_architectural_marvel:_design_and_construction",
        title: "Shubert Theatre",
        subtitle: "Architectural Marvel: Design and Construction",
        src: require("../../../audioAssets/english/shubert_theatre_architectural_marvel:_design_and_construction.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Shubert Theatre",
        dateAdded: "2024-07-09",
        audioFile: "shubert_theatre_architectural_marvel:_design_and_construction.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "225 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.7582, long: -73.9884 },
        isPremium: false,
        premiumPackage: null,
        playNext: "shubert_theatre_broadway_legends:_iconic_performances_and_shows"
    },
    {
        rid: "shubert_theatre_broadway_legends:_iconic_performances_and_shows",
        title: "Shubert Theatre",
        subtitle: "Broadway Legends: Iconic Performances and Shows",
        src: require("../../../audioAssets/english/shubert_theatre_broadway_legends:_iconic_performances_and_shows.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Shubert Theatre",
        dateAdded: "2024-07-09",
        audioFile: "shubert_theatre_broadway_legends:_iconic_performances_and_shows.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "225 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.7582, long: -73.9884 },
        isPremium: false,
        premiumPackage: null,
        playNext: "shubert_theatre_preserving_history:_restoration_and_modern_day_significance"
    },
    {
        rid: "shubert_theatre_preserving_history:_restoration_and_modern_day_significance",
        title: "Shubert Theatre",
        subtitle: "Preserving History: Restoration and Modern Day Significance",
        src: require("../../../audioAssets/english/shubert_theatre_preserving_history:_restoration_and_modern_day_significance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Midtown",
        landMarkCategory: "Theater",
        landmark: "Shubert Theatre",
        dateAdded: "2024-07-09",
        audioFile: "shubert_theatre_preserving_history:_restoration_and_modern_day_significance.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "225 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.7582, long: -73.9884 },
        isPremium: false,
        premiumPackage: null,
        playNext: "al_hirschfeld_theatre_introduction_and_history"
    },
    {
        rid: "al_hirschfeld_theatre_introduction_and_history",
        title: "Al Hirschfeld Theatre",
        subtitle: "Introduction and History",
        src: require("../../../audioAssets/english/al_hirschfeld_theatre_introduction_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Al Hirschfeld Theatre",
        dateAdded: "2024-07-09",
        audioFile: "al_hirschfeld_theatre_introduction_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "302 W 45th St, New York, NY 10036, USA",
        latLong: { lat: 40.759193, long: -73.988133 },
        isPremium: false,
        premiumPackage: null,
        playNext: "al_hirschfeld_theatre_architectural_wonders"
    },
    {
        rid: "al_hirschfeld_theatre_architectural_wonders",
        title: "Al Hirschfeld Theatre",
        subtitle: "Architectural Wonders",
        src: require("../../../audioAssets/english/al_hirschfeld_theatre_architectural_wonders.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Al Hirschfeld Theatre",
        dateAdded: "2024-07-09",
        audioFile: "al_hirschfeld_theatre_architectural_wonders.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "302 W 45th St, New York, NY 10036, USA",
        latLong: { lat: 40.759193, long: -73.988133 },
        isPremium: false,
        premiumPackage: null,
        playNext: "al_hirschfeld_theatre_memorable_productions"
    },
    {
        rid: "al_hirschfeld_theatre_memorable_productions",
        title: "Al Hirschfeld Theatre",
        subtitle: "Memorable Productions",
        src: require("../../../audioAssets/english/al_hirschfeld_theatre_memorable_productions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Al Hirschfeld Theatre",
        dateAdded: "2024-07-09",
        audioFile: "al_hirschfeld_theatre_memorable_productions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "302 W 45th St, New York, NY 10036, USA",
        latLong: { lat: 40.759193, long: -73.988133 },
        isPremium: false,
        premiumPackage: null,
        playNext: "al_hirschfeld_theatre_legacy_and_cultural_impact"
    },
    {
        rid: "al_hirschfeld_theatre_legacy_and_cultural_impact",
        title: "Al Hirschfeld Theatre",
        subtitle: "Legacy and Cultural Impact",
        src: require("../../../audioAssets/english/al_hirschfeld_theatre_legacy_and_cultural_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Al Hirschfeld Theatre",
        dateAdded: "2024-07-09",
        audioFile: "al_hirschfeld_theatre_legacy_and_cultural_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "302 W 45th St, New York, NY 10036, USA",
        latLong: { lat: 40.759193, long: -73.988133 },
        isPremium: false,
        premiumPackage: null,
        playNext: "majestic_theatre_introduction_to_the_majestic_theatre"
    },
    {
        rid: "majestic_theatre_introduction_to_the_majestic_theatre",
        title: "Majestic Theatre",
        subtitle: "Introduction to the Majestic Theatre",
        src: require("../../../audioAssets/english/majestic_theatre_introduction_to_the_majestic_theatre.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Majestic Theatre",
        dateAdded: "2024-07-09",
        audioFile: "majestic_theatre_introduction_to_the_majestic_theatre.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "245 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.758636, long: -73.987763 },
        isPremium: false,
        premiumPackage: null,
        playNext: "majestic_theatre_the_majestic_theatre's_architectural_design"
    },
    {
        rid: "majestic_theatre_the_majestic_theatre's_architectural_design",
        title: "Majestic Theatre",
        subtitle: "The Majestic Theatre's Architectural Design",
        src: require("../../../audioAssets/english/majestic_theatre_the_majestic_theatre's_architectural_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Majestic Theatre",
        dateAdded: "2024-07-09",
        audioFile: "majestic_theatre_the_majestic_theatre's_architectural_design.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "245 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.758636, long: -73.987763 },
        isPremium: false,
        premiumPackage: null,
        playNext: "majestic_theatre_historic_productions_and_performances"
    },
    {
        rid: "majestic_theatre_historic_productions_and_performances",
        title: "Majestic Theatre",
        subtitle: "Historic Productions and Performances",
        src: require("../../../audioAssets/english/majestic_theatre_historic_productions_and_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Majestic Theatre",
        dateAdded: "2024-07-09",
        audioFile: "majestic_theatre_historic_productions_and_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "245 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.758636, long: -73.987763 },
        isPremium: false,
        premiumPackage: null,
        playNext: "majestic_theatre_the_majestic_theatre's_role_in_broadway"
    },
    {
        rid: "majestic_theatre_the_majestic_theatre's_role_in_broadway",
        title: "Majestic Theatre",
        subtitle: "The Majestic Theatre's Role in Broadway",
        src: require("../../../audioAssets/english/majestic_theatre_the_majestic_theatre's_role_in_broadway.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Majestic Theatre",
        dateAdded: "2024-07-09",
        audioFile: "majestic_theatre_the_majestic_theatre's_role_in_broadway.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "245 West 44th Street, New York, NY 10036",
        latLong: { lat: 40.758636, long: -73.987763 },
        isPremium: false,
        premiumPackage: null,
        playNext: "minskoff_theatre_a_broadway_gem:_an_overview"
    },
    {
        rid: "minskoff_theatre_a_broadway_gem:_an_overview",
        title: "Minskoff Theatre",
        subtitle: "A Broadway Gem: An Overview",
        src: require("../../../audioAssets/english/minskoff_theatre_a_broadway_gem:_an_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Minskoff Theatre",
        dateAdded: "2024-07-09",
        audioFile: "minskoff_theatre_a_broadway_gem:_an_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "200 West 45th Street, New York, NY 10036",
        latLong: { lat: 40.758, long: -73.9865 },
        isPremium: false,
        premiumPackage: null,
        playNext: "minskoff_theatre_architectural_marvel:_design_and_construction"
    },
    {
        rid: "minskoff_theatre_architectural_marvel:_design_and_construction",
        title: "Minskoff Theatre",
        subtitle: "Architectural Marvel: Design and Construction",
        src: require("../../../audioAssets/english/minskoff_theatre_architectural_marvel:_design_and_construction.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Minskoff Theatre",
        dateAdded: "2024-07-09",
        audioFile: "minskoff_theatre_architectural_marvel:_design_and_construction.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "200 West 45th Street, New York, NY 10036",
        latLong: { lat: 40.758, long: -73.9865 },
        isPremium: false,
        premiumPackage: null,
        playNext: "minskoff_theatre_from_ballet_to_broadway:_notable_productions"
    },
    {
        rid: "minskoff_theatre_from_ballet_to_broadway:_notable_productions",
        title: "Minskoff Theatre",
        subtitle: "From Ballet to Broadway: Notable Productions",
        src: require("../../../audioAssets/english/minskoff_theatre_from_ballet_to_broadway:_notable_productions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Minskoff Theatre",
        dateAdded: "2024-07-09",
        audioFile: "minskoff_theatre_from_ballet_to_broadway:_notable_productions.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "200 West 45th Street, New York, NY 10036",
        latLong: { lat: 40.758, long: -73.9865 },
        isPremium: false,
        premiumPackage: null,
        playNext: "minskoff_theatre_cultural_impact:_the_minskoff’s_legacy"
    },
    {
        rid: "minskoff_theatre_cultural_impact:_the_minskoff’s_legacy",
        title: "Minskoff Theatre",
        subtitle: "Cultural Impact: The Minskoff’s Legacy",
        src: require("../../../audioAssets/english/minskoff_theatre_cultural_impact:_the_minskoff’s_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Minskoff Theatre",
        dateAdded: "2024-07-09",
        audioFile: "minskoff_theatre_cultural_impact:_the_minskoff’s_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "200 West 45th Street, New York, NY 10036",
        latLong: { lat: 40.758, long: -73.9865 },
        isPremium: false,
        premiumPackage: null,
        playNext: "new_amsterdam_theatre_historical_overview"
    },
    {
        rid: "new_amsterdam_theatre_historical_overview",
        title: "New Amsterdam Theatre",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/new_amsterdam_theatre_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theatre District",
        landMarkCategory: "Theater",
        landmark: "New Amsterdam Theatre",
        dateAdded: "2024-07-09",
        audioFile: "new_amsterdam_theatre_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "214 West 42nd Street, New York, NY 10036",
        latLong: { lat: 40.755488, long: -73.987712 },
        isPremium: false,
        premiumPackage: null,
        playNext: "new_amsterdam_theatre_architectural_marvel"
    },
    {
        rid: "new_amsterdam_theatre_architectural_marvel",
        title: "New Amsterdam Theatre",
        subtitle: "Architectural Marvel",
        src: require("../../../audioAssets/english/new_amsterdam_theatre_architectural_marvel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theatre District",
        landMarkCategory: "Theater",
        landmark: "New Amsterdam Theatre",
        dateAdded: "2024-07-09",
        audioFile: "new_amsterdam_theatre_architectural_marvel.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "214 West 42nd Street, New York, NY 10036",
        latLong: { lat: 40.755488, long: -73.987712 },
        isPremium: false,
        premiumPackage: null,
        playNext: "new_amsterdam_theatre_the_roaring_1920s"
    },
    {
        rid: "new_amsterdam_theatre_the_roaring_1920s",
        title: "New Amsterdam Theatre",
        subtitle: "The Roaring 1920s",
        src: require("../../../audioAssets/english/new_amsterdam_theatre_the_roaring_1920s.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theatre District",
        landMarkCategory: "Theater",
        landmark: "New Amsterdam Theatre",
        dateAdded: "2024-07-09",
        audioFile: "new_amsterdam_theatre_the_roaring_1920s.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "214 West 42nd Street, New York, NY 10036",
        latLong: { lat: 40.755488, long: -73.987712 },
        isPremium: false,
        premiumPackage: null,
        playNext: "new_amsterdam_theatre_broadway's_revival"
    },
    {
        rid: "new_amsterdam_theatre_broadway's_revival",
        title: "New Amsterdam Theatre",
        subtitle: "Broadway's Revival",
        src: require("../../../audioAssets/english/new_amsterdam_theatre_broadway's_revival.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theatre District",
        landMarkCategory: "Theater",
        landmark: "New Amsterdam Theatre",
        dateAdded: "2024-07-09",
        audioFile: "new_amsterdam_theatre_broadway's_revival.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "214 West 42nd Street, New York, NY 10036",
        latLong: { lat: 40.755488, long: -73.987712 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lyric_theatre_a_storied_past:_the_early_years"
    },
    {
        rid: "lyric_theatre_a_storied_past:_the_early_years",
        title: "Lyric Theatre",
        subtitle: "A Storied Past: The Early Years",
        src: require("../../../audioAssets/english/lyric_theatre_a_storied_past:_the_early_years.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Lyric Theatre",
        dateAdded: "2024-07-09",
        audioFile: "lyric_theatre_a_storied_past:_the_early_years.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "214 West 43rd Street, New York, NY 10036",
        latLong: { lat: 40.7564, long: -73.9896 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lyric_theatre_resurrection_and_renovation"
    },
    {
        rid: "lyric_theatre_resurrection_and_renovation",
        title: "Lyric Theatre",
        subtitle: "Resurrection and Renovation",
        src: require("../../../audioAssets/english/lyric_theatre_resurrection_and_renovation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Lyric Theatre",
        dateAdded: "2024-07-09",
        audioFile: "lyric_theatre_resurrection_and_renovation.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "214 West 43rd Street, New York, NY 10036",
        latLong: { lat: 40.7564, long: -73.9896 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lyric_theatre_broadway's_golden_age_to_modern_times"
    },
    {
        rid: "lyric_theatre_broadway's_golden_age_to_modern_times",
        title: "Lyric Theatre",
        subtitle: "Broadway's Golden Age to Modern Times",
        src: require("../../../audioAssets/english/lyric_theatre_broadway's_golden_age_to_modern_times.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Lyric Theatre",
        dateAdded: "2024-07-09",
        audioFile: "lyric_theatre_broadway's_golden_age_to_modern_times.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "214 West 43rd Street, New York, NY 10036",
        latLong: { lat: 40.7564, long: -73.9896 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lyric_theatre_the_lyric's_role_in_broadway_today"
    },
    {
        rid: "lyric_theatre_the_lyric's_role_in_broadway_today",
        title: "Lyric Theatre",
        subtitle: "The Lyric's Role in Broadway Today",
        src: require("../../../audioAssets/english/lyric_theatre_the_lyric's_role_in_broadway_today.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Times Square",
        landMarkCategory: "Theater",
        landmark: "Lyric Theatre",
        dateAdded: "2024-07-09",
        audioFile: "lyric_theatre_the_lyric's_role_in_broadway_today.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "214 West 43rd Street, New York, NY 10036",
        latLong: { lat: 40.7564, long: -73.9896 },
        isPremium: false,
        premiumPackage: null,
        playNext: "st._james_theatre_a_historical_overview"
    },
    {
        rid: "st._james_theatre_a_historical_overview",
        title: "St. James Theatre",
        subtitle: "A Historical Overview",
        src: require("../../../audioAssets/english/st._james_theatre_a_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "St. James Theatre",
        dateAdded: "2024-07-09",
        audioFile: "st._james_theatre_a_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "246 W 44th St, New York, NY 10036",
        latLong: { lat: 40.758545, long: -73.989131 },
        isPremium: false,
        premiumPackage: null,
        playNext: "st._james_theatre_the_architecture_and_design"
    },
    {
        rid: "st._james_theatre_the_architecture_and_design",
        title: "St. James Theatre",
        subtitle: "The Architecture and Design",
        src: require("../../../audioAssets/english/st._james_theatre_the_architecture_and_design.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "St. James Theatre",
        dateAdded: "2024-07-09",
        audioFile: "st._james_theatre_the_architecture_and_design.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "246 W 44th St, New York, NY 10036",
        latLong: { lat: 40.758545, long: -73.989131 },
        isPremium: false,
        premiumPackage: null,
        playNext: "st._james_theatre_notable_productions_over_the_decades"
    },
    {
        rid: "st._james_theatre_notable_productions_over_the_decades",
        title: "St. James Theatre",
        subtitle: "Notable Productions Over the Decades",
        src: require("../../../audioAssets/english/st._james_theatre_notable_productions_over_the_decades.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "St. James Theatre",
        dateAdded: "2024-07-09",
        audioFile: "st._james_theatre_notable_productions_over_the_decades.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "246 W 44th St, New York, NY 10036",
        latLong: { lat: 40.758545, long: -73.989131 },
        isPremium: false,
        premiumPackage: null,
        playNext: "st._james_theatre_the_theatre's_impact_on_broadway"
    },
    {
        rid: "st._james_theatre_the_theatre's_impact_on_broadway",
        title: "St. James Theatre",
        subtitle: "The Theatre's Impact on Broadway",
        src: require("../../../audioAssets/english/st._james_theatre_the_theatre's_impact_on_broadway.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "St. James Theatre",
        dateAdded: "2024-07-09",
        audioFile: "st._james_theatre_the_theatre's_impact_on_broadway.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "246 W 44th St, New York, NY 10036",
        latLong: { lat: 40.758545, long: -73.989131 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nederlander_theatre_the_early_years:_a_historic_beginning"
    },
    {
        rid: "nederlander_theatre_the_early_years:_a_historic_beginning",
        title: "Nederlander Theatre",
        subtitle: "The Early Years: A Historic Beginning",
        src: require("../../../audioAssets/english/nederlander_theatre_the_early_years:_a_historic_beginning.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Nederlander Theatre",
        dateAdded: "2024-07-09",
        audioFile: "nederlander_theatre_the_early_years:_a_historic_beginning.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "208 W 41st St, New York, NY 10036",
        latLong: { lat: 40.756261, long: -73.989364 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nederlander_theatre_broadway_giants:_iconic_productions_and_stars"
    },
    {
        rid: "nederlander_theatre_broadway_giants:_iconic_productions_and_stars",
        title: "Nederlander Theatre",
        subtitle: "Broadway Giants: Iconic Productions and Stars",
        src: require("../../../audioAssets/english/nederlander_theatre_broadway_giants:_iconic_productions_and_stars.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Nederlander Theatre",
        dateAdded: "2024-07-09",
        audioFile: "nederlander_theatre_broadway_giants:_iconic_productions_and_stars.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "208 W 41st St, New York, NY 10036",
        latLong: { lat: 40.756261, long: -73.989364 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nederlander_theatre_preservation_and_renovations:_keeping_the_past_alive"
    },
    {
        rid: "nederlander_theatre_preservation_and_renovations:_keeping_the_past_alive",
        title: "Nederlander Theatre",
        subtitle: "Preservation and Renovations: Keeping the Past Alive",
        src: require("../../../audioAssets/english/nederlander_theatre_preservation_and_renovations:_keeping_the_past_alive.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Nederlander Theatre",
        dateAdded: "2024-07-09",
        audioFile: "nederlander_theatre_preservation_and_renovations:_keeping_the_past_alive.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "208 W 41st St, New York, NY 10036",
        latLong: { lat: 40.756261, long: -73.989364 },
        isPremium: false,
        premiumPackage: null,
        playNext: "nederlander_theatre_cultural_impact:_the_nederlander_legacy"
    },
    {
        rid: "nederlander_theatre_cultural_impact:_the_nederlander_legacy",
        title: "Nederlander Theatre",
        subtitle: "Cultural Impact: The Nederlander Legacy",
        src: require("../../../audioAssets/english/nederlander_theatre_cultural_impact:_the_nederlander_legacy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Theater District",
        landMarkCategory: "Theater",
        landmark: "Nederlander Theatre",
        dateAdded: "2024-07-09",
        audioFile: "nederlander_theatre_cultural_impact:_the_nederlander_legacy.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "208 W 41st St, New York, NY 10036",
        latLong: { lat: 40.756261, long: -73.989364 },
        isPremium: false,
        premiumPackage: null,
        playNext: "vivian_beaumont_theater_introduction_to_the_vivian_beaumont_theater"
    },
    {
        rid: "vivian_beaumont_theater_introduction_to_the_vivian_beaumont_theater",
        title: "Vivian Beaumont Theater",
        subtitle: "Introduction to the Vivian Beaumont Theater",
        src: require("../../../audioAssets/english/vivian_beaumont_theater_introduction_to_the_vivian_beaumont_theater.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Theater",
        landmark: "Vivian Beaumont Theater",
        dateAdded: "2024-07-09",
        audioFile: "vivian_beaumont_theater_introduction_to_the_vivian_beaumont_theater.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "150 West 65th Street, New York, NY 10023",
        latLong: { lat: 40.772193, long: -73.985682 },
        isPremium: false,
        premiumPackage: null,
        playNext: "vivian_beaumont_theater_architectural_design_and_construction"
    },
    {
        rid: "vivian_beaumont_theater_architectural_design_and_construction",
        title: "Vivian Beaumont Theater",
        subtitle: "Architectural Design and Construction",
        src: require("../../../audioAssets/english/vivian_beaumont_theater_architectural_design_and_construction.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Theater",
        landmark: "Vivian Beaumont Theater",
        dateAdded: "2024-07-09",
        audioFile: "vivian_beaumont_theater_architectural_design_and_construction.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "150 West 65th Street, New York, NY 10023",
        latLong: { lat: 40.772193, long: -73.985682 },
        isPremium: false,
        premiumPackage: null,
        playNext: "vivian_beaumont_theater_memorable_productions_and_performances"
    },
    {
        rid: "vivian_beaumont_theater_memorable_productions_and_performances",
        title: "Vivian Beaumont Theater",
        subtitle: "Memorable Productions and Performances",
        src: require("../../../audioAssets/english/vivian_beaumont_theater_memorable_productions_and_performances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Theater",
        landmark: "Vivian Beaumont Theater",
        dateAdded: "2024-07-09",
        audioFile: "vivian_beaumont_theater_memorable_productions_and_performances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "150 West 65th Street, New York, NY 10023",
        latLong: { lat: 40.772193, long: -73.985682 },
        isPremium: false,
        premiumPackage: null,
        playNext: "vivian_beaumont_theater_impact_on_lincoln_center_and_beyond"
    },
    {
        rid: "vivian_beaumont_theater_impact_on_lincoln_center_and_beyond",
        title: "Vivian Beaumont Theater",
        subtitle: "Impact on Lincoln Center and Beyond",
        src: require("../../../audioAssets/english/vivian_beaumont_theater_impact_on_lincoln_center_and_beyond.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Theater",
        landmark: "Vivian Beaumont Theater",
        dateAdded: "2024-07-09",
        audioFile: "vivian_beaumont_theater_impact_on_lincoln_center_and_beyond.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "150 West 65th Street, New York, NY 10023",
        latLong: { lat: 40.772193, long: -73.985682 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_west_side_historical_overview"
    },
    {
        rid: "upper_west_side_historical_overview",
        title: "Upper West Side",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/upper_west_side_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper West Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_west_side_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Upper West Side, Manhattan, NY, USA",
        latLong: { lat: 40.787, long: -73.9754 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_west_side_architectural_highlights"
    },
    {
        rid: "upper_west_side_architectural_highlights",
        title: "Upper West Side",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/upper_west_side_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper West Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_west_side_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Upper West Side, Manhattan, NY, USA",
        latLong: { lat: 40.787, long: -73.9754 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_west_side_famous_residents_and_cultural_icons"
    },
    {
        rid: "upper_west_side_famous_residents_and_cultural_icons",
        title: "Upper West Side",
        subtitle: "Famous Residents and Cultural Icons",
        src: require("../../../audioAssets/english/upper_west_side_famous_residents_and_cultural_icons.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper West Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_west_side_famous_residents_and_cultural_icons.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Upper West Side, Manhattan, NY, USA",
        latLong: { lat: 40.787, long: -73.9754 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_west_side_parks_and_recreation"
    },
    {
        rid: "upper_west_side_parks_and_recreation",
        title: "Upper West Side",
        subtitle: "Parks and Recreation",
        src: require("../../../audioAssets/english/upper_west_side_parks_and_recreation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper West Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper West Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_west_side_parks_and_recreation.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Upper West Side, Manhattan, NY, USA",
        latLong: { lat: 40.787, long: -73.9754 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_east_side_historical_background"
    },
    {
        rid: "upper_east_side_historical_background",
        title: "Upper East Side",
        subtitle: "Historical Background",
        src: require("../../../audioAssets/english/upper_east_side_historical_background.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper East Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_east_side_historical_background.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Upper East Side, Manhattan, New York, NY",
        latLong: { lat: 40.7736, long: -73.9566 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_east_side_architectural_highlights"
    },
    {
        rid: "upper_east_side_architectural_highlights",
        title: "Upper East Side",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/upper_east_side_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper East Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_east_side_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Upper East Side, Manhattan, New York, NY",
        latLong: { lat: 40.7736, long: -73.9566 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_east_side_famous_residents"
    },
    {
        rid: "upper_east_side_famous_residents",
        title: "Upper East Side",
        subtitle: "Famous Residents",
        src: require("../../../audioAssets/english/upper_east_side_famous_residents.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper East Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_east_side_famous_residents.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Upper East Side, Manhattan, New York, NY",
        latLong: { lat: 40.7736, long: -73.9566 },
        isPremium: false,
        premiumPackage: null,
        playNext: "upper_east_side_cultural_and_social_dynamics"
    },
    {
        rid: "upper_east_side_cultural_and_social_dynamics",
        title: "Upper East Side",
        subtitle: "Cultural and Social Dynamics",
        src: require("../../../audioAssets/english/upper_east_side_cultural_and_social_dynamics.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Upper East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Upper East Side",
        dateAdded: "2024-07-09",
        audioFile: "upper_east_side_cultural_and_social_dynamics.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Upper East Side, Manhattan, New York, NY",
        latLong: { lat: 40.7736, long: -73.9566 },
        isPremium: false,
        premiumPackage: null,
        playNext: "greenwich_village_historical_overview"
    },
    {
        rid: "greenwich_village_historical_overview",
        title: "Greenwich Village",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/greenwich_village_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Neighborhoods",
        landmark: "Greenwich Village",
        dateAdded: "2024-07-09",
        audioFile: "greenwich_village_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7336, long: -74.0027 },
        isPremium: false,
        premiumPackage: null,
        playNext: "greenwich_village_architectural_evolution"
    },
    {
        rid: "greenwich_village_architectural_evolution",
        title: "Greenwich Village",
        subtitle: "Architectural Evolution",
        src: require("../../../audioAssets/english/greenwich_village_architectural_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Neighborhoods",
        landmark: "Greenwich Village",
        dateAdded: "2024-07-09",
        audioFile: "greenwich_village_architectural_evolution.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7336, long: -74.0027 },
        isPremium: false,
        premiumPackage: null,
        playNext: "greenwich_village_bohemian_roots_and_counterculture_movements"
    },
    {
        rid: "greenwich_village_bohemian_roots_and_counterculture_movements",
        title: "Greenwich Village",
        subtitle: "Bohemian Roots and Counterculture Movements",
        src: require("../../../audioAssets/english/greenwich_village_bohemian_roots_and_counterculture_movements.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Neighborhoods",
        landmark: "Greenwich Village",
        dateAdded: "2024-07-09",
        audioFile: "greenwich_village_bohemian_roots_and_counterculture_movements.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7336, long: -74.0027 },
        isPremium: false,
        premiumPackage: null,
        playNext: "greenwich_village_modern-day_attractions_and_culture"
    },
    {
        rid: "greenwich_village_modern-day_attractions_and_culture",
        title: "Greenwich Village",
        subtitle: "Modern-Day Attractions and Culture",
        src: require("../../../audioAssets/english/greenwich_village_modern-day_attractions_and_culture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Greenwich Village",
        landMarkCategory: "Neighborhoods",
        landmark: "Greenwich Village",
        dateAdded: "2024-07-09",
        audioFile: "greenwich_village_modern-day_attractions_and_culture.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7336, long: -74.0027 },
        isPremium: false,
        premiumPackage: null,
        playNext: "soho_historical_overview"
    },
    {
        rid: "soho_historical_overview",
        title: "SoHo",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/soho_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "SoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "SoHo",
        dateAdded: "2024-07-09",
        audioFile: "soho_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "SoHo, Manhattan, New York City, NY",
        latLong: { lat: 40.7233, long: -74.003 },
        isPremium: false,
        premiumPackage: null,
        playNext: "soho_architectural_evolution"
    },
    {
        rid: "soho_architectural_evolution",
        title: "SoHo",
        subtitle: "Architectural Evolution",
        src: require("../../../audioAssets/english/soho_architectural_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "SoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "SoHo",
        dateAdded: "2024-07-09",
        audioFile: "soho_architectural_evolution.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "SoHo, Manhattan, New York City, NY",
        latLong: { lat: 40.7233, long: -74.003 },
        isPremium: false,
        premiumPackage: null,
        playNext: "soho_the_artistic_renaissance"
    },
    {
        rid: "soho_the_artistic_renaissance",
        title: "SoHo",
        subtitle: "The Artistic Renaissance",
        src: require("../../../audioAssets/english/soho_the_artistic_renaissance.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "SoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "SoHo",
        dateAdded: "2024-07-09",
        audioFile: "soho_the_artistic_renaissance.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "SoHo, Manhattan, New York City, NY",
        latLong: { lat: 40.7233, long: -74.003 },
        isPremium: false,
        premiumPackage: null,
        playNext: "soho_modern-day_soho"
    },
    {
        rid: "soho_modern-day_soho",
        title: "SoHo",
        subtitle: "Modern-Day SoHo",
        src: require("../../../audioAssets/english/soho_modern-day_soho.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "SoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "SoHo",
        dateAdded: "2024-07-09",
        audioFile: "soho_modern-day_soho.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "SoHo, Manhattan, New York City, NY",
        latLong: { lat: 40.7233, long: -74.003 },
        isPremium: false,
        premiumPackage: null,
        playNext: "tribeca_overview_and_history"
    },
    {
        rid: "tribeca_overview_and_history",
        title: "Tribeca",
        subtitle: "Overview and History",
        src: require("../../../audioAssets/english/tribeca_overview_and_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Tribeca",
        landMarkCategory: "Neighborhoods",
        landmark: "Tribeca",
        dateAdded: "2024-07-09",
        audioFile: "tribeca_overview_and_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Tribeca, New York, NY, USA",
        latLong: { lat: 40.7163, long: -74.0086 },
        isPremium: false,
        premiumPackage: null,
        playNext: "tribeca_architectural_highlights"
    },
    {
        rid: "tribeca_architectural_highlights",
        title: "Tribeca",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/tribeca_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Tribeca",
        landMarkCategory: "Neighborhoods",
        landmark: "Tribeca",
        dateAdded: "2024-07-09",
        audioFile: "tribeca_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Tribeca, New York, NY, USA",
        latLong: { lat: 40.7163, long: -74.0086 },
        isPremium: false,
        premiumPackage: null,
        playNext: "tribeca_hollywood_in_tribeca"
    },
    {
        rid: "tribeca_hollywood_in_tribeca",
        title: "Tribeca",
        subtitle: "Hollywood in Tribeca",
        src: require("../../../audioAssets/english/tribeca_hollywood_in_tribeca.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Tribeca",
        landMarkCategory: "Neighborhoods",
        landmark: "Tribeca",
        dateAdded: "2024-07-09",
        audioFile: "tribeca_hollywood_in_tribeca.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Tribeca, New York, NY, USA",
        latLong: { lat: 40.7163, long: -74.0086 },
        isPremium: false,
        premiumPackage: null,
        playNext: "tribeca_dining_and_nightlife"
    },
    {
        rid: "tribeca_dining_and_nightlife",
        title: "Tribeca",
        subtitle: "Dining and Nightlife",
        src: require("../../../audioAssets/english/tribeca_dining_and_nightlife.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Tribeca",
        landMarkCategory: "Neighborhoods",
        landmark: "Tribeca",
        dateAdded: "2024-07-09",
        audioFile: "tribeca_dining_and_nightlife.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Tribeca, New York, NY, USA",
        latLong: { lat: 40.7163, long: -74.0086 },
        isPremium: false,
        premiumPackage: null,
        playNext: "harlem_a_brief_history_of_harlem"
    },
    {
        rid: "harlem_a_brief_history_of_harlem",
        title: "Harlem",
        subtitle: "A Brief History of Harlem",
        src: require("../../../audioAssets/english/harlem_a_brief_history_of_harlem.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Neighborhoods",
        landmark: "Harlem",
        dateAdded: "2024-07-09",
        audioFile: "harlem_a_brief_history_of_harlem.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Harlem, New York, NY, USA",
        latLong: { lat: 40.81155, long: -73.946477 },
        isPremium: false,
        premiumPackage: null,
        playNext: "harlem_the_harlem_renaissance:_a_cultural_explosion"
    },
    {
        rid: "harlem_the_harlem_renaissance:_a_cultural_explosion",
        title: "Harlem",
        subtitle: "The Harlem Renaissance: A Cultural Explosion",
        src: require("../../../audioAssets/english/harlem_the_harlem_renaissance:_a_cultural_explosion.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Neighborhoods",
        landmark: "Harlem",
        dateAdded: "2024-07-09",
        audioFile: "harlem_the_harlem_renaissance:_a_cultural_explosion.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Harlem, New York, NY, USA",
        latLong: { lat: 40.81155, long: -73.946477 },
        isPremium: false,
        premiumPackage: null,
        playNext: "harlem_harlem's_architectural_wonders"
    },
    {
        rid: "harlem_harlem's_architectural_wonders",
        title: "Harlem",
        subtitle: "Harlem's Architectural Wonders",
        src: require("../../../audioAssets/english/harlem_harlem's_architectural_wonders.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Neighborhoods",
        landmark: "Harlem",
        dateAdded: "2024-07-09",
        audioFile: "harlem_harlem's_architectural_wonders.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Harlem, New York, NY, USA",
        latLong: { lat: 40.81155, long: -73.946477 },
        isPremium: false,
        premiumPackage: null,
        playNext: "harlem_modern_day_harlem:_a_blend_of_heritage_and_innovation"
    },
    {
        rid: "harlem_modern_day_harlem:_a_blend_of_heritage_and_innovation",
        title: "Harlem",
        subtitle: "Modern Day Harlem: A Blend of Heritage and Innovation",
        src: require("../../../audioAssets/english/harlem_modern_day_harlem:_a_blend_of_heritage_and_innovation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Harlem",
        landMarkCategory: "Neighborhoods",
        landmark: "Harlem",
        dateAdded: "2024-07-09",
        audioFile: "harlem_modern_day_harlem:_a_blend_of_heritage_and_innovation.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Harlem, New York, NY, USA",
        latLong: { lat: 40.81155, long: -73.946477 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_historical_overview"
    },
    {
        rid: "chelsea_historical_overview",
        title: "Chelsea",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/chelsea_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Neighborhoods",
        landmark: "Chelsea",
        dateAdded: "2024-07-09",
        audioFile: "chelsea_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Chelsea, Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7465, long: -74.0018 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_architectural_highlights"
    },
    {
        rid: "chelsea_architectural_highlights",
        title: "Chelsea",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/chelsea_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Neighborhoods",
        landmark: "Chelsea",
        dateAdded: "2024-07-09",
        audioFile: "chelsea_architectural_highlights.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Chelsea, Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7465, long: -74.0018 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_cultural_hub_and_art_scene"
    },
    {
        rid: "chelsea_cultural_hub_and_art_scene",
        title: "Chelsea",
        subtitle: "Cultural Hub and Art Scene",
        src: require("../../../audioAssets/english/chelsea_cultural_hub_and_art_scene.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Neighborhoods",
        landmark: "Chelsea",
        dateAdded: "2024-07-09",
        audioFile: "chelsea_cultural_hub_and_art_scene.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Chelsea, Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7465, long: -74.0018 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chelsea_modern_day_chelsea:_dining_and_nightlife"
    },
    {
        rid: "chelsea_modern_day_chelsea:_dining_and_nightlife",
        title: "Chelsea",
        subtitle: "Modern Day Chelsea: Dining and Nightlife",
        src: require("../../../audioAssets/english/chelsea_modern_day_chelsea:_dining_and_nightlife.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chelsea",
        landMarkCategory: "Neighborhoods",
        landmark: "Chelsea",
        dateAdded: "2024-07-09",
        audioFile: "chelsea_modern_day_chelsea:_dining_and_nightlife.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Chelsea, Manhattan, New York City, NY, USA",
        latLong: { lat: 40.7465, long: -74.0018 },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_village_introduction_and_early_history"
    },
    {
        rid: "east_village_introduction_and_early_history",
        title: "East Village",
        subtitle: "Introduction and Early History",
        src: require("../../../audioAssets/english/east_village_introduction_and_early_history.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Neighborhoods",
        landmark: "East Village",
        dateAdded: "2024-07-09",
        audioFile: "east_village_introduction_and_early_history.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "East Village, Manhattan, New York, NY, USA",
        latLong: { lat: 40.7265, long: -73.9815 },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_village_the_beat_generation_and_counterculture"
    },
    {
        rid: "east_village_the_beat_generation_and_counterculture",
        title: "East Village",
        subtitle: "The Beat Generation and Counterculture",
        src: require("../../../audioAssets/english/east_village_the_beat_generation_and_counterculture.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Neighborhoods",
        landmark: "East Village",
        dateAdded: "2024-07-09",
        audioFile: "east_village_the_beat_generation_and_counterculture.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "East Village, Manhattan, New York, NY, USA",
        latLong: { lat: 40.7265, long: -73.9815 },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_village_landmarks_and_hidden_gems"
    },
    {
        rid: "east_village_landmarks_and_hidden_gems",
        title: "East Village",
        subtitle: "Landmarks and Hidden Gems",
        src: require("../../../audioAssets/english/east_village_landmarks_and_hidden_gems.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Neighborhoods",
        landmark: "East Village",
        dateAdded: "2024-07-09",
        audioFile: "east_village_landmarks_and_hidden_gems.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "East Village, Manhattan, New York, NY, USA",
        latLong: { lat: 40.7265, long: -73.9815 },
        isPremium: false,
        premiumPackage: null,
        playNext: "east_village_modern_day_east_village:_food,_art,_and_nightlife"
    },
    {
        rid: "east_village_modern_day_east_village:_food,_art,_and_nightlife",
        title: "East Village",
        subtitle: "Modern Day East Village: Food, Art, and Nightlife",
        src: require("../../../audioAssets/english/east_village_modern_day_east_village:_food,_art,_and_nightlife.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "East Village",
        landMarkCategory: "Neighborhoods",
        landmark: "East Village",
        dateAdded: "2024-07-09",
        audioFile: "east_village_modern_day_east_village:_food,_art,_and_nightlife.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "East Village, Manhattan, New York, NY, USA",
        latLong: { lat: 40.7265, long: -73.9815 },
        isPremium: false,
        premiumPackage: null,
        playNext: "williamsburg_introduction_to_williamsburg"
    },
    {
        rid: "williamsburg_introduction_to_williamsburg",
        title: "Williamsburg",
        subtitle: "Introduction to Williamsburg",
        src: require("../../../audioAssets/english/williamsburg_introduction_to_williamsburg.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Brooklyn",
        landMarkCategory: "Neighborhoods",
        landmark: "Williamsburg",
        dateAdded: "2024-07-09",
        audioFile: "williamsburg_introduction_to_williamsburg.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Williamsburg, Brooklyn, NY 11211",
        latLong: { lat: 40.7081, long: -73.9571 },
        isPremium: false,
        premiumPackage: null,
        playNext: "williamsburg_historical_evolution"
    },
    {
        rid: "williamsburg_historical_evolution",
        title: "Williamsburg",
        subtitle: "Historical Evolution",
        src: require("../../../audioAssets/english/williamsburg_historical_evolution.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Brooklyn",
        landMarkCategory: "Neighborhoods",
        landmark: "Williamsburg",
        dateAdded: "2024-07-09",
        audioFile: "williamsburg_historical_evolution.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Williamsburg, Brooklyn, NY 11211",
        latLong: { lat: 40.7081, long: -73.9571 },
        isPremium: false,
        premiumPackage: null,
        playNext: "williamsburg_arts_and_culture_scene"
    },
    {
        rid: "williamsburg_arts_and_culture_scene",
        title: "Williamsburg",
        subtitle: "Arts and Culture Scene",
        src: require("../../../audioAssets/english/williamsburg_arts_and_culture_scene.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Brooklyn",
        landMarkCategory: "Neighborhoods",
        landmark: "Williamsburg",
        dateAdded: "2024-07-09",
        audioFile: "williamsburg_arts_and_culture_scene.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Williamsburg, Brooklyn, NY 11211",
        latLong: { lat: 40.7081, long: -73.9571 },
        isPremium: false,
        premiumPackage: null,
        playNext: "williamsburg_modern_williamsburg:_a_hipster’s_paradise"
    },
    {
        rid: "williamsburg_modern_williamsburg:_a_hipster’s_paradise",
        title: "Williamsburg",
        subtitle: "Modern Williamsburg: A Hipster’s Paradise",
        src: require("../../../audioAssets/english/williamsburg_modern_williamsburg:_a_hipster’s_paradise.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Brooklyn",
        landMarkCategory: "Neighborhoods",
        landmark: "Williamsburg",
        dateAdded: "2024-07-09",
        audioFile: "williamsburg_modern_williamsburg:_a_hipster’s_paradise.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Williamsburg, Brooklyn, NY 11211",
        latLong: { lat: 40.7081, long: -73.9571 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dumbo_introduction_to_dumbo"
    },
    {
        rid: "dumbo_introduction_to_dumbo",
        title: "DUMBO",
        subtitle: "Introduction to DUMBO",
        src: require("../../../audioAssets/english/dumbo_introduction_to_dumbo.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Neighborhoods",
        landmark: "DUMBO",
        dateAdded: "2024-07-09",
        audioFile: "dumbo_introduction_to_dumbo.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "DUMBO, Brooklyn, NY 11201",
        latLong: { lat: 40.7033, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dumbo_industrial_past_and_transformation"
    },
    {
        rid: "dumbo_industrial_past_and_transformation",
        title: "DUMBO",
        subtitle: "Industrial Past and Transformation",
        src: require("../../../audioAssets/english/dumbo_industrial_past_and_transformation.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Neighborhoods",
        landmark: "DUMBO",
        dateAdded: "2024-07-09",
        audioFile: "dumbo_industrial_past_and_transformation.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "DUMBO, Brooklyn, NY 11201",
        latLong: { lat: 40.7033, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dumbo_art_and_culture_in_dumbo"
    },
    {
        rid: "dumbo_art_and_culture_in_dumbo",
        title: "DUMBO",
        subtitle: "Art and Culture in DUMBO",
        src: require("../../../audioAssets/english/dumbo_art_and_culture_in_dumbo.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Neighborhoods",
        landmark: "DUMBO",
        dateAdded: "2024-07-09",
        audioFile: "dumbo_art_and_culture_in_dumbo.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "DUMBO, Brooklyn, NY 11201",
        latLong: { lat: 40.7033, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "dumbo_exploring_brooklyn_bridge_park"
    },
    {
        rid: "dumbo_exploring_brooklyn_bridge_park",
        title: "DUMBO",
        subtitle: "Exploring Brooklyn Bridge Park",
        src: require("../../../audioAssets/english/dumbo_exploring_brooklyn_bridge_park.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "DUMBO",
        landMarkCategory: "Neighborhoods",
        landmark: "DUMBO",
        dateAdded: "2024-07-09",
        audioFile: "dumbo_exploring_brooklyn_bridge_park.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "DUMBO, Brooklyn, NY 11201",
        latLong: { lat: 40.7033, long: -73.9881 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chinatown_historical_origins"
    },
    {
        rid: "chinatown_historical_origins",
        title: "Chinatown",
        subtitle: "Historical Origins",
        src: require("../../../audioAssets/english/chinatown_historical_origins.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chinatown",
        landMarkCategory: "Neighborhoods",
        landmark: "Chinatown",
        dateAdded: "2024-07-09",
        audioFile: "chinatown_historical_origins.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Canal Street, New York, NY 10013",
        latLong: { lat: 40.71575, long: -73.99703 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chinatown_architectural_gems"
    },
    {
        rid: "chinatown_architectural_gems",
        title: "Chinatown",
        subtitle: "Architectural Gems",
        src: require("../../../audioAssets/english/chinatown_architectural_gems.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chinatown",
        landMarkCategory: "Neighborhoods",
        landmark: "Chinatown",
        dateAdded: "2024-07-09",
        audioFile: "chinatown_architectural_gems.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Canal Street, New York, NY 10013",
        latLong: { lat: 40.71575, long: -73.99703 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chinatown_culinary_delights"
    },
    {
        rid: "chinatown_culinary_delights",
        title: "Chinatown",
        subtitle: "Culinary Delights",
        src: require("../../../audioAssets/english/chinatown_culinary_delights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chinatown",
        landMarkCategory: "Neighborhoods",
        landmark: "Chinatown",
        dateAdded: "2024-07-09",
        audioFile: "chinatown_culinary_delights.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Canal Street, New York, NY 10013",
        latLong: { lat: 40.71575, long: -73.99703 },
        isPremium: false,
        premiumPackage: null,
        playNext: "chinatown_cultural_festivals_and_traditions"
    },
    {
        rid: "chinatown_cultural_festivals_and_traditions",
        title: "Chinatown",
        subtitle: "Cultural Festivals and Traditions",
        src: require("../../../audioAssets/english/chinatown_cultural_festivals_and_traditions.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Chinatown",
        landMarkCategory: "Neighborhoods",
        landmark: "Chinatown",
        dateAdded: "2024-07-09",
        audioFile: "chinatown_cultural_festivals_and_traditions.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Canal Street, New York, NY 10013",
        latLong: { lat: 40.71575, long: -73.99703 },
        isPremium: false,
        premiumPackage: null,
        playNext: "little_italy_introduction_to_little_italy"
    },
    {
        rid: "little_italy_introduction_to_little_italy",
        title: "Little Italy",
        subtitle: "Introduction to Little Italy",
        src: require("../../../audioAssets/english/little_italy_introduction_to_little_italy.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower Manhattan",
        landMarkCategory: "Neighborhoods",
        landmark: "Little Italy",
        dateAdded: "2024-07-09",
        audioFile: "little_italy_introduction_to_little_italy.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Mulberry St, New York, NY",
        latLong: { lat: 40.719141, long: -73.997327 },
        isPremium: false,
        premiumPackage: null,
        playNext: "little_italy_early_immigrant_stories"
    },
    {
        rid: "little_italy_early_immigrant_stories",
        title: "Little Italy",
        subtitle: "Early Immigrant Stories",
        src: require("../../../audioAssets/english/little_italy_early_immigrant_stories.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower Manhattan",
        landMarkCategory: "Neighborhoods",
        landmark: "Little Italy",
        dateAdded: "2024-07-09",
        audioFile: "little_italy_early_immigrant_stories.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Mulberry St, New York, NY",
        latLong: { lat: 40.719141, long: -73.997327 },
        isPremium: false,
        premiumPackage: null,
        playNext: "little_italy_iconic_eateries_and_shops"
    },
    {
        rid: "little_italy_iconic_eateries_and_shops",
        title: "Little Italy",
        subtitle: "Iconic Eateries and Shops",
        src: require("../../../audioAssets/english/little_italy_iconic_eateries_and_shops.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower Manhattan",
        landMarkCategory: "Neighborhoods",
        landmark: "Little Italy",
        dateAdded: "2024-07-09",
        audioFile: "little_italy_iconic_eateries_and_shops.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Mulberry St, New York, NY",
        latLong: { lat: 40.719141, long: -73.997327 },
        isPremium: false,
        premiumPackage: null,
        playNext: "little_italy_festivals_and_cultural_events"
    },
    {
        rid: "little_italy_festivals_and_cultural_events",
        title: "Little Italy",
        subtitle: "Festivals and Cultural Events",
        src: require("../../../audioAssets/english/little_italy_festivals_and_cultural_events.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower Manhattan",
        landMarkCategory: "Neighborhoods",
        landmark: "Little Italy",
        dateAdded: "2024-07-09",
        audioFile: "little_italy_festivals_and_cultural_events.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Mulberry St, New York, NY",
        latLong: { lat: 40.719141, long: -73.997327 },
        isPremium: false,
        premiumPackage: null,
        playNext: "noho_introduction_to_noho:_a_hidden_gem"
    },
    {
        rid: "noho_introduction_to_noho:_a_hidden_gem",
        title: "NoHo",
        subtitle: "Introduction to NoHo: A Hidden Gem",
        src: require("../../../audioAssets/english/noho_introduction_to_noho:_a_hidden_gem.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "NoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "NoHo",
        dateAdded: "2024-07-09",
        audioFile: "noho_introduction_to_noho:_a_hidden_gem.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "NoHo, New York, NY",
        latLong: { lat: 40.7293, long: -73.9927 },
        isPremium: false,
        premiumPackage: null,
        playNext: "noho_architectural_evolution:_past_to_present"
    },
    {
        rid: "noho_architectural_evolution:_past_to_present",
        title: "NoHo",
        subtitle: "Architectural Evolution: Past to Present",
        src: require("../../../audioAssets/english/noho_architectural_evolution:_past_to_present.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "NoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "NoHo",
        dateAdded: "2024-07-09",
        audioFile: "noho_architectural_evolution:_past_to_present.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "NoHo, New York, NY",
        latLong: { lat: 40.7293, long: -73.9927 },
        isPremium: false,
        premiumPackage: null,
        playNext: "noho_famous_residents_and_historical_events"
    },
    {
        rid: "noho_famous_residents_and_historical_events",
        title: "NoHo",
        subtitle: "Famous Residents and Historical Events",
        src: require("../../../audioAssets/english/noho_famous_residents_and_historical_events.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "NoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "NoHo",
        dateAdded: "2024-07-09",
        audioFile: "noho_famous_residents_and_historical_events.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "NoHo, New York, NY",
        latLong: { lat: 40.7293, long: -73.9927 },
        isPremium: false,
        premiumPackage: null,
        playNext: "noho_cultural_and_artistic_impact"
    },
    {
        rid: "noho_cultural_and_artistic_impact",
        title: "NoHo",
        subtitle: "Cultural and Artistic Impact",
        src: require("../../../audioAssets/english/noho_cultural_and_artistic_impact.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "NoHo",
        landMarkCategory: "Neighborhoods",
        landmark: "NoHo",
        dateAdded: "2024-07-09",
        audioFile: "noho_cultural_and_artistic_impact.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "NoHo, New York, NY",
        latLong: { lat: 40.7293, long: -73.9927 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lower_east_side_historical_overview"
    },
    {
        rid: "lower_east_side_historical_overview",
        title: "Lower East Side",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/lower_east_side_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Lower East Side",
        dateAdded: "2024-07-09",
        audioFile: "lower_east_side_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Lower East Side, Manhattan, New York, NY, USA",
        latLong: { lat: 40.715033, long: -73.984272 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lower_east_side_immigrant_stories_and_cultural_melting_pot"
    },
    {
        rid: "lower_east_side_immigrant_stories_and_cultural_melting_pot",
        title: "Lower East Side",
        subtitle: "Immigrant Stories and Cultural Melting Pot",
        src: require("../../../audioAssets/english/lower_east_side_immigrant_stories_and_cultural_melting_pot.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Lower East Side",
        dateAdded: "2024-07-09",
        audioFile: "lower_east_side_immigrant_stories_and_cultural_melting_pot.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Lower East Side, Manhattan, New York, NY, USA",
        latLong: { lat: 40.715033, long: -73.984272 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lower_east_side_architectural_highlights"
    },
    {
        rid: "lower_east_side_architectural_highlights",
        title: "Lower East Side",
        subtitle: "Architectural Highlights",
        src: require("../../../audioAssets/english/lower_east_side_architectural_highlights.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Lower East Side",
        dateAdded: "2024-07-09",
        audioFile: "lower_east_side_architectural_highlights.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Lower East Side, Manhattan, New York, NY, USA",
        latLong: { lat: 40.715033, long: -73.984272 },
        isPremium: false,
        premiumPackage: null,
        playNext: "lower_east_side_modern_transformation_and_current_vibes"
    },
    {
        rid: "lower_east_side_modern_transformation_and_current_vibes",
        title: "Lower East Side",
        subtitle: "Modern Transformation and Current Vibes",
        src: require("../../../audioAssets/english/lower_east_side_modern_transformation_and_current_vibes.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lower East Side",
        landMarkCategory: "Neighborhoods",
        landmark: "Lower East Side",
        dateAdded: "2024-07-09",
        audioFile: "lower_east_side_modern_transformation_and_current_vibes.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Lower East Side, Manhattan, New York, NY, USA",
        latLong: { lat: 40.715033, long: -73.984272 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hell's_kitchen_historical_overview"
    },
    {
        rid: "hell's_kitchen_historical_overview",
        title: "Hell's Kitchen",
        subtitle: "Historical Overview",
        src: require("../../../audioAssets/english/hell's_kitchen_historical_overview.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Neighborhoods",
        landmark: "Hell's Kitchen",
        dateAdded: "2024-07-09",
        audioFile: "hell's_kitchen_historical_overview.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "Hell's Kitchen, New York, NY",
        latLong: { lat: 40.7638, long: -73.9918 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hell's_kitchen_evolution_and_gentrification"
    },
    {
        rid: "hell's_kitchen_evolution_and_gentrification",
        title: "Hell's Kitchen",
        subtitle: "Evolution and Gentrification",
        src: require("../../../audioAssets/english/hell's_kitchen_evolution_and_gentrification.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Neighborhoods",
        landmark: "Hell's Kitchen",
        dateAdded: "2024-07-09",
        audioFile: "hell's_kitchen_evolution_and_gentrification.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "Hell's Kitchen, New York, NY",
        latLong: { lat: 40.7638, long: -73.9918 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hell's_kitchen_famous_residents_and_pop_culture_references"
    },
    {
        rid: "hell's_kitchen_famous_residents_and_pop_culture_references",
        title: "Hell's Kitchen",
        subtitle: "Famous Residents and Pop Culture References",
        src: require("../../../audioAssets/english/hell's_kitchen_famous_residents_and_pop_culture_references.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Neighborhoods",
        landmark: "Hell's Kitchen",
        dateAdded: "2024-07-09",
        audioFile: "hell's_kitchen_famous_residents_and_pop_culture_references.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "Hell's Kitchen, New York, NY",
        latLong: { lat: 40.7638, long: -73.9918 },
        isPremium: false,
        premiumPackage: null,
        playNext: "hell's_kitchen_dining_and_entertainment_hotspots"
    },
    {
        rid: "hell's_kitchen_dining_and_entertainment_hotspots",
        title: "Hell's Kitchen",
        subtitle: "Dining and Entertainment Hotspots",
        src: require("../../../audioAssets/english/hell's_kitchen_dining_and_entertainment_hotspots.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Hell's Kitchen",
        landMarkCategory: "Neighborhoods",
        landmark: "Hell's Kitchen",
        dateAdded: "2024-07-09",
        audioFile: "hell's_kitchen_dining_and_entertainment_hotspots.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "Hell's Kitchen, New York, NY",
        latLong: { lat: 40.7638, long: -73.9918 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_hotel_a_glimpse_into_history:_the_origins_of_the_empire_hotel"
    },
    {
        rid: "empire_hotel_a_glimpse_into_history:_the_origins_of_the_empire_hotel",
        title: "Empire Hotel",
        subtitle: "A Glimpse into History: The Origins of the Empire Hotel",
        src: require("../../../audioAssets/english/empire_hotel_a_glimpse_into_history:_the_origins_of_the_empire_hotel.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire Hotel",
        dateAdded: "2024-07-21",
        audioFile: "empire_hotel_a_glimpse_into_history:_the_origins_of_the_empire_hotel.mp3",
        chapter: 1,
        total_chapters: 4,
        address: "44 West 63rd Street, New York, NY 10023",
        latLong: { lat: 40.772254, long: -73.983499 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_hotel_the_renaissance:_renovations_and_the_modern_era"
    },
    {
        rid: "empire_hotel_the_renaissance:_renovations_and_the_modern_era",
        title: "Empire Hotel",
        subtitle: "The Renaissance: Renovations and the Modern Era",
        src: require("../../../audioAssets/english/empire_hotel_the_renaissance:_renovations_and_the_modern_era.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire Hotel",
        dateAdded: "2024-07-21",
        audioFile: "empire_hotel_the_renaissance:_renovations_and_the_modern_era.mp3",
        chapter: 2,
        total_chapters: 4,
        address: "44 West 63rd Street, New York, NY 10023",
        latLong: { lat: 40.772254, long: -73.983499 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_hotel_famous_guests_and_film_appearances"
    },
    {
        rid: "empire_hotel_famous_guests_and_film_appearances",
        title: "Empire Hotel",
        subtitle: "Famous Guests and Film Appearances",
        src: require("../../../audioAssets/english/empire_hotel_famous_guests_and_film_appearances.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire Hotel",
        dateAdded: "2024-07-21",
        audioFile: "empire_hotel_famous_guests_and_film_appearances.mp3",
        chapter: 3,
        total_chapters: 4,
        address: "44 West 63rd Street, New York, NY 10023",
        latLong: { lat: 40.772254, long: -73.983499 },
        isPremium: false,
        premiumPackage: null,
        playNext: "empire_hotel_cultural_impact_on_new_york_city_and_beyond"
    },
    {
        rid: "empire_hotel_cultural_impact_on_new_york_city_and_beyond",
        title: "Empire Hotel",
        subtitle: "Cultural Impact on New York City and Beyond",
        src: require("../../../audioAssets/english/empire_hotel_cultural_impact_on_new_york_city_and_beyond.mp3"),
        language: "English",
        city: "New York City",
        neighborhood: "Lincoln Square",
        landMarkCategory: "Iconic Destination",
        landmark: "Empire Hotel",
        dateAdded: "2024-07-21",
        audioFile: "empire_hotel_cultural_impact_on_new_york_city_and_beyond.mp3",
        chapter: 4,
        total_chapters: 4,
        address: "44 West 63rd Street, New York, NY 10023",
        latLong: { lat: 40.772254, long: -73.983499 },
        isPremium: false,
        premiumPackage: null,
        playNext: "highbridge_park_landmarks_and_features"
    }
];

export const landMarkCategories = Array.from(
    new Set(locations.map(location => location.landMarkCategory))
);