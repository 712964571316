import { landMarkCategories } from '../Types/Location';
import './FilterList.css';

export const FilterList: React.FC<{
    selectedFilters: string[];
    handleSetSelectedFilter: (landMarkCategory: string) => void;
}> = ({ selectedFilters, handleSetSelectedFilter }) => {

    return (
        <div className='category-and-filter-options'>
            <h4>Category</h4>
            <div className='filters'>
                {landMarkCategories.map((landMarkCategory, index) => (
                    <p 
                        key={index} 
                        className={`filter ${selectedFilters.includes(landMarkCategory) && 'selected'}`}
                        onClick={() => handleSetSelectedFilter(landMarkCategory)}
                    >
                        {landMarkCategory}
                    </p>
                ))}
            </div>
        </div>
       
    );
};