import React from 'react';
import like_button_white from "../../../icons/like_button.svg";
import like_button_black from "../../../icons/like_button_all_black.svg";
import { Location } from "../Types/Location";
import { useLikedLocations } from './LikedLocationsProvider'; 
import './LikeButton.css';
import { EventActions, EventCategories, logAnalyticsEvent } from '../Analytics/GoogleAnalytics';

export const LikeButton: React.FC<{ 
    location: Location;
}> = ({ location }) => {

    const { likedLocations, toggleLike } = useLikedLocations();

    const isLiked = likedLocations.includes(location.rid);

    const handleLikeClick = () => {
        const label = isLiked ? 'unlike' : 'like';
        if(isLiked){
          logAnalyticsEvent(EventCategories.LIKE, EventActions.UNLIKE, label);
        } else {
          logAnalyticsEvent(EventCategories.LIKE, EventActions.LIKE, label);
        }
        toggleLike(location);
    };

    return (
        <div className='like-button-container' onClick={handleLikeClick}>
          <div className='like-button'>
            <img
              className='like-icon'
              src={isLiked ? like_button_black : like_button_white}
              alt='like'
            />
          </div>
        </div>
      );
};