import React from 'react';
import './Timer.css';

export const Timer: React.FC<{ 
    duration: number;
    currentTime: number;
}> = ({ duration, currentTime }) => {
  
    // const progressPercentage = duration ? (currentTime / duration) * 100 : 0;

    return (
        <div className="progress-bar-timer">
            <p>{formatTime(currentTime)} / {formatTime(duration)}</p> 
        </div>
    );
};

const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
};


//  <div className="progress-bar-container">
//             <div className="progress-bar">
//                 <div className="progress-circle" style={{ left: `${progressPercentage}%` }} />
//                 <div className="progress" style={{ width: `${progressPercentage}%` }} />
//             </div> 
            
//         </div>
