import * as React from 'react';
import './PlayHomePage.css';
import { PlayHomeHeader } from '../PlayHomePageHeader/PlayHomeHeader';
import { PlayButton } from '../PlayButton/PlayButton';
import { Location, locations } from '../Types/Location';
import { ShareButton } from '../ShareButton/ShareButton';
import { useParams, useNavigate } from 'react-router-dom';
import { RouteParams } from '../Types/RouteParams';
import { Chapters } from './Chapters';
import { LikeButton } from '../LikeButton/LikeButton';
import { Timer } from '../Timer/Timer';
import { EventActions, EventCategories, logAnalyticsEvent } from '../Analytics/GoogleAnalytics';
import { AudioMetadata } from '../Types/AudioMetadata';
import { ChapterOverview } from '../ChapterOverview/ChapterOverview';

export const PlayHomePage: React.FC<{ }> = ({ }) => {
   
    const { city, landmark, chapter } = useParams<RouteParams>();
    const navigate = useNavigate();
    const [location, setLocation] = React.useState<Location>(locations[0]);
    const [nextLocation, setNextLocation] = React.useState<Location>(locations.filter(x => x.rid === location.playNext)[0])
    const [isViewingOptions, setIsViewingOptions] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [duration, setDuration] = React.useState<number>(0);
    const [currentTime, setCurrentTime] = React.useState<number>(0);
    const [audioSrc, setAudioSrc] = React.useState<string>("");
    const [audioMetadata, setAudioMetadata] = React.useState<AudioMetadata>({
        audioSrc: audioSrc,
        isPlaying: isPlaying,
        subtitle: location.subtitle,
        album: location.title,
    });
    
    // handle URL entered
    React.useEffect(() => {
        setLocationFromURL(setLocation, city, landmark, chapter);
    }, [city, landmark, chapter]);

    // handle audio input load
    React.useEffect(() => {
        loadAudioModule(location, setAudioSrc);
        setAudioMetadata({
            audioSrc: audioSrc,
            isPlaying: isPlaying,
            subtitle: location.subtitle,
            album: location.title,
        })
        const nextLocation = locations.find(loc => loc.rid === location.playNext);
        if (nextLocation) {
            setNextLocation(nextLocation);
        } else {
            setNextLocation(location);
        }
    }, [location, audioSrc, isPlaying]);

    // handle page height for 100% screen use
    React.useEffect(() => {
        setWebpageHeight();
    }, []);

    const handleSetIsPlaying = React.useCallback(() => {
        setIsPlaying(true);
        logAnalyticsEvent(EventCategories.AUDIO, EventActions.PLAY, 'Play');
    }, []);

    const handleSetIsPaused = React.useCallback(() => {
        setIsPlaying(false);
        logAnalyticsEvent(EventCategories.AUDIO, EventActions.PAUSE, 'Pause');
    }, []);

    const handleTimeUpdate =  React.useCallback((currentTime: number, duration: number) => {
        setCurrentTime(currentTime);
        setDuration(duration);
    }, []);
    
    const handleLocationClick = React.useCallback(() => {
        setIsPlaying(false);
        setIsViewingOptions((prev) => !prev);
    }, []);

    const handleAudioEnd = React.useCallback(() => {
        logAnalyticsEvent(EventCategories.AUDIO, EventActions.FINISHED, 'audio_fully_played');
        setIsPlaying(false);
        if (nextLocation) {
            setLocation(nextLocation);
            setTimeout(() => {
                setIsPlaying(true);
            }, 2000); // 2 seconds delay
        }
    }, [nextLocation]);

    const handleSetLocation = React.useCallback((location: Location) => {
        logAnalyticsEvent(EventCategories.FILTER, EventActions.LANDMARK_SELECTED, location.landmark);
        setLocation(location);
        setIsViewingOptions(false);
        setIsPlaying(false);
        navigate(`/${location.city}/${location.landmark}/${location.chapter}`);
    }, [navigate]);

    return (
        <div className='home-page-container'>
            
            <PlayHomeHeader 
                isViewingOptions={isViewingOptions} 
                handleLocationClick={handleLocationClick} 
                handleSetLocation={handleSetLocation} 
            />

            {!isViewingOptions && (
                <div className='core-home-contents'>
                    <div className='location-and-chapter'>
                        <ChapterOverview location={location} />
                    </div>
                    <div className='play-and-timer'>
                        <div className='play-like-share'>
                            <PlayButton 
                                audioMetadata={audioMetadata}
                                handleAudioEnd={handleAudioEnd}
                                handleSetIsPlaying={handleSetIsPlaying}
                                handleSetIsPaused={handleSetIsPaused}
                                handleTimeUpdate={handleTimeUpdate} 
                            />
                            <LikeButton  location={location}/>
                            <ShareButton location={location} url={window.location.href} />
                        </div>
                        <div className='timer'>
                            <Timer duration={duration} currentTime={currentTime} />
                        </div>
                    </div>
                </div>
                    
            )}

            {!isViewingOptions && (
                <Chapters currentLocation={location} handleSetLocation={handleSetLocation} />
            )}

        </div>
    );
};

const setLocationFromURL = (
    setLocation: (location: Location) => void,
    city?: string, 
    landmark?: string, 
    chapter?: string,
) => {
    const newLocation = locations.find(
        loc => loc.city.toLowerCase() === city?.toLowerCase() &&
               loc.landmark.toLowerCase() === landmark?.toLowerCase() &&
               loc.chapter === parseInt(chapter ?? "1")
    );
    if (newLocation) {
        setLocation(newLocation);
    } else {
        // Handle the case where the location is not found (e.g., redirect to a 404 page)
    }

};

const setWebpageHeight = () => {
   
    const updateHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Initial call to set the custom property
    updateHeight();

    // Update the custom property on resize and orientation change
    window.addEventListener('resize', updateHeight);
    window.addEventListener('orientationchange', updateHeight);

    // Cleanup event listeners on component unmount
    return () => {
        window.removeEventListener('resize', updateHeight);
        window.removeEventListener('orientationchange', updateHeight);
    };

};

const loadAudioModule = async (
    location: Location, 
    setAudioSrc: React.Dispatch<React.SetStateAction<string>>, 
) => {
    try {
        setAudioSrc(location.src);
    } catch (error) {
        console.error('Error loading audio module:', error);
        setAudioSrc("");
    }
};