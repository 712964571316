import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { PlayHomePage } from './pages/PlayHomePage/PlayHomePage/PlayHomePage';
import { LikedLocationsProvider } from './pages/PlayHomePage/LikeButton/LikedLocationsProvider';
import ReactGA from 'react-ga4';

const App: React.FC = () => {
  
  function isMobileDevice() {
    return (
      (navigator.userAgent.match(/Android/i)) ||
      (navigator.userAgent.match(/webOS/i)) ||
      (navigator.userAgent.match(/iPhone/i)) ||
      (navigator.userAgent.match(/iPod/i)) ||
      (navigator.userAgent.match(/BlackBerry/i)) ||
      (navigator.userAgent.match(/Windows Phone/i))
    );
  }

  // if (!isMobileDevice()) {
  //   return <div>This is only available on mobile browsers</div>;
  // }

  // run in debug mode using gaOptions: { debug_mode: true, }, gtagOptions: { debug_mode: true, },
  React.useEffect(() => {
      ReactGA.initialize('G-EYWNFZ00GD');
  }, [])
  

  return (
    <LikedLocationsProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<PlayHomePage />} />
            <Route path="/:city/:landmark/:chapter" element={<PlayHomePage />} />
          </Routes>
        </div>
      </Router>
    </LikedLocationsProvider>
  );
};

export default App;
