import React from 'react';
import './FilterResults.css';
import { Location, locations } from '../Types/Location';

type LocationWithDistance = Location & { distance: number };

export const FilterResults: React.FC<{ 
    selectedFilters: string[];
    handleSetLocation: (location: Location) => void;
    userLocation: { lat: number, long: number } | undefined
}> = ({ selectedFilters, handleSetLocation, userLocation }) => {

    const [filteredLocations, setFilteredLocations] = React.useState<LocationWithDistance[]>([]);

    const calculateDistances = React.useCallback((latitude: number, longitude: number, locations: Location[]) => {
        const locationsWithDistance = locations.map(location => ({
            ...location,
            distance: calculateDistance(latitude, longitude, location.latLong.lat, location.latLong.long)
        }));

        // Sort locations by distance
        locationsWithDistance.sort((a, b) => a.distance - b.distance);

        setFilteredLocations(locationsWithDistance);
    }, []);

    React.useEffect(() => {
        const filtered = locations.filter(location => 
            location.chapter === 1 && 
            (selectedFilters.length === 0 || selectedFilters.includes(location.landMarkCategory))
        );

        if (userLocation) {
            calculateDistances(userLocation.lat, userLocation.long, filtered);
        } else {
            setFilteredLocations(filtered as LocationWithDistance[]);
        }
    }, [selectedFilters, userLocation, calculateDistances]);

    return (
        <div className='filter-results-container'>
            <h4 className='title'>
                {`${filteredLocations.length} ${userLocation ? (filteredLocations.length === 1 ? "Result sorted by distance" : "Results sorted by distance") : (filteredLocations.length === 1 ? "Result" : "Results")}`}
            </h4>
            <div className='filter-results'>
                {filteredLocations.map((location, index) => (
                    <div 
                        className='location-option' 
                        onClick={() => handleSetLocation(location)}
                        key={index}
                    >
                        <h4>
                            {location.title}
                        </h4>
                        {location.distance && (
                            <p className='location-metadata-item'>{location.distance.toFixed(2)} mi</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const toRad = (value: number) => value * Math.PI / 180;
    const R = 3958.8; // Radius of the Earth in miles

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in miles
};