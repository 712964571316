import React, { Suspense } from 'react';
import play_button from "../../../icons/play_button.svg";
import pause_button from "../../../icons/pause_button.svg";
import './PlayButton.css';
import { AudioMetadata } from '../Types/AudioMetadata';

const LazyAudioPlayer = React.lazy(() => import('../AudioPlayer/AudioPlayer'));

export const PlayButton: React.FC<{ 
    audioMetadata: AudioMetadata;
    handleAudioEnd: () => void;
    handleSetIsPlaying: () => void;
    handleSetIsPaused: () => void;
    handleTimeUpdate: (currentTime: number, duration: number) => void;
}> = ({ 
  audioMetadata,
  handleAudioEnd, 
  handleSetIsPlaying, 
  handleSetIsPaused, 
  handleTimeUpdate,
}) => {

  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  React.useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: audioMetadata.subtitle,
        artist: "Curious Walks",
        album: audioMetadata.album,
        artwork: [
          { 
            src: "../../../../public/logo512.png", 
            sizes: '512x512', 
            type: 'image/png' 
          }
        ]
      });

      navigator.mediaSession.setActionHandler('play', () => {
        handleSetIsPlaying();
      });

      navigator.mediaSession.setActionHandler('pause', () => {
        handleSetIsPaused();
      });

      navigator.mediaSession.setActionHandler('seekbackward', (details) => {
        if (audioRef.current) {
          audioRef.current.currentTime = Math.max(audioRef.current.currentTime - (details.seekOffset || 10), 0);
          handleTimeUpdate(audioRef.current.currentTime, audioRef.current.duration);
        }
      });

      navigator.mediaSession.setActionHandler('seekforward', (details) => {
        if (audioRef.current) {
          audioRef.current.currentTime = Math.min(audioRef.current.currentTime + (details.seekOffset || 10), audioRef.current.duration);
          handleTimeUpdate(audioRef.current.currentTime, audioRef.current.duration);
        }
      });
    }
  }, [audioMetadata, handleSetIsPlaying, handleSetIsPaused, handleTimeUpdate]);


  return (
    <div className='play-button-container'>
      <div className='play-pause-button'>
        {audioMetadata.isPlaying ? (
          <img className='pause-button' src={pause_button} onClick={handleSetIsPaused} alt={'pause button'}/>
        ) : (
          <img className='play-button' src={play_button} onClick={handleSetIsPlaying} alt={'play button'}/>
        )}
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {audioMetadata.audioSrc && 
            <LazyAudioPlayer 
              src={audioMetadata.audioSrc} 
              isPlaying={audioMetadata.isPlaying} 
              onTimeUpdate={handleTimeUpdate}
              handleAudioEnd={handleAudioEnd}
            />
        }
      </Suspense>
    </div>
  );
};