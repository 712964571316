import React from 'react';
import './PlayHomeHeader.css';
import arrow_down from "../../../icons/arrow-down.svg";
import { Location } from '../Types/Location';
import { Filters } from '../Filters/Filters';

export const PlayHomeHeader: React.FC<{ 
    isViewingOptions: boolean;
    handleLocationClick: () => void;
    handleSetLocation: (location: Location) => void;
}> = ({
    isViewingOptions,
    handleLocationClick,
    handleSetLocation 
}) => {

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                handleLocationClick();
            }
        };

        if (isViewingOptions) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isViewingOptions, handleLocationClick]);
   
    return (
        <div className='location-header' ref={ref}>
            {isViewingOptions ? (
                <Filters 
                    handleSetLocation={handleSetLocation} 
                    handleClickExit={handleLocationClick}
                />
            ) : (
                <DeafultHeader handleLocationClick={handleLocationClick}/>
            )}
        </div>
    );
};


export const DeafultHeader: React.FC<{ 
    handleLocationClick: () => void;
}> = ({
    handleLocationClick,
}) => {

    return (
        <div className='location-and-down-arrow' onClick={handleLocationClick}>
            <p  className='location-header-title'>Landmarks</p>
            <div className='down-arrow'>
                <img className='play-button' src={arrow_down} alt={'down arrow'}/>
            </div>
        </div>
    )
};

