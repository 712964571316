import React from 'react';
import './ShareButton.css';
import share_button from "../../../icons/share_button.svg";
import { EventActions, EventCategories, logAnalyticsEvent } from '../Analytics/GoogleAnalytics';
import { Location } from '../Types/Location';

export const ShareButton: React.FC<{ 
  location: Location
  url: string;
}> = ({ location, url }) => {
  
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: location.subtitle,
          text: `Check out Curious Walks for a free audio history of New York!\n\nI'm listening to a chapter called ${location.subtitle} about ${location.landmark}`,
          url,
        });
        logAnalyticsEvent(EventCategories.SHARE, EventActions.SHARE, 'clicked_share');
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      // if handle share doesn't work
    }
  };

  return (
    <div className='share-button-container'>
        <div className='share-button' onClick={handleShare}>
            <img src={share_button} alt={"share"}/>
        </div>
    </div>
  );
};