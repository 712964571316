import ReactGA from 'react-ga4';

// Define types for categories and actions
export type EventCategory = 'Filter' | 'Like & Unlike' | 'Audio' | 'Share';
export type EventAction = 'filter_selected' | 'use_location' | 'share' | 'like' | 'unlike' | 'play' | 'pause' | 'finished' | 'landmark_selected';

// Define constants for categories and actions to ensure consistency
export const EventCategories = {
  FILTER: 'Filter' as EventCategory,
  LIKE: 'Like & Unlike' as EventCategory,
  AUDIO: 'Audio' as EventCategory,
  SHARE: 'Share' as EventCategory,
};

export const EventActions = {
  FILTER_SELECTED: 'filter_selected' as EventAction,
  USE_LOCATION: 'use_location' as EventAction,
  SHARE: 'share' as EventAction,
  LIKE: 'like' as EventAction,
  UNLIKE: 'unlike' as EventAction,
  PLAY: 'play' as EventAction,
  PAUSE: 'pause' as EventAction,
  FINISHED: 'finished' as EventAction,
  LANDMARK_SELECTED: 'landmark_selected' as EventAction,
};

// Helper function to log GA events
/**
 * Logs an event to Google Analytics
 * @param category - The category of the event (use constants from EventCategories)
 * @param action - The action of the event (use constants from EventActions)
 * @param label - The label of the event (dynamic values or predefined strings)
 */
export const logAnalyticsEvent = (category: EventCategory, action: EventAction, label: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};