import * as React from 'react';
import './Chapters.css';
import { Location, locations } from '../Types/Location';

export const Chapters: React.FC<{
    currentLocation: Location 
    handleSetLocation: (location: Location) => void;
 }> = ({ currentLocation, handleSetLocation }) => {

    const chatpers = locations.filter(loc => loc.landmark === currentLocation.landmark)
   
    return (
        <div className='chapters-container'>
            {chatpers.map(chapter => (
                <div 
                    key={chapter.rid} 
                    className={`chapter ${chapter.rid !== currentLocation.rid && 'inactive'}`}
                    onClick={() => handleSetLocation(chapter)}
                >
                    <p>{chapter.chapter}.  {chapter.subtitle}</p>
                </div>
            ))}
        </div>
    )
};