import * as React from 'react';
import { Location } from "../Types/Location";

interface LikedLocationsContextProps {
    likedLocations: string[]; // array of location ids (rids)
    toggleLike: (location: Location) => void;
}

const LikedLocationsContext = React.createContext<LikedLocationsContextProps | undefined>(undefined);

export const LikedLocationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [likedLocations, setLikedLocations] = React.useState<string[]>(() => {
        // Read from localStorage on initial load
        const saved = localStorage.getItem('likedLocations');
        return saved ? JSON.parse(saved) : [];
    });

    React.useEffect(() => {
        // Save to localStorage whenever likedLocations change
        localStorage.setItem('likedLocations', JSON.stringify(likedLocations));
    }, [likedLocations]);

    const toggleLike = (location: Location) => {
        setLikedLocations((prevLikedLocations) => {
            if (prevLikedLocations.includes(location.rid)) {
                return prevLikedLocations.filter(id => id !== location.rid);
            } else {
                return [...prevLikedLocations, location.rid];
            }
        });
    };

    return (
        <LikedLocationsContext.Provider value={{ likedLocations, toggleLike }}>
            {children}
        </LikedLocationsContext.Provider>
    );
};

export const useLikedLocations = (): LikedLocationsContextProps => {
    const context = React.useContext(LikedLocationsContext);
    if (!context) {
        throw new Error('useLikedLocations must be used within a LikedLocationsProvider');
    }
    return context;
};