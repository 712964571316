import React from 'react';
import './Filters.css';
import { Location } from '../Types/Location';
import { FilterResults } from "./FilterResults";
import { FilterList } from "./FilterList";
import map_icon from "../../../icons/map_icon.svg";
import close_icon from "../../../icons/close_icon.svg";
import { EventActions, EventCategories, logAnalyticsEvent } from '../Analytics/GoogleAnalytics';

export const Filters: React.FC<{ 
    handleSetLocation: (location: Location) => void;
    handleClickExit: () => void;
}> = ({ handleSetLocation, handleClickExit }) => {

    const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);
    const [userLocation, setUserLocation] = React.useState<{ lat: number; long: number } | undefined>(undefined);

    const handleSetSelectedFilter = React.useCallback((landMarkCategory: string) => {
        setSelectedFilters(prevFilters => {
            if (prevFilters.includes(landMarkCategory)) {
                return prevFilters.filter(filter => filter !== landMarkCategory);
            } else {
                logAnalyticsEvent(EventCategories.FILTER, EventActions.FILTER_SELECTED, landMarkCategory);
                return [...prevFilters, landMarkCategory];
            }
        });
    }, []);   

    const handleLocationClick = React.useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ lat: latitude, long: longitude });
                logAnalyticsEvent(EventCategories.FILTER, EventActions.USE_LOCATION, `${latitude}, ${longitude}`);
            }, (error) => {
                console.error("Error getting user location:", error);
            });
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    return (
        <div className='filters-and-results-container'>
            <div className='search-header-and-filter-list'>
                <div className='search-header-and-close'>
                    <h2>Filters</h2>

                    <div className='map-and-close'>
                        <LocationButton handleLocationClick={handleLocationClick}/>
                        <CloseButton handleClickExit={handleClickExit}/>
                    </div>
                </div>
                <FilterList 
                    selectedFilters={selectedFilters} 
                    handleSetSelectedFilter={handleSetSelectedFilter} 
                />
            </div>

            <FilterResults 
                selectedFilters={selectedFilters} 
                handleSetLocation={handleSetLocation} 
                userLocation={userLocation}
            />
        </div>
    );
};

const CloseButton: React.FC<{ 
    handleClickExit: () => void;
}> = ({ handleClickExit }) => {

    return (
        <div className='close-button' onClick={handleClickExit}>
            <img src={close_icon} alt={'close button'}/>
        </div>
    )
}

const LocationButton: React.FC<{ 
    handleLocationClick: () => void;
}> = ({ handleLocationClick }) => {
    return (
        <div className='map-button' onClick={handleLocationClick}>
            <img src={map_icon} alt={'location button'}/>
            <p>Near me</p>
        </div>
    )
}